<template>
  <div
    class="close-button"
    :class="{
      'close-button_bordered': withBorder,
      'close-button_large': large
    }"
    @click="$emit('click')"
  >
    <m-icon :icon="iconCross" />
  </div>
</template>

<script>
  import iconCross from 'assets/icons/cross.svg';

  export default {
    name: 'CloseButton',
    props: {
      large: {
        type: Boolean,
        default: false
      },
      withBorder: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        iconCross
      };
    }
  };
</script>

<style>
  .close-button {
    color: var(--gray-text-color);

    cursor: pointer;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 16px;
    height: 16px;
  }

  .close-button_bordered {
    color: var(--secondary-text-color);

    background-color: rgba(212, 212, 212, 0.5);

    border-radius: 50%;
  }

  .close-button_large {
    width: 20px;
    height: 20px;
  }

  .close-button:hover {
    color: var(--secondary-text-color);
  }

  .close-button_bordered:hover {
    color: var(--text-main-color);
    background-color: var(--gray-text-color);
  }

  .close-button svg {
    width: 14px;
    height: 14px;
  }

  .close-button_large svg {
    width: 18px;
    height: 18px;
  }
</style>
