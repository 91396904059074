<template>
  <MTableCell
    class="m-table-cell__currency"
    @click="$emit('click-trigger')"
  >
    <div
      class="m-table-cell__currency_value"
    >
      <MTitle
        :title="currencyName"
      >
        {{ currencyValue }}
      </MTitle>
    </div>
  </MTableCell>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {MTitle, mTableCells} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTableCellCurrency',
    components: {
      MTitle,
      MTableCell: mTableCells.MTableCell
    },
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      ...mapGetters('enums', ['currencies', 'getCurrencyById']),
      currency () {
        return this.getCurrencyById(this.tableRow[this.itemKey]) || {};
      },
      currencyValue () {
        return this.currency.symbol || '-';
      },
      currencyName () {
        return this.currency.name;
      }
    }
  };
</script>

<style>
  .m-table-cell__currency {
    padding: 12px;
  }
</style>
