<template>
  <div>
    <div class="accounts-search-panel">
      <SearchInput
        :emit-input-on-change="false"
        :value="params.name"
        :placeholder="_('Search')"
        :icon="iconMagnifier"
        class="m-inline-search__accounts"
        @input="getSearchTextDebounced"
      />
      <MSelectControl
        class="select-container__accounts"
        id-attr="id"
        text-attr="name"
        :value="selectedStatusValue"
        :choices="choices"
        popper-class="popper__accounts"
        @input="getSelectedStatus"
      />
      <MButton
        v-if="currentUserIsAdmin"
        class="m-button_secondary"
        :title="_('Move Accounts')"
        :disabled="!hasSelectedItemsInTable"
        @click="openModal"
      >
        {{ isSmallWindow ? _('Move') : _('Move Accounts') }}
      </MButton>
      <router-link
        v-if="canUser('CREATE_ACCOUNT')"
        class="m-button m-button-create__accounts"
        :title="_('Create account')"
        :to="{name: 'accountCreate', params: {accountId}}"
      >
        {{ isSmallWindow ? _('Create') : _('Create account') }}
      </router-link>
    </div>
    <div class="accounts-table">
      <AccountsTable
        :data="data"
        :items-total="itemsTotal"
        :selected-items="selectedItems"
        :m-table-columns-data="mTableColumnsData"
        :is-loading="isLoading"
        :is-table-loading="isTableLoading"
        :current-page="params.page"
        :per-page="params.per_page"
        :current-sorting="params.sorting"
        @fetch="paginateTable"
        @sort="sortTable"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {accountsService} from 'services';
  import {mapGetters} from 'vuex';
  import AccountsListPage from 'pages/accounts/AccountsListPage';
  import MTableCellNumeric from 'legacy/modules/common/components/MTable/cells/MTableCellNumeric';
  import MSelectControl from 'components/common/MSelectControl/MSelectControl';
  import MTableCellCurrency from 'legacy/modules/common/components/MTable/cells/MTableCellCurrency';
  import MovingAccountsModal from 'pages/accounts/components/MovingAccountsModal';
  import MModalMixin from 'legacy/modules/common/components/MModal/MModalMixin';
  import MTableCellRouterLink from 'legacy/modules/common/components/MTable/cells/MTableCellRouterLink';
  import MTableCellAccountType from 'legacy/modules/common/components/MTable/cells/MTableCellAccountType';
  import translation from 'translation';
  import MTableCellAccountStatus from 'legacy/modules/common/components/MTable/cells/MTableCellAccountStatus';
  import iconMagnifier from 'assets/icons/search.svg';
  import ApiBadRequestError from 'utils/api/errors/ApiBadRequestError';
  import {SearchInput} from '@marilyn/marilyn-ui';

  _.noConflict();

  export default {
    name: 'AccountPage',
    components: {MSelectControl, SearchInput},
    extends: AccountsListPage,
    mixins: [MModalMixin],
    props: {
      accountId: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        iconMagnifier,
        modalComponent: MovingAccountsModal,
        modalProps: {
          choices: [],
          errorObject: {},
          errorPath: ''
        },
        modalEventListeners: {
          apply: this.moveAccounts,
          cancel: this.closeModal,
          select: this.clearErrorPropsInModal
        }
      };
    },
    computed: {
      ...mapGetters('accounts', ['currentAccount']),
      ...mapGetters('user', ['currentUserIsAdmin', 'canUser']),
      mTableColumnsData () {
        return [
          {
            itemKey: 'id',
            header: '№',
            cellComponent: MTableCellRouterLink,
            minWidth: 86,
            align: 'left',
            resizable: false,
            static: true,
            sortable: true,
            route: el => this.getPath(el)
          },
          {
            itemKey: 'name',
            header: translation.tr('Name'),
            cellComponent: MTableCellRouterLink,
            minWidth: 180,
            align: 'left',
            sortable: true,
            route: el => this.getPath(el)
          },
          {
            itemKey: 'type',
            header: translation.tr('Type'),
            cellComponent: MTableCellAccountType,
            minWidth: 100,
            sortable: true,
            align: 'left'
          },
          {
            itemKey: 'status',
            header: translation.tr('Status'),
            cellComponent: MTableCellAccountStatus,
            minWidth: 80,
            sortable: true,
            align: 'center'
          },
          {
            itemKey: 'currency_id',
            header: translation.tr('Currency'),
            cellComponent: MTableCellCurrency,
            minWidth: 80,
            sortable: true,
            align: 'center'
          },
          {
            itemKey: 'cost_fact',
            header: translation.tr('Budget'),
            cellComponent: MTableCellNumeric,
            type: 'currency',
            currencyWithSymbol: false,
            calcVAT: true,
            minWidth: 100,
            sortable: true,
            static: true,
            align: 'right'
          },
          {
            itemKey: 'impressions',
            header: translation.tr('Impressions'),
            cellComponent: MTableCellNumeric,
            minWidth: 100,
            sortable: true,
            align: 'right'
          },
          {
            itemKey: 'clicks',
            header: translation.tr('Clicks'),
            cellComponent: MTableCellNumeric,
            minWidth: 100,
            sortable: true,
            align: 'right'
          }
        ];
      },
      hasSelectedItemsInTable () {
        return this.selectedItems.length !== 0;
      }
    },
    methods: {
      closeModal () {
        this.clearErrorPropsInModal();
        this.modalProps.choices = [];
        this.hidePopup();
      },
      async getModalContentChoices () {
        let params = {
          descending: true,
          include_current: false,
          type: 'MCC',
          status: ['ACTIVE']
        };

        const sortFunc = function (model) {
          return model.name;
        };

        const tree = {};

        const response = await accountsService.list(this.accountId, params);
        const items = response.items;
        const rootItems = items.filter(item => item.parent_id === this.accountId);
        const treedItems = _.map(_.sortBy(rootItems, sortFunc), (model) => JSON.parse(JSON.stringify(model)));

        _.each(treedItems, (item) => {
          tree[item.id] = item;
        });

        let nextLevelItems = _.sortBy(_.flatten(
          _.map(rootItems, (item) => items.filter(value => item.id === value.parent_id))
        ), sortFunc);

        while (nextLevelItems.length) {
          _.each(nextLevelItems, (model) => {
            const item = JSON.parse(JSON.stringify(model));
            const parent = tree[item.parent_id];

            parent.children = (parent.children || []).concat(item);
            tree[item.id] = item;
          });

          nextLevelItems = _.sortBy(_.flatten(
            _.map(nextLevelItems, (item) => items.filter(value => value.parent_id === item.id))
          ), sortFunc);
        }

        this.modalProps.choices = treedItems;
      },
      async moveAccounts (accountId) {
        try {
          let params = this.getQueryStringParams(this.selectedItems);
          await accountsService.moveAccounts(accountId, params);
          this.getDefaultPagination();
          await this.afterDataFetch(this.params);
          this.closeModal();
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            const errors = e.getValidationErrors();
            this.modalProps.errorObject = errors;
            this.getErrorPath(errors);
          }
        }
      },
      getQueryStringParams (params) {
        let result = [];
        params.forEach(id => {
          result.push(`${encodeURIComponent('id')}=${encodeURIComponent(id)}`);
        });

        return result.join('&');
      },
      getErrorPath (obj) {
        Object.keys(obj).forEach(key => {
          if (_.isPlainObject(obj[key])) {
            this.modalProps.errorPath += `${key}.`;
            return this.getErrorPath(obj[key]);
          }

          this.modalProps.errorPath += key;
        });
      },
      clearErrorPropsInModal () {
        this.modalProps.errorObject = {};
        this.modalProps.errorPath = '';
      },
      openModal () {
        this.showPopup();
        this.getModalContentChoices();
      }
    }
  };
</script>

<style>
  .m-button-create__accounts {
    margin-left: 16px;
  }

  .accounts-search-panel .m-button_secondary {
    margin-left: auto;
  }

  .m-select__modal-accounts {
    width: 100%;
  }

  .m-modal__accounts .m-modal__content {
    padding: 18px;
  }

  .m-modal__accounts .m-modal__container {
    width: 25%;
  }

  .popper__accounts .select-option__icon {
    color: var(--success-color);
  }

  .popper__accounts .select-option_active .select-option__icon {
    color: var(--active-item-text-color);
  }

  .popper__account-page .select-option_sticky {
    background-color: var(--background-color);
  }

  .popper__account-page .m-select-control-tree-level__sticky {
    font-weight: var(--bold-font-weight);
  }
</style>
