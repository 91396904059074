<template>
  <MTableCell
    class="m-table-cell__numeric"
    :class="computedClassList"
    @click="$emit('click-trigger')"
    @dblclick="selectNumber"
  >
    <div
      ref="numberBlock"
      class="m-table-cell__numeric_formatted m-table-cell__ellipsis-wrapper"
      :style="{color: getTextColor}"
    >
      {{ formattedNumber }}
    </div>
  </MTableCell>
</template>

<script>
  import FormatNumberMixin from 'pages/seller/statistics/components/MTable/cells/FormatNumberMixin';
  import BigNumber from 'bignumber.js';
  import {mapGetters} from 'vuex';
  import {mTableCells} from '@marilyn/marilyn-ui';


  export default {
    name: 'MTableCellNumeric',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    mixins: [FormatNumberMixin],
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      },
      textColor: {
        type: Function,
        default: () => ''
      },
      currencyWithSymbol: {
        type: Boolean,
        default: true
      },
      calcVAT: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        formattedNumber: null
      };
    },
    computed: {
      ...mapGetters('common', ['vatRate', 'vatOn']),
      computedClassList () {
        return {};
      },
      number () {
        return this.tableRow[this.itemKey];
      },
      numberWithVat () {
        const val = new BigNumber(this.number);
        if (this.calcVAT && this.vatOn){
          return val.times(this.vatRate);
        }
        return val;
      },
      getTextColor () {
        const color = this.textColor(this.tableRow);

        if (color) {
          return `var(${color})`;
        }

        return 'inherit';
      }
    },
    watch: {
      vatOn () {
        if (this.calcVAT) {
          this.formatNumber();
        }
      },
      number (val, prevVal) {
        // do not reformat if value is the same
        if (val !== prevVal) {
          this.formatNumber();
        }
      },
      options () {
        this.formatNumber();
      }
    },
    mounted () {
      this.formatNumber();
    },
    methods: {
      formatNumber () {
        if (this.type === 'decimal') {
          // we show decimal in tables as shortDecimal
          this.formattedNumber = this.getFormattedNumber(
            this.number, {withCurrencySymbol: false, type: 'shortDecimal'}
          );
        } else if (this.type === 'currency') {
          this.formattedNumber = this.getFormattedNumber(
            this.calcVAT ? this.numberWithVat : this.number, {withCurrencySymbol: this.currencyWithSymbol, type: 'currency'}
          );
        } else {
          this.formattedNumber = this.getFormattedNumber(
            this.number, {withCurrencySymbol: false}
          );
        }
      },

      /**
       * Forces selection of whole number, ons some locales parts are separated with spaces
       * and on dblclick whole number wouldn't be selected. This function solves this problem
       */
      selectNumber () {
        const selection = window.getSelection();
        const range = document.createRange();
        const node = this.$refs.numberBlock;
        range.setStart(node, 0);
        range.setEnd(node, 1);

        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };
</script>

<style>
  .m-table-cell__numeric {
    text-align: right;

    position: relative;
  }

  .m-table-cell__numeric_formatted::after {
    content: '';
    padding: 1px;
  }

  .m-table-cell__numeric-loading {
    color: rgba(211, 211, 211, 0);

    height: 13px;

    background-color: #E6E6E6;

    border-radius: 3px;
  }
</style>
