<template>
  <div
    :class="classList"
    class="weekday-hour"
  >
    <div
      v-if="label"
      class="weekday-hour__label"
    >
      {{ label }}
    </div>
    <div class="weekday-hour__content-block">
      <div class="weekday-hour__container">
        <div
          v-for="item in items"
          :key="item.id"
          class="weekday-hour__item"
          :class="{'weekday-hour__item_active': item.selected}"
          @mouseover="onMouseOver(item)"
          @mousedown="selectItem(item)"
          @click.prevent
        >
          {{ item.name }}
        </div>
      </div>

      <MButton
        v-if="showManageChoiceButton"
        link
        @click="clickManageChoice"
      >
        {{ managePickItemText }}
      </MButton>
    </div>
  </div>
</template>

<script>
  import translation from 'translation';

  export default {
    name: 'WeekdayHourForm',
    props: {
      label: {
        type: String,
        default: ''
      },
      items: {
        type: Array,
        required: true
      },
      size: {
        type: String,
        default: 'm',
        choices: ['m', 'l']
      },
      showManageChoiceButton: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        mouseDown: false,
        selectMode: null // true means all hovered items become selected, false -- unselected
      };
    },
    computed: {
      isMedium () {
        return this.size === 'm';
      },
      managePickItemText () {
        if (this.isAllPickItem) {
          return translation.tr('Clear all hours');
        }

        return translation.tr('Select all hours');
      },
      isAllPickItem () {
        return !this.items.find(item => !item.selected);
      },
      classList () {
        return {
          'weekday-hour__medium': this.isMedium,
          'weekday-hour__large': this.isLarge
        };
      },
      isLarge () {
        return this.size === 'l';
      }
    },
    mounted () {
      this.attachMouseDownEvents();
    },
    methods: {
      clickManageChoice () {
        if (this.isAllPickItem) {
          this.$emit('clear-all-item');
        } else {
          this.$emit('pick-all-item');
        }
      },
      attachMouseDownEvents () {
        const mouseDownApply = () => {
          this.mouseDown = true;
        };
        document.addEventListener('mousedown', mouseDownApply);
        this.$on('hook:beforeDestroy', () => {
          document.removeEventListener('mousedown', mouseDownApply);
        });
        const mouseDownCancel = () => {
          this.mouseDown = false;
          this.selectMode = null;
        };
        document.addEventListener('mouseup', mouseDownCancel);
        this.$on('hook:beforeDestroy', () => {
          document.removeEventListener('mouseup', mouseDownCancel);
        });
      },
      selectItem (item) {
        if (this.selectMode === null) {
          this.selectMode = !item.selected;
        }
        if (item.selected !== this.selectMode) {
          this.$emit('select', item);
        }
      },
      onMouseOver (item) {
        if (!this.mouseDown) {
          return;
        }
        this.selectItem(item);
      }
    }
  };
</script>

<style>
  .weekday-hour__medium {
    --weekday-hour-control-item-size: 24px;
    --weekday-hour-control-item-font-size: 12px;
    --weekday-hour-control-item-horizontal-margin: 8px;
    --weekday-hour-control-item-vertical-margin: 6px;
  }

  .weekday-hour__large {
    --weekday-hour-control-item-size: 30px;
    --weekday-hour-control-item-font-size: 14px;
    --weekday-hour-control-item-horizontal-margin: 20px;
    --weekday-hour-control-item-vertical-margin: 10px;
  }

  .weekday-hour__container {
    display: flex;

    flex-wrap: wrap;

    width: var(--width-container-advanced-item);

    /* remove margins from first and last row and column */
    margin:
      calc(-1 * var(--weekday-hour-control-item-vertical-margin) / 2)
      calc(-1 * var(--weekday-hour-control-item-horizontal-margin) / 2);

    user-select: none;
  }

  .weekday-hour__label {
    font-size: var(--main-font-size);
    font-weight: var(--medium-font-weight);

    color: var(--text-color);

    padding-bottom: 12px;
  }

  .weekday-hour__item {
    font-size: var(--weekday-hour-control-item-font-size);
    font-weight: var(--main-font-weight);

    cursor: pointer;

    display: flex;

    flex: 0 0 var(--weekday-hour-control-item-size);
    justify-content: center;
    align-items: center;

    height: var(--weekday-hour-control-item-size);

    margin:
      calc(var(--weekday-hour-control-item-vertical-margin) / 2)
      calc(var(--weekday-hour-control-item-horizontal-margin) / 2);

    background-color: var(--gray-table-color);

    border-radius: calc(var(--weekday-hour-control-item-size) / 2);

    &.weekday-hour__item_active {
      color: var(--background-color);
      background-color: var(--action-color);
    }

    &:not(.weekday-hour__item_active):hover {
      background-color: var(--gray-color);
    }
  }

  .weekday-hour__content-block {
    display: flex;
    justify-content: space-between;
  }
</style>
