<template>
  <div
    :class="{
      'm-prompt__wrapper-hidden': componentIsHidden,
      [`m-prompt__${sizeModal}-size`]: sizeModal
    }"
    class="m-prompt m-prompt__screen-tint"
  >
    <div class="m-prompt__position-wrapper">
      <div class="m-prompt__container">
        <div
          v-if="headerText"
          class="m-prompt__header"
        >
          {{ headerText }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="bodyText"
          :class="{ 'm-prompt__text_padded': headerText }"
          class="m-prompt__text"
          v-html="bodyText"
        />
        <!-- eslint-enable -->
        <div class="m-prompt__controls">
          <m-button
            id="m-button-ok-m-prompt"
            :critical="okButtonType === 'critical'"
            :large="sizeModal === 'small'"
            @click.native="$emit('ok')"
          >
            {{ okText }}
          </m-button>
          <m-button
            id="m-button-cancel-m-prompt"
            :secondary="true"
            :large="sizeModal === 'small'"
            @click.native="$emit('cancel')"
          >
            {{ cancelText }}
          </m-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import translation from 'translation';

  export default {
    name: 'MPrompt',
    props: {
      headerText: {
        type: String,
        default: ''
      },
      bodyText: {
        type: String,
        default: ''
      },
      cancelText: {
        type: String,
        default: () => translation.tr('Cancel')
      },
      okText: {
        type: String,
        default: () => translation.tr('OK')
      },
      okButtonType: {
        type: String,
        default: ''
      },
      sizeModal: {
        type: String,
        default: 'default'
      }
    },
    data () {
      return {
        componentIsHidden: true
      };
    },
    mounted () {
      this.$nextTick(() => {
        this.activatePrompt();
        document.getElementById('m-button-ok-m-prompt').focus();
      });
    },
    methods: {
      activatePrompt () {
        this.componentIsHidden = false;
      },

      /**
       * Hide modal function
       * Returns promise that is resolved when animation is ended
       * @returns {Promise<any>}
       */
      removePrompt () {
        return new Promise(resolve => {
          this.componentIsHidden = true;

          // wait 200ms while animation is playing
          setTimeout(() => {
            resolve();
          }, 200);
        });
      }
    }
  };
</script>

<style>
  .m-prompt__default-size {
    .m-prompt__container {
      width: 368px;
      padding: 28px;
    }

    .m-prompt__text {
      font-size: 14px;

      line-height: 16px;

      text-align: center;
    }

    .m-prompt__controls {
      margin-top: 22px;

      & > * {
        flex-basis: 50px;

        max-width: 100px;

        margin: 0 12px;
      }
    }
  }

  .m-prompt__small-size {
    .m-prompt__container {
      width: 350px;
      padding: 16px 20px;
    }

    .m-prompt__text {
      font-size: 14px;
      font-weight: 400;

      line-height: 20px;

      letter-spacing: -0.03em;
    }

    .m-prompt__controls {
      margin-top: 28px;

      & > button {
        flex-basis: 50%;
        height: 36px;
        padding: 0;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .m-prompt {
    transition: opacity 200ms;
  }

  .m-prompt__wrapper-hidden {
    opacity: 0;
  }

  .m-prompt__screen-tint {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--popover-z-index);

    width: 100%;
    height: 100%;

    background-color: var(--modal-background-tint-color);
  }

  .m-prompt__position-wrapper {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  .m-prompt__container {
    box-sizing: border-box;

    background: var(--background-color);
    box-shadow: 0 4px 15px rgba(168, 168, 168, 0.25);

    border-radius: 5px;
  }

  .m-prompt__header {
    font-size: 18px;
    font-weight: var(--bold-font-weight);

    color: #000000;

    text-align: center;
  }

  .m-prompt__text {
    color: var(--secondary-text-color);
  }

  .m-prompt__text_padded {
    margin-top: 8px;
  }

  .m-prompt__controls {
    display: flex;
    justify-content: center;

    & > * {
      flex-grow: 1;
      justify-content: center;
    }
  }

  #m-button-ok-m-prompt:focus,
  #m-button-cancel-m-prompt:focus {
    box-shadow: 0 0 0 3px var(--action-disabled-color);
  }
</style>
