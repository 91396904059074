<template>
  <div class="statistics-table">
    <TableSettings
      :available-columns="availableColumns"
      :value="filteredActiveColumnIds"
      :show-vertical-separator="true"
      :default-enabled-preset="activeByDefaultColumns"
      :grouped-presets="columnPresets"
      :active-preset-id="activePresetId"
      :with-presets="true"
      :preserve-sorting="true"
      :preset-save-form-component="TableSettingsPresetSaveForm"
      :preset-save-form-handlers="savePresetHandlers"

      class="statistics-table__settings"

      @input="onActiveColumnsChange"
      @activatePreset="onActivatePreset"
      @deletePreset="deletePreset"
    >
      <DropdownMenu
        ref="export-dropdown"
        class="statistics-table-settings__export-data"
        :icon="iconDownload"
        :title="_('Export')"
      >
        <ul class="menu__list m-table-settings__export-menu">
          <MMenuLinkItem
            v-if="canDownloadCSV"
            class="menu__list-item"
            :name="_('Export to CSV')"
            @click.native="exportStatistics('csv')"
          />
          <MMenuLinkItem
            class="menu__list-item"
            :name="_('Export to XLSX')"
            @click.native="exportStatistics('xlsx')"
          />
        </ul>
      </DropdownMenu>
      <div
        class="table-settings__separator"
      />
      <RefreshButton
        :link="true"
        :icon-size="18"
        :disabled="isLoading"
        :is-loading="isLoading"
        :title="_('Refresh data')"
        class="m-table-settings-controls__button"
        @click="$emit('refreshStatistics')"
      />
      <transition name="downloading">
        <div
          v-if="isFileLoading || isFileLoaded"
          class="statistics-table__downloading"
        >
          <span class="statistics-table__downloading-message">
            {{ isFileLoading ? _('File will be downloaded soon') : _('File downloaded') }}
          </span>
          <SmallSpinner
            v-if="isFileLoading"
            class="statistics-table__downloading-spinner"
            :radius="7"
            :stroke-width="1.5"
          />
          <MIcon
            v-else
            class="statistics-table__downloading-check"
            :icon="iconCheckMark"
          />
        </div>
      </transition>
    </TableSettings>
    <DynamicTableHeightWrapper
      :paginated-table="true"
    >
      <PaginatedTable
        ref="table"

        :columns="activeColumns"
        :data="data"
        :items-total="itemsTotal"
        :per-page="itemsPerPage"
        :current-page="currentPage"

        :params="tableParams"

        :multi-sortable="true"
        :with-summary-row="withSummaryRow"
        :show-pagination="!tableIsEmpty"
        :initial-sorting="tableSorting"

        resizable

        @fetch="$emit('triggerFetchStat', $event)"
        @sort="$emit('sortTable', $event)"
        @update-params="updateTableParams"
      />
    </DynamicTableHeightWrapper>
    <div
      v-if="tableIsEmpty"
      class="empty-table-warn"
    >
      <MIcon
        class="empty-table-warn__magnifier-icon"
        :icon="iconMagnifier"
      />
      <div class="empty-table-warn__message">
        {{ _('No items found. Try selecting other filters.') }}
      </div>
    </div>
  </div>
</template>

<script>
  import iconDownload from 'assets/icons/download.svg';
  import iconCheckMark from 'assets/icons/checkmark.svg';
  import iconMagnifier from 'assets/illustrations/magnifier.svg';
  import {MIcon, PaginatedTable, TableSettings, SmallSpinner} from '@marilyn/marilyn-ui';
  import DropdownMenu from 'legacy/modules/common/components/DropdownMenu';
  import MMenuLinkItem from 'legacy/modules/common/components/MMenuLinkItem';
  import RefreshButton from 'legacy/modules/common/components/RefreshButton';
  import DynamicTableHeightWrapper from 'components/Tables/TableCells/DynamicTableHeightWrapper';
  import _ from 'lodash';
  import ColumnProcessorMixin from 'components/common/TableSettings/mixins/ColumnProcessorMixin';
  import TablePresetsMixin from 'components/common/TableSettings/mixins/TablePresetsMixin';
  import LocalStorageTableSettingsMixin from 'components/common/TableSettings/mixins/LocalStorageTableSettingsMixin';
  import TableSettingsPresetSaveForm from 'components/common/TableSettings/TableSettingsPresetSaveForm';
  import getColumnsChoices from 'components/Tables/TableSettings/utils/getColumnsChoices';
  import {mapGetters} from 'vuex';
  import parseMetric from 'utils/webanalytics/parseMetric';
  import {defaultProfileMetrics, defaultGoalMetrics} from 'pages/seller/statistics/components/StatisticsTable/utils/defaultMetrics';

  _.noConflict();

  function getRequiredColumns (groupings, dateGrouping) {
    const requiredForGrouping = {
      placement: ['placement_name'],
      order: ['order_name'],
      channel: ['channel_id'],
      project: ['project_name'],
      label: ['label_name'],
      keyword: ['keyword_text'],
      ad: ['ad_name']
    };
    const requiredForDateGrouping = dateGrouping === 'no' ? [] : ['date'];
    const requiredColumns = requiredForGrouping[groupings] || [];
    return requiredColumns.concat(requiredForDateGrouping);
  }

  export default {
    name: 'StatisticsTable',
    components: {
      MIcon,
      DynamicTableHeightWrapper,
      PaginatedTable,
      DropdownMenu,
      MMenuLinkItem,
      RefreshButton,
      SmallSpinner,
      TableSettings
    },
    mixins: [LocalStorageTableSettingsMixin, ColumnProcessorMixin, TablePresetsMixin],
    props: {
      accountId: {
        type: Number,
        required: true
      },
      data: {
        type: Array,
        default: Array
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      customMetrics: {
        type: Array,
        default: Array
      },
      profiles: {
        type: Array,
        default: Array
      },
      goals: {
        type: Array,
        default: Array
      },
      activeProfilesIds: {
        type: Array,
        default: Array
      },
      activeGoalsIds: {
        type: Array,
        default: Array
      },
      itemsTotal: {
        type: Number,
        default: 0
      },
      itemsPerPage: {
        type: Number,
        default: 0
      },
      currentPage: {
        type: Number,
        default: 0
      },
      tableName: {
        type: String,
        default: ''
      },
      tableSorting: {
        type: Array,
        required: true
      },
      channelFiltering: {
        type: Array,
        required: true
      },
      groupings: {
        type: String,
        default: ''
      },
      dateGrouping: {
        type: String,
        default: ''
      },
      canDownloadCSV: {
        type: Boolean,
        default: false
      },
      isFileLoading: {
        type: Boolean,
        default: false
      },
      isFileLoaded: {
        type: Boolean,
        default: false
      },
      adFormatsByChannelId: {
        type: Object,
        default: Object
      },
      columnsGetter: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        storageKeyName: this.tableName,
        tableViewName: this.tableName,

        TableSettingsPresetSaveForm,

        iconMagnifier,
        iconDownload,
        iconCheckMark
      };
    },
    computed: {
      ...mapGetters('sellerUser', ['currentAccount']),
      statisticsColumns () {
        const activeProfiles = this.profiles.filter(p => this.activeProfilesIds.includes(p.id));
        const activeGoals = this.goals.filter(p => this.activeGoalsIds.includes(p.id));

        return this.columnsGetter({
          groupings: this.groupings,
          dateGrouping: this.dateGrouping,
          customMetrics: this.customMetrics,
          activeProfiles,
          activeGoals,
          channelIds: this.channelFiltering
        });
      },
      statisticsColumnsFlat () {
        return this.statisticsColumns.map(c => {
          if (c.children) {
            return [
              c,
              ...c.children
            ];
          }
          return c;
        }).flat();
      },
      statisticsColumnsMap () {
        return _.keyBy(this.statisticsColumnsFlat, 'itemKey');
      },
      columnParentMap () {
        const map = {};
        this.statisticsColumns.forEach(c => {
          if (!c.children) {
            return;
          }

          c.children.forEach(child => {
            map[child.itemKey] = c.itemKey;
          });
        });
        return map;
      },
      filteredActiveColumnIds () {
        // save to local var to prevent calling vue's getter on every loop iteration
        const columnsMap = this.statisticsColumnsMap;
        return this.activeColumnsIds.filter(c => Boolean(columnsMap[c]));
      },
      activeColumns () {
        // save to local var to prevent calling vue's getter on every loop iteration
        const statisticsColumnsMap = this.statisticsColumnsMap;
        const columnParentMap = this.columnParentMap;

        const activeColumns = [];

        let activeParentId = null;
        let activeParent = null;

        this.filteredActiveColumnIds.forEach(cId => {
          const columnData = statisticsColumnsMap[cId];
          const parentId = columnParentMap[cId];

          // write data if parent changed
          if (activeParentId && parentId !== activeParentId) {
            activeParent = null;
            activeParentId = null;
          }

          if (!parentId) {
            activeColumns.push(columnData);
            return;
          }

          if (activeParent === null) {
            activeParentId = parentId;
            activeParent = {
              ...statisticsColumnsMap[parentId],
              children: []
            };
            activeColumns.push(activeParent);
          }

          activeParent.children.push(columnData);
        });

        return activeColumns;
      },
      availableColumns () {
        return getColumnsChoices(this.statisticsColumns, this.requiredColumnsForGrouping);
      },
      availableColumnIds () {
        return this.availableColumns.map(group => group.children.map(c => c.id)).flat();
      },
      activeByDefaultColumns () {
        const allColumns = this.availableColumns.map(group => group.children).flat();


        return allColumns.filter(c => {
          const m = parseMetric(c.id);
          const isNotDefaultProfileMetric = m.profileId && !defaultProfileMetrics.includes(m.simpleMetricId);
          const isNotDefaultGoalMetric = m.goalId && !defaultGoalMetrics.includes(m.simpleMetricId);

          if (isNotDefaultProfileMetric) {
            return false;
          }

          if (isNotDefaultGoalMetric) {
            return false;
          }

          return c.show === undefined || c.show === true;
        }).map(c => c.id);
      },
      withSummaryRow () {
        return this.itemsTotal > 1;
      },
      tableIsEmpty () {
        return !this.isLoading && this.data.length === 0;
      },
      requiredColumnsForGrouping () {
        return getRequiredColumns(this.groupings, this.dateGrouping);
      },
      requiredColumnIds () {
        const existingColumnsSet = new Set(this.availableColumnIds);
        return this.requiredColumnsForGrouping.filter(i => existingColumnsSet.has(i));
      }
    },
    watch: {
      accountId: {
        immediate: true,
        handler () {

          // account is null when leaving page
          if (!this.currentAccount) {
            return;
          }
          this.fetchPresets();
        }
      }
    },
    methods: {
      onTriggerFetch (oldColumns, newColumns) {
        if (!this.currentAccount) {
          return;
        }
        this.$emit('columnsChanged', {oldColumns, newColumns});
      },
      onActiveColumnsChange (newColumns) {
        const oldColumns = this.activeColumnsIds;
        this.activeColumnsIds = newColumns;
        this.activePresetId = null;
        this.onTriggerFetch(oldColumns, newColumns);
      },
      exportStatistics (format) {
        this.$emit('download-statistics', {format, fields: this.activeColumnsIds});
        this.$refs['export-dropdown'].$refs.popover.closeDropdown();
      }
    }
  };
</script>

<style>
  .statistics-table__downloading {
    display: flex;

    align-items: center;

    margin-left: 40px;
  }

  .statistics-table__downloading-message {
    font-weight: 400;
    color: #666666;
  }

  .statistics-table__downloading-spinner {
    margin-left: 10px;
    stroke: var(--main-color);
  }

  .statistics-table__downloading-check {
    width: 22px;
    height: 22px;

    margin-left: 10px;

    fill: var(--success-color);
  }

  .downloading-enter-active,
  .downloading-leave-active {
    transition: opacity 0.3;
  }

  .downloading-enter,
  .downloading-leave-to {
    opacity: 0;
  }

  .empty-table-warn {
    display: flex;

    align-items: center;

    padding: 16px 22px;

    margin-top: 12px;

    background-color: var(--gray-table-color);

    border-radius: 4px;
  }

  .statistics-table__settings {
    margin-bottom: 16px;
  }

  .empty-table-warn__magnifier-icon {
    color: var(--panel-text-color);

    width: 18px;
    height: 18px;
  }

  .empty-table-warn__message {
    font-size: 14px;

    color: var(--text-main-color);

    margin-left: 10px;
  }

  .m-table-settings-controls__button,
  .statistics-table-settings__export-data {
    margin-left: 12px;
  }
</style>
