<template>
  <MInput
    ref="input"
    :value="formattedValue"
    v-bind="commonInputProps"
    autocomplete="off"
    class="m-numeric-input"
    @click="onClick"
    @input="onChange"
  />
</template>

<script>
  import FormatNumberMixin from 'legacy/mixins/components/FormatNumberMixin';
  import InputMixin from 'legacy/mixins/components/InputMixin';
  import MInput from 'legacy/modules/common/components/MInput';
  import AutoNumeric from 'legacy/lib/patched/autoNumeric';
  import {mdash, clear} from 'legacy/utils';
  import BigNumber from 'bignumber.js';
  import * as configs from 'legacy/modules/common/components/MNumericInput/MNumericInputConfigurations';
  import _ from 'lodash';

  _.noConflict();


  export default {
    name: 'MNumericInput',
    components: {MInput},
    mixins: [InputMixin, FormatNumberMixin],
    props: {
      value: {
        type: [Number, String],
        default: ''
      },
      vat: {
        type: [Boolean, Object, BigNumber],
        default: false
      },
      showCurrencySymbol: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      autonumericConfig () {
        /*
         * configs - is module with numeric config object factory
         * here we are getting config factory for specific numeric type, or getting default factory
         */
        let config = (configs[this.type] || configs.defaults)(clear({
          minimumValue: this.localMinimumValue,
          maximumValue: this.maximumValue,
          symbol: this.symbol,
          negative: this.negative,
          allowDecimalPadding: this.allowDecimalPadding
        }), this.value);
        if (!this.showCurrencySymbol) {
          config = _.omit(config, ['currencySymbol']);
        }
        return config;
      },
      isInteger () {
        return this.autonumericConfig.decimalPlaces === 0;
      },
      limitedValue () {
        const max = new BigNumber(this.autonumericConfig.maximumValue);
        const min = new BigNumber(this.autonumericConfig.minimumValue);
        if (min.isGreaterThan(this.value)) {
          return min;
        } else if (max.isLessThan(this.value)) {
          return max;
        }
        return new BigNumber(this.value);
      },
      valueWithVat () {
        if (this.vat) {
          return this.limitedValue.times(this.vat);
        }
        return this.limitedValue;
      },
      formattedValue () {
        const formattedValue = this.getFormattedNumber(this.bigNumberToFixed(this.valueWithVat));
        return formattedValue === mdash ? '' : formattedValue;
      }
    },
    watch: {
      value () {
        this.forceSetValue();
      },
      disabled () {
        this.forceSetValue();
      },
      vat () {
        this.forceSetValue();
      },
      autonumericConfig: {
        handler () {
          this.forceSetValue(() => {
            // do not call update if element was deleted during waiting for next frame (2 x requestAnimationFrame)
            if (this.$refs.input && this.$refs.input.$refs) {
              // update type after value limit is applied
              this.autoNumeric.remove();
              this.initAutonumeric();
            }
          });
          this.onChange();
        },
        deep: true
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.initAutonumeric();
      });
    },
    destroyed () {
      if (this.autoNumeric) {
        this.autoNumeric.remove();
      }
    },
    methods: {
      bigNumberToFixed (val) {
        return parseFloat(val.toFixed(this.autonumericConfig.decimalPlaces || 2));
      },
      removeVat (val) {
        let bigNumberVal = new BigNumber(val);
        if (this.vat) {
          bigNumberVal = bigNumberVal.div(this.vat);
        }
        return this.bigNumberToFixed(bigNumberVal);
      },
      forceSetValue (callback) {

        // autonumeric should set is's value after vue render
        this.$nextFrame(() => {
          try {
            this.autoNumeric.set(this.valueWithVat);
          } catch (e) {

            /*
             * autonumeric may throw error when changing `mininumValue` and `maximumValue`
             * but result will be correctly handled so just do nothing here
             */
          }
          if (callback) {
            callback();
          }
        });
      },
      initAutonumeric () {
        if (this.$refs.input && this.$refs.input.$refs.input) {
          this.autoNumeric = new AutoNumeric(this.$refs.input.$refs.input, this.autonumericConfig);
          this.autoNumeric.set(this.valueWithVat);
        }
      },
      onClick (target) {
        if (this.autonumericConfig.currencySymbol) {
          const start = target.selectionStart;
          let end = target.selectionEnd;
          const numericSymbolsCount = target.value.length - this.autoNumeric.originalCurrencySymbol.length;

          // move cursor to the end of number, we do not want to cursor stay after currency symbol
          if (end === start && end > numericSymbolsCount) {
            end = numericSymbolsCount;
            target.setSelectionRange(numericSymbolsCount, numericSymbolsCount);
          }
        }
      },
      onChange () {
        this.$nextFrame(() => {
          let val = parseFloat(this.autoNumeric.rawValue);

          if (isNaN(val)) {
            val = null;
          } else {
            val = this.removeVat(val);
            if (this.isInteger) {
              val = parseInt(this.autoNumeric.rawValue);
            }
          }

          this.$emit('input', val);
        });
      }
    }
  };
</script>

<style>
  .m-numeric-input {
    input {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;

      line-height: 16px;
    }

    /* пришлось копипастить, т.к. scss формат записи не работает для псевдоэлементов */
    input::placeholder {
      font-size: 14px;
      font-weight: 400;

      color: #A8A8A8;
      line-height: 16px;
    }

    input::input-placeholder {
      font-size: 14px;
      font-weight: 400;

      color: #A8A8A8;
      line-height: 16px;
    }

    input::placeholder {
      font-size: 14px;
      font-weight: 400;

      color: #A8A8A8;
      line-height: 16px;
    }

    input:input-placeholder {
      font-size: 14px;
      font-weight: 400;

      color: #A8A8A8;
      line-height: 16px;
    }

    input::input-placeholder {
      font-size: 14px;
      font-weight: 400;

      color: #A8A8A8;
      line-height: 16px;
    }
  }
</style>
