<template>
  <component
    :is="componentType"
    class="menu__list-item_link"
    :href="url"
    :title="title"
    :target="target"
    @click="handleClick"
  >
    {{ name }}
    <MIcon
      v-for="(icon, index) in icons"
      :key="index"
      class="menu__list-item-icon"
      :icon="icon"
    />
  </component>
</template>

<script>
  import {MIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'MMenuLinkItem',
    components: {MIcon},
    props: {
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: null
      },
      action: {
        type: Function,
        default: null
      },
      target: {
        type: String,
        default: null
      },
      icons: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      componentType () {
        return this.url ? 'a' : 'span';
      }
    },
    methods: {
      handleClick () {
        if (this.action) {
          this.action();
        }
        this.$emit('click');
      }
    }
  };
</script>

<style>
  .menu__list-item {
    &.menu__list-item_link {
      line-height: var(--menu-item-height);
      text-overflow: ellipsis;

      text-decoration: none;

      white-space: nowrap;

      overflow: hidden;

      display: flex;

      align-items: center;
    }
  }

  .menu__list-item-icon {
    width: 16px;
    height: 16px;

    margin-left: 6px;
  }

  .menu__list-item-icon:not(:first-of-type) {
    margin-left: 4px;
  }
</style>
