<template>
  <div class="info-panel">
    <button
      class="info-panel__header"
      @click="isOpen = !isOpen"
    >
      <span class="info-panel__icon">
        <m-icon
          :icon="lightningIcon"
          class="info-panel-icon"
        />
      </span>
      <span class="info-panel__title">
        Стратегия будет работать эффективно, если на кампанию тратится от 1 000
        ₽ в день
      </span>
      <span class="info-panel__toggle">
        <m-icon
          :icon="bulletArrowIcon"
          class="info-panel-header__icon"
          :class="{ 'info-panel-header__icon_opened': isOpen }"
        />
      </span>
    </button>
    <div
      v-if="isOpen"
      class="info-panel__content"
    >
      <p class="info-panel__text">
        Чтобы траты не снижались, убедитесь, что в кампании нет ограничений по
        настройкам, хватает&nbsp;бюджета, товар есть в наличии, а максимальная ставка
        в Marilyn не слишком низкая
      </p>
    </div>
  </div>
</template>

<script>
  import bulletArrowIcon from 'assets/icons/bullet-arrow.svg';
  import lightningIcon from 'assets/icons/lightning.svg';

  export default {
    data () {
      return {
        bulletArrowIcon,
        lightningIcon,
        isOpen: false
      };
    }
  };
</script>

<style scoped>
.info-panel {
  border-radius: 5px;
  margin: 32px 0;
  border-radius: 5px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 12px 0px rgba(71, 71, 71, 0.1);
  overflow: hidden;
}

.info-panel__header {
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  background: rgba(0, 184, 212, 0.1);
  padding: 8px 16px;
}

.info-panel__icon {
  margin-right: 12px;
  color: #0091ea;
}

.info-panel__title {
  flex: 1;
  color: #242424;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
}

.info-panel__toggle {
  margin-left: 8px;
  color: #696969;
}

.info-panel__content {
  padding: 12px 16px;
  color: var(--, #242424);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.info-panel__text {
  font-size: 14px;
  color: #4f4f4f;
  margin: 0;
  color: var(--, #242424);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.info-panel-icon {
  width: 18px;
  height: 18px;
}

.info-panel-header__icon {
  width: 18px;
  height: 18px;

  transform: rotate(90deg);

  transition: transform var(--transition-duration);
}

.info-panel-header__icon_opened {
  transform: rotate(-90deg);
}
</style>
