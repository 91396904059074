<template>
  <div
    class="unsupported-browser-notification"
    :class="{'unsupported-browser-notification_closed': !opened}"
  >
    <m-icon
      class="unsupported-browser-notification__warning"
      :icon="warningIcon"
    />
    <div class="unsupported-browser-notification__content">
      <span class="unsupported-browser-notification__header">
        {{ _('Your web browser is out of date\nor not supported') }}
      </span>
      <div
        v-trim-whitespace
        class="unsupported-browser-notification__text"
      >
        {{ _('This browser version is not supported.') }}<br>
        {{ _('For best experience, please, switch to the latest version of') }}
        <m-link href="https://www.google.com/chrome">
          Chrome
        </m-link>
        {{ _('or') }}
        <m-link href="https://www.mozilla.org/firefox/new">
          Firefox
        </m-link>
      </div>
    </div>
    <m-icon
      class="unsupported-browser-notification__close"
      :icon="crossIcon"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
  import warningIcon from 'assets/icons/warning.svg';
  import crossIcon from 'assets/icons/cross.svg';

  export default {
    name: 'UnsupportedBrowserNotification',
    data () {
      return {
        opened: false,
        warningIcon,
        crossIcon
      };
    },
    mounted () {
      this.$nextTick(() => {
        this.open();
      });
    },
    methods: {
      open () {
        return new Promise(resolve => {
          this.opened = true;

          // wait 200ms while animation is playing
          setTimeout(resolve, 200);
        });
      },
      close () {
        return new Promise(resolve => {
          this.opened = false;

          // wait 200ms while animation is playing
          setTimeout(resolve, 200);
        });
      }
    }
  };
</script>

<style>
  .unsupported-browser-notification {
    position: absolute;
    display: flex;
    right: 30px;
    bottom: 30px;
    z-index: var(--unsupported-browser-notification-z-index);

    box-sizing: border-box;
    width: 450px;

    padding: 16px;

    background-color: var(--background-color);
    box-shadow: var(--dropdown-box-shadow);

    border-radius: var(--border-radius);

    transition: opacity 200ms ease-in-out;
  }

  .unsupported-browser-notification_closed {
    opacity: 0;
  }

  .unsupported-browser-notification__warning {
    color: var(--warning-color);

    flex-shrink: 0;

    width: 18px;
    height: 18px;

    margin-right: 7px;
  }

  .unsupported-browser-notification__content {
    display: flex;
    flex-direction: column;
  }

  .unsupported-browser-notification__header {
    font-size: 16px;
    font-weight: var(--bold-font-weight);

    color: var(--warning-color);
    line-height: 20px;

    margin-bottom: 8px;
  }

  .unsupported-browser-notification__text {
    font-size: 14px;

    color: var(--secondary-text-color);
    line-height: 18px;
  }

  .unsupported-browser-notification__close {
    color: var(--gray-text-color);

    cursor: pointer;

    display: flex;

    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    width: 16px;
    height: 16px;
  }

  .unsupported-browser-notification__close:hover {
    color: var(--text-main-color);
  }
</style>
