<template>
  <MTableCell class="m-table-cell-campaign-metric">
    <div @click="handleClick">
      <MHint
        placement="right"
        :allow-show="hasStrategy"
        offset="0,5"
        hint-class="m-table-cell-campaign-metric-hint"
      >
        <div
          class="m-table-cell-campaign-metric__content"
          :class="{
            '__not-need-background': isNotEnoughTime || !hasStrategy,
            '__is-not-enough-time': isNotEnoughTime
          }"
        >
          <template v-if="!hasStrategy">
            <span>—</span>
          </template>

          <MIcon
            v-else-if="isNotEnoughTime"
            class="progress-metrics-stats-cell__time-enough__icon"
            :icon="timeIcon"
          />

          <template v-else>
            <MNumeric
              v-if="withValue"
              class="progress-metrics-stats-cell__value"
              :value="metricStats.value"
              :allow-decimal-padding="false"
              :decimal-places="0"
            />

            <div
              :class="classListChangeValue"
              class="progress-metrics-stats-cell__change"
            >
              <MIcon
                class="progress-metrics-stats-cell__change__icon"
                :icon="shapeIcon"
              />

              <span class="progress-metrics-stats-cell__change__value">{{ changeValue }}</span>
            </div>
          </template>
        </div>

        <template v-slot:content>
          <template v-if="!hasStrategy" />

          <template v-else-if="isNotEnoughTime">
            Динамика появится в&nbsp;течение 3&nbsp;дней после&nbsp;запуска стратегии
          </template>

          <template v-else>
            Процент показывает изменения по&nbsp;сравнению с&nbsp;предыдущей неделей
          </template>
        </template>
      </MHint>
    </div>
  </MTableCell>
</template>

<script>
  import MNumeric from 'legacy/modules/common/components/MNumeric/MNumeric';
  import {mTableCells, MIcon, MHint} from '@marilyn/marilyn-ui';
  import shapeIcon from 'assets/icons/shape.svg';
  import timeIcon from 'assets/icons/time.svg';
  import moment from 'moment';
  import {parseProgressMetrics} from 'utils/optimization/bidmanager/progressMetrics';

  export default {
    name: 'MTableCellCampaignMetric',
    components: {
      MNumeric,
      MIcon,
      MTableCell: mTableCells.MTableCell,
      MHint
    },
    props: {
      itemKey: {
        type: String,
        required: true
      },
      tableRow: {
        type: Object,
        default: Object
      },
      withValue: {
        type: Boolean,
        default: false
      },
      defaultDisplayMetric: {
        type: String,
        default: 'first_metric',
        validator: (val) => ['first_metric', 'second_metric'].includes(val)
      },
      availableProgressMetricsMap: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        shapeIcon,
        timeIcon
      };
    },
    computed: {
      changeValue () {
        return `${Math.abs(this.metricStats.change)}%`;
      },
      isNotEnoughTime () {
        return !(this.progressMetricsStats.days > 0);
      },
      isPositiveChange () {
        return this.metricStats.change > 0;
      },
      classListChangeValue () {
        return [
          `__metric-change-type-${this.getMetricChangeType(this.metricId, this.metricStats)}`,
          `__metric-change-value-${this.getValueChangeType(this.metricStats.change)}`
        ];
      },
      hasStrategy () {
        return Boolean(this.tableRow.strategy_id);
      },
      defaultDisplayMetricCamelCase () {
        if (this.defaultDisplayMetric === 'first_metric') {
          return 'firstMetric';
        }

        if (this.defaultDisplayMetric === 'second_metric') {
          return 'secondMetric';
        }

        return null;
      },
      metricId () {
        return this.parsedProgressMetrics[this.defaultDisplayMetricCamelCase];
      },
      parsedProgressMetrics () {
        return parseProgressMetrics(this.progressMetrics);
      },
      itemKeyNormalize () {
        return this.itemKey.split('_')[0];
      },
      progressMetricsStats () {
        return this.tableRow[this.itemKeyNormalize] || {days: null};
      },
      progressMetrics () {
        return this.tableRow.progress_metrics;
      },
      metricStats () {
        return this.formatMetricStats(this.metricId, this.progressMetricsStats[this.defaultDisplayMetric]);
      }
    },
    methods: {
      formatMetricStats (metricId, rawMetricStats) {
        const stats = rawMetricStats || {};
        const value = stats.value !== undefined ? Number(stats.value) : null;
        const change = stats.change !== undefined ? Number(stats.change) : null;

        return {
          value,
          change,
          ...this.availableProgressMetricsMap[metricId]
        };
      },
      handleClick () {
        if (!this.hasStrategy || this.isNotEnoughTime) {
          return;
        }
        const routeData = {
          name: 'sellerStatistics',
          query: {
            date_grouping: 'day',
            start_date: this.getFormattedDate(15),
            end_date: moment().format('YYYY-MM-DD'),
            grouping: 'no',
            filtering: 'placement',
            entities: this.tableRow.id,
            sorting: 'date'
          }
        };

        window.open(this.$router.resolve(routeData).href, '_blank');
      },
      getFormattedDate (days) {
        return moment().subtract(days, 'days').
          format('YYYY-MM-DD');
      },
      getMetricChangeType (metricId, metricStats) {
        if (metricStats.change === 0) {
          return 'neutral';
        }

        const isDirectCorrelation = this.availableProgressMetricsMap[metricId].isGrowthPositive;
        const isGood = isDirectCorrelation ? metricStats.change > 0 : metricStats.change < 0;
        if (isGood) {
          return 'positive';
        }

        return 'negative';
      },
      getValueChangeType (change) {
        if (change === 0) {
          return 'neutral';
        }

        return change > 0 ? 'positive' : 'negative';
      }
    }
  };
</script>

<style>
  .m-table-cell-campaign-metric-hint {
    width: 224px !important;
    padding: 12px !important;
  }

  .m-table-cell-campaign-metric__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    min-width: 60px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #F9F9F9;
    transition: .2s background-color;
    cursor: pointer;

    &:hover {
      background-color: #EAEAEA;
    }

    &.__not-need-background {
      background-color: unset;
      cursor: default;
    }

    &.__is-not-enough-time {
      min-width: unset;
      padding: 0;
    }
  }

  .progress-metrics-stats-cell__value {
    margin-right: 8px;
  }

  .progress-metrics-stats-cell__change {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .progress-metrics-stats-cell__change__icon {
      width: 7.5px;
      height: 3.75px;
      flex-shrink: 0;
      margin-right: 2px;
    }

    &.__metric-change-value-positive {
      .progress-metrics-stats-cell__change__icon {
        transform: rotate(180deg);
      }
    }

    &.__metric-change-value-negative {
      .progress-metrics-stats-cell__change__icon {
        transform: rotate(0deg);
      }
    }

    &.__metric-change-value-neutral {
      .progress-metrics-stats-cell__change__icon {
        display: none;
      }
    }

    &.__metric-change-type-positive {
      color: #4B924D;
    }

    &.__metric-change-type-negative {
      color: #C6474F;
    }

    &.__metric-change-type-neutral {
      color: #747474;
    }
  }

  .progress-metrics-stats-cell__time-enough__icon {
    height: 16px;
    width: 16px;
    color: #696969;
    flex-shrink: 0;
  }
</style>
