<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    class="pie-widget"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="argumentsValues.name"
        :metrics="argumentsValues.metrics"
        :profiles="profiles"
        :external-href="externalHref"
        @click="$emit('cancel')"
      />
    </template>
    <template>
      <div
        v-if="dataIsNotNull"
        ref="plotContainer"
        class="pie-widget__plot"
      >
        <PieWidgetChart
          ref="chart"
          :total="total"
          :statistics="data.items"
          :metric="targetMetric"
          :metric-type="metricType"
          :grouping="dsParams.grouping"
          :color-getter="colorGetter"
          :height="data.items.length > 4 ? '200px' : '266px'"
        />
      </div>
      <CardNoDataText
        v-else
      />
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import PieWidgetChart from 'components/Dashboards/Widgets/PieWidget/PieWidgetChart';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';
  import AppEventBus from 'utils/appEventBus';

  export default {
    name: 'PieWidget',
    components: {
      CardNoDataText,
      PieWidgetChart,
      DashboardCard,
      WidgetHeader
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      metrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: null
      },
      colorGetter: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        pieHeight: 0
      };
    },
    computed: {
      dataIsNotNull () {
        return this.data.items.length > 0 && this.data.items.some(i => Boolean(Number(i[this.targetMetric])));
      },
      total () {
        return this.data.total && this.data.total[this.targetMetric]
          ? Number(this.data.total[this.targetMetric])
          : null;
      },
      targetMetric () {
        return this.argumentsValues.metrics[0];
      },
      metricType () {
        return this.metrics[this.targetMetric].type;
      }
    },
    watch: {
      data: {
        immediate: true,
        handler () {
          this.updateTotalElHeight();
        }
      }
    },
    mounted () {
      AppEventBus.$on('PAGE_RESIZE', this.updateTotalElHeight);
      this.$on('hook:destroyed', () => {
        AppEventBus.$off('PAGE_RESIZE', this.updateTotalElHeight);
      });
    },
    methods: {
      updateTotalElHeight () {
        this.$nextFrame(() => {

          const pieNode = this.$refs.chart;
          if (!pieNode) {
            return;
          }
          const pieEl = pieNode.$el.querySelector('.apexcharts-svg').children[1];
          this.pieHeight = pieEl.getBoundingClientRect().height;
        });
      }
    }
  };
</script>

<style>
  .pie-widget__plot {
    position: relative;

    height: 100%;

    padding: 5px 20px;
  }

  .pie-widget__plot .apexcharts-legend.position-right {
    justify-content: center;
  }
</style>
