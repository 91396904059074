<template>
  <div class="m-tour-steps-controls">
    <div
      v-if="manySteps"
      class="m-tour-steps-controls__progress"
    >
      {{ currentStepNum }} из {{ stepsCount }}
    </div>
    <div class="m-tour-step-controls__navigation">
      <a
        v-if="showBackButton"
        class="m-tour-step-controls__button-back"
        @click.prevent="$emit('prev')"
      >Назад</a>

      <MButton
        v-if="isNotLastStep"
        class="m-tour-step-controls__button"
        @click="$emit('next')"
      >
        Далее
      </MButton>

      <MButton
        v-if="isLastStep && !hideFinishButton"
        class="m-tour-step-controls__button m-tour-step-controls__finish"
        @click="$emit('stop')"
      >
        {{ okText }}
      </MButton>
    </div>
  </div>
</template>

<script>
  import {MButton} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTourStepControls',
    components: {MButton},
    props: {
      currentIndex: {
        type: Number,
        default: 0
      },
      stepsCount: {
        type: Number,
        default: 0
      },
      okText: {
        type: String,
        default: 'Закончить'
      },
      hideFinishButton: {
        type: Boolean,
        default: false
      },
      notHideBackButtonOnLastStep: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      showBackButton () {
        return (this.notHideBackButtonOnLastStep || this.isNotLastStep) && this.isNotFirstStep;
      },
      currentStepNum () {
        return this.currentIndex + 1;
      },
      isFirstStep () {
        return this.currentStepNum === 1;
      },
      isNotFirstStep () {
        return !this.isFirstStep;
      },
      isLastStep () {
        return this.currentStepNum === this.stepsCount;
      },
      isNotLastStep () {
        return !this.isLastStep;
      },
      manySteps () {
        return this.stepsCount > 1;
      }
    }
  };
</script>

<style>
  .m-tour-steps-controls {
    display: flex;
    align-items: center;
  }

  .m-tour-steps-controls__progress {
    font-size: 14px;
    font-weight: var(--medium-font-weight);

    color: var(--gray-text-color);
  }

  .m-tour-step-controls__navigation {
    display: flex;

    align-items: center;

    margin-left: auto;
  }

  a.m-tour-step-controls__button-back {
    font-size: 14px;
    font-weight: var(--medium-font-weight);

    color: var(--gray-text-color);

    cursor: pointer;

    display: inline-block;

    padding: 8px 12px;

    margin-right: 10px;
    user-select: none;
  }

  a.m-tour-step-controls__button-back:hover {
    color: var(--panel-text-color);
  }

  .m-tour-step-controls__button.m-button {
    font-weight: var(--bold-font-weight);
    padding: 8px 12px;
  }

  .m-tour-step-controls__finish.m-button {
    /*
     * it is very very bad, but it's hard to override without important,
     * because default button color has really hard selector
     */
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--success-color) !important;

    &:hover {
      background-color: var(--success-hover-color) !important;
    }
  }

</style>
