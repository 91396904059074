<template>
  <MTableCell>
    <div class="m-table-cell-placement-type">
      <span>{{ itemText }}</span>
    </div>
  </MTableCell>
</template>

<script>
  import {mTableCells} from '@marilyn/marilyn-ui';
  import {getPlacementTypeNameById} from 'utils/seller/sellerPlacementUtils';

  export default {
    name: 'MTableCellPlacementType',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    props: {
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      itemText () {
        return getPlacementTypeNameById(this.tableRow[this.itemKey]);
      }
    }
  };
</script>

<style>
  .m-table-cell-placement-type {
    font-size: 12px;
    font-weight: 400;

    color: #242424;
    line-height: 14px;

    letter-spacing: -0.01em;
  }
</style>
