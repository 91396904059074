<template>
  <MTableCell>
    <div class="m-table-cell-schedule-setting">
      <MButton
        link
        :title="itemText"
        :disabled="isCampaignDisable"
        class="m-table-cell-schedule-setting__button"
        @click="openScheduleSettingModal"
      >
        {{ itemText }}
      </MButton>
    </div>
  </MTableCell>
</template>

<script>
  import {mTableCells} from '@marilyn/marilyn-ui';
  import {formatToTextScheduleMatrix, normalizeScheduleMatrix, normalToMatrix} from 'utils/Date/formatScheduleMatrix';
  import ScheduleControlModal from 'modules/common/components/SheduleControl/ScheduleControlModal.vue';
  import MModalMixin from 'modules/common/components/MModal/MModalMixin';
  import ApiBadRequestError from 'utils/api/errors/ApiBadRequestError';
  import translation from 'translation';
  import {mapActions} from 'vuex';
  import {EDIT_SCHEDULE_PLACEMENT} from 'store/modules/seller/campaignsSeller';

  export default {
    name: 'MTableCellScheduleSetting',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    mixins: [MModalMixin],
    props: {
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        modalComponent: ScheduleControlModal,
        modalProps: {},
        isLoading: false,
        modalEventListeners: {
          input: this.handleSave,
          cancel: this.handleCancel
        }
      };
    },
    computed: {
      isCampaignDisable () {
        const status = this.tableRow.status;
        return !(status === 'RUNNING' || status === 'PAUSED');
      },
      propsForModal () {
        return {
          ...normalizeScheduleMatrix(this.tableRow[this.itemKey]),
          isOnlyAdvanced: true,
          headerText: 'Расписание показов',
          subtitleText: 'Выберите, когда реклама должна быть активна',
          advancedDayTitle: 'Дни',
          advancedHourTitle: 'Часы (Московское время)'
        };
      },
      itemText () {
        const {days, time} = formatToTextScheduleMatrix(this.tableRow[this.itemKey]);
        if (!days || !time) {
          return '-';
        }

        return `${days}, ${time}`;
      }
    },
    methods: {
      ...mapActions('sellerCampaigns', {
        editSchedulePlacement: EDIT_SCHEDULE_PLACEMENT
      }),
      openScheduleSettingModal () {
        this.modalProps = this.propsForModal;
        this.showPopup();
      },
      async handleSave (payload) {
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;

        try {
          const normalizedData = normalToMatrix(payload, [this.tableRow.id]);
          await this.editSchedulePlacement(normalizedData);
          this.hidePopup();
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            this.$notify({
              type: 'error',
              header: translation.tr('Error'),
              text: e.getErrorMessage()
            });
          } else {
            throw e;
          }
        }

        this.isLoading = false;
      },
      handleCancel () {
        this.hidePopup();
      }
    }
  };
</script>

<style>
  .m-table-cell-schedule-setting {
    overflow: hidden;

    button.m-table-cell-schedule-setting__button {
      max-width: 100%;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
</style>
