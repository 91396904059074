<template>
  <div
    class="switcher-container"
    :class="{
      'is-active': state,
      'switcher-container_large': isLarge,
      'switcher-container_medium': isMedium,
      'switcher-container_small': isSmall,
      'switcher-container_label-left': labelPosition==='left',
      'switcher-container_label-right': labelPosition==='right',
      'switcher-container_disabled': disabled,
    }"
    @click="triggerSwitcher"
  >
    <div class="switch">
      <div class="switch-dot" />
    </div>
    <span
      v-show="label"
      class="switcher-label"
    >{{ label }}</span>
  </div>
</template>

<script>
  export default {
    name: 'Switcher',
    model: {
      prop: 'state',
      event: 'switched'
    },
    props: {
      state: {
        type: Boolean,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      labelPosition: {
        type: String,
        choices: ['left', 'right'],
        default: 'left'
      },
      size: {
        type: String,
        choices: ['s', 'm', 'l'],
        default: 'm'
      }
    },
    computed: {
      isSmall () {
        return this.size === 's';
      },
      isMedium () {
        return this.size === 'm';
      },
      isLarge () {
        return this.size === 'l';
      }
    },
    methods: {
      triggerSwitcher () {
        if (!this.disabled) {
          this.$emit('switched', !this.state);
        }
      }
    }
  };
</script>

<style>
  .switcher-label {
    font-size: 14px;
    font-weight: var(--medium-font-weight);
  }

  .switcher-container_label-left {
    flex-direction: row-reverse;

    & .switcher-label {
      margin-right: var(--switch-label-offset);
    }
  }

  .switcher-container_label-right {
    flex-direction: row;

    & .switcher-label {
      margin-left: var(--switch-label-offset);
    }
  }

  .switcher-container_large {
    --switch-width: 54px;
    --switch-height: 28px;
  }

  .switcher-container_medium {
    --switch-width: 42px;
    --switch-height: 22px;
  }

  .switcher-container_small {
    --switch-width: 30px;
    --switch-height: 16px;
  }

  .switcher-container {
    /* constants */
    --switch-paddings: 2px;
    --switch-border-size: 1px;
    --switch-border-radius: 30px;
    --switch-transition: var(--transition-duration) all ease;
    --switch-label-offset: var(--label-offset);

    /* variables */
    --switch-color: var(--background-color);
    --switch-border-color: var(--gray-color);
    --switch-dot-color: #D4D7D9;
    --switch-dot-position: 0px;

    cursor: pointer;

    display: inline-flex;

    align-items: center;
  }

  .switcher-container.is-active {
    --switch-color: var(--action-secondary-color);
    --switch-border-color: #D6EAFF;
    --switch-dot-color: var(--action-color);
    --switch-dot-position: calc(var(--switch-width) - var(--switch-height));
  }

  .switcher-container.switcher-container_disabled {
    --switch-color: var(--gray-table-color);
    --switch-border-color: var(--gray-table-color);
    --switch-dot-color: var(--border-color);

    cursor: not-allowed;
  }

  .switch {
    flex-shrink: 0;

    box-sizing: border-box;
    width: var(--switch-width);
    height: var(--switch-height);

    padding-left: var(--switch-dot-position);

    background-color: var(--switch-color);

    border: var(--switch-border-size) solid var(--switch-border-color);

    border-radius: var(--switch-border-radius);

    transition: var(--switch-transition);
  }

  .switch-dot {
    width: calc(var(--switch-height) - 2 * var(--switch-border-size) - 2 * var(--switch-paddings));
    height: calc(var(--switch-height) - 2 * var(--switch-border-size) - 2 * var(--switch-paddings));

    margin: var(--switch-paddings);

    background-color: var(--switch-dot-color);

    border-radius: 100%;

    transition: var(--switch-transition);
  }
</style>
