<template>
  <div>
    <div
      v-if="tabs.length"
      class="tabs"
      :class="{'tabs_wide': isSidebarClosed}"
    >
      <template v-for="tab in tabs.filter(x => !x.disabled)">
        <TabGroup
          v-if="tab.items"
          :key="tab.id"
          :group-id="tab.id"
          class="tabs__tab tabs__tab_group"
          :class="[`tabs__${tab.id}-tab`]"
          :popper-class="['tabs__tab-group-popper', tab.popperClass].filter(c => Boolean(c))"
          :title="tab.title"
          :items="tab.items"
          :active-tab-id="activeTabId"
          @active-tab="setActiveTab"
        />
        <router-link
          v-else-if="tab.url || tab.to"
          :key="tab.id"
          class="tabs__tab"
          :class="[
            isActive(tab) ? 'tabs__tab_active' : '',
            !tab.exactMatch ? 'tabs__tab-nested-active' : '',
            `tabs__${tab.id}-tab`
          ]"
          :to="tab.to"
        >
          {{ tab.title }}
        </router-link>
        <span
          v-else
          :key="tab.id"
          class="tabs__tab"
          :class="{
            'tabs__tab_active': isActive(tab),
            [tab.className]: !!tab.className
          }"
          @click="setActiveTab(tab)"
        >{{ tab.title }}</span>
      </template>
    </div>
    <div
      v-if="showTabContent"
      class="tabs-content"
    >
      <slot :name="activeTabId" />
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import TabGroup from 'components/common/Tabs/TabGroup';
  import {isCurrentAppSeller} from 'utils/appTypeState';

  export default {
    name: 'Tabs',
    components: {TabGroup},
    props: {
      value: {
        type: String,
        default: ''
      },
      tabs: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...isCurrentAppSeller ? {} : mapGetters('sidebar', {storeIsSidebarClosed: 'isSidebarClosed'}),
      isSidebarClosed () {
        return this?.storeIsSidebarClosed || false;
      },
      activeTabId: {
        get () {
          return this.value;
        },
        set (tabId) {
          this.$emit('input', tabId);
        }
      },
      showTabContent () {
        return !_.isEmpty(this.$slots);
      }
    },
    methods: {
      handleOpenFromTab (tab, tabItem) {
        this.setActiveTab(tabItem);
      },
      setActiveTab (tab) {
        this.activeTabId = tab.id;
        this.$emit('activated-tab', tab);
      },
      isActive (tab) {
        return tab.id === this.activeTabId;
      }
    }
  };
</script>

<style scoped>
  .tabs {
    font-family: var(--main-font-family);
    font-size: 14px;

    color: var(--secondary-text-color);
    line-height: normal;

    padding-top: 2px;

    margin-bottom: 25px;

    border-bottom: 0.75px solid var(--border-color);
  }

  .tabs a {
    color: var(--secondary-text-color);
  }

  .tabs__tab {
    cursor: pointer;

    display: inline-block;

    padding: 9px 2px 10.5px;

    margin-right: 20px;
  }

  .tabs__tab_group {
    padding-bottom: 0;
  }

  .tabs__tab_group >>> .menu__title {
    padding-bottom: 10.5px;
  }

  .tabs_wide > .tabs__tab {
    margin-right: 32px;
  }

  .tabs > :first-child {
    padding-left: 0;
  }

  .tabs > :last-child {
    margin-right: 0;
  }

  .tabs__tab.tabs__tab-nested-active.router-link-active,
  .tabs__tab.router-link-exact-active,
  .tabs__tab.tabs__tab_active {
    color: var(--action-color);

    box-shadow: 0 1.5px 0 0 var(--action-color);

    border-bottom: 1.5px solid var(--action-color);
  }

  .tabs__tab_group >>> .popper__trigger_active {
    color: var(--action-color);
  }

  @media (max-width: 1439px) {
    .tabs__tab {
      margin-right: 18px;
    }

    .tabs_wide > .tabs__tab {
      margin-right: 24px;
    }
  }

  @media (max-width: 1339px) {
    .tabs__tab {
      margin-right: 13px;
    }

    .tabs_wide > .tabs__tab {
      margin-right: 19px;
    }
  }
</style>
