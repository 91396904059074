<template>
  <div class="m-table-campaign-status">
    <MMenu
      v-if="!isHasProblem"
      v-model="menuOpen"
      :disabled="isDisabled"
      :items="statusChoices"
      @item-click="onSelectNewStatus"
    >
      <template>
        <MTableCellIcon
          :icon="iconStatus"
          :disabled="isDisabled"
          :class="{
            'm-table-cell__project-status_active': isActive,
            'm-table-cell__project-status_paused': isPaused
          }"
          class="m-table-cell__project-status"
          :title="statusLabel"
        />
      </template>
    </MMenu>

    <MHint
      v-else
      placement="right"
      :close-only-hover-popper-outside="true"
      hint-class="m-table-cell-hover__disabled-hint"
      class="m-table-cell-hover__disabled-popover"
    >
      <MTableCellClickableWrapper
        :enabled="true"
        class="m-table-cell-hover__disabled-block"
      >
        <MTableCellIcon
          :icon="iconStatus"
          :class="{
            'm-table-cell__project-status_disabled': isHasProblem,
          }"
          class="m-table-cell__project-status m-table-cell__project-status_hover"
          :title="statusLabel"
        />
      </MTableCellClickableWrapper>
      <template v-slot:content>
        <span class="m-table-cell-campaign-status__hint-text">
          Что‑то не&nbsp;так… Проверьте статус кампании на&nbsp;маркетплейсе
          <br>
          и
          <m-link
            :to="{name: 'sellerShop'}"
            class="m-table-cell-campaign-status__hint-link"
          >
            доступ к&nbsp;магазину в Marilyn
          </m-link>
        </span>
      </template>
    </MHint>
  </div>
</template>

<script>
  import MTableCellIcon from 'legacy/modules/common/components/MTable/cells/MTableCellIcon';
  import iconActive from 'assets/icons/play.svg';
  import iconPaused from 'assets/icons/pause.svg';
  import iconWarning from 'assets/icons/warning.svg';
  import MMenu from 'modules/common/components/MMenu';
  import {getConnectionById} from 'utils/seller/sellerConnectionData';
  import ProjectStatusCellMenuItem from 'components/Tables/TableCells/MTableCellProjectStatus/ProjectStatusCellMenuItem';
  import {getFindSellerCampaignsStatusById, getSellerCampaignsStatus} from 'utils/seller/setllerCampaingsTableUtils';
  import MTableCellClickableWrapper from 'legacy/modules/common/components/MTable/cells/MTableCellClickableWrapper';

  export default {
    name: 'MTableCellCampaignStatus',
    components: {
      MMenu,
      MTableCellIcon,
      MTableCellClickableWrapper
    },
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        menuOpen: false,
        statusList: getSellerCampaignsStatus()
      };
    },
    computed: {
      currentChannelData () {
        return getConnectionById(this.tableRow.channel_id);
      },
      isDisabled () {
        if (this.currentChannelData.id === 'ozon') {
          const isManualOrTargetBidsOzonSkuTop =
            this.tableRow.placement_strategy_type === 'MANUAL' &&
            this.tableRow.placement_type === 'OZON_SKU_TOP';

          return isManualOrTargetBidsOzonSkuTop;
        }

        return false;

      },
      statusLabel () {
        const status = getFindSellerCampaignsStatusById(this.status);
        return status ? status.name : null;
      },
      status () {
        return this.tableRow[this.itemKey];
      },
      statusChoices () {
        if (this.statusList.length === 0) {
          return [];
        }

        const active = getFindSellerCampaignsStatusById('RUNNING');
        const paused = getFindSellerCampaignsStatusById('PAUSED');

        return [
          {
            component: ProjectStatusCellMenuItem,
            active: this.status === active.id,
            props: {
              value: active.id,
              name: active.name,
              icon: iconActive,
              class: {'project-status-menu-item_active': true}
            }
          },
          {
            component: ProjectStatusCellMenuItem,
            active: this.status === paused.id,
            props: {
              value: paused.id,
              name: paused.name,
              icon: iconPaused,
              class: {'project-status-menu-item_paused': true}
            }
          }
        ];
      },
      iconStatus () {
        if (this.isActive) {
          return iconActive;
        } else if (this.isPaused) {
          return iconPaused;
        }

        return iconWarning;
      },
      isActive () {
        return this.status === 'RUNNING';
      },
      isPaused () {
        return this.status === 'PAUSED';
      },
      isHasProblem () {
        return !this.isActive && !this.isPaused;
      }
    },
    methods: {
      onSelectNewStatus (status) {
        const value = status.props.value;
        this.$emit('changeStatus', {id: this.tableRow.id, status: value});
        this.menuOpen = false;
      }
    }
  };
</script>

<style>
  .m-table-campaign-status {
    --current-cell-width: 55px;

    .m-table-cell__project-status {
      --color-active: var(--success-color);
      --color-paused: var(--action-text-color);
      --color-disabled: var(--error-color);

      display: flex;

      justify-content: center;

      padding: 12px 12px 12px 0;
    }

    .m-table-cell-hover__disabled-block {
      --action-border-color: var(--gray-color);

      position: static;
      display: flex;

      width: 36px;

      padding: 0;

      margin: 8px 0 6px;

      border-radius: 4px;

      .m-table-cell__project-status_hover {
        width: max-content;
        height: 22px;

        padding: 0;
      }
    }

    .m-table-cell__project-status_active svg {
      color: var(--color-active);
    }

    .m-table-cell__project-status_paused svg {
      color: var(--color-paused);
    }

    .m-table-cell__project-status_disabled svg {
      color: var(--color-disabled);
    }

    .m-table-cell-hover__disabled-popover {
      display: flex;

      justify-content: center;
      align-items: center;

      width: 44px;
      height: 36px;

      .m-table-cell-hover__disabled-block {
        display: flex;

        justify-content: center;
        align-items: center;

        width: 32px;
        height: 22px;

        margin: 0;
      }
    }
  }

  div.m-table-cell-hover__disabled-hint {
    max-width: 224px;
    padding: 12px;
    line-height: 14px;
  }

  .m-table-cell-campaign-status__hint-text {
    display: inline-block;

    .m-table-cell-campaign-status__hint-link {
      display: inline;
      vertical-align: baseline;
    }
  }
</style>
