<template>
  <div class="tour-body__line">
    <template
      v-if="splitedText.length === 1"
    >
      {{ text }}
    </template>
    <template
      v-else
    >
      <template v-for="(item, index) in splitedText">
        <template>
          {{ item }}
          <m-icon
            v-if="index + 1 !== splitedText.length"
            :key="index"
            class="tour-body__icon"
            :icon="iconSprite"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'MTourBodyLine',
    props: {
      text: {
        type: String,
        default: ''
      },
      icon: {
        type: Object,
        default: Object
      }
    },
    computed: {
      iconTextNode () {
        return Object.keys(this.icon)[0];
      },
      iconSprite () {
        return this.icon[this.iconTextNode];
      },
      splitedText () {
        if (Object.keys(this.icon).length) {
          return this.text.split(`:${this.iconTextNode}:`);
        }
        return [this.text];
      }
    }
  };
</script>

<style scoped>
  .tour-body__line {
    font-size: 14px;

    line-height: 20px;

    display: flex;

    align-items: center;
  }

  .tour-body__icon {
    width: 12px;
    height: 12px;

    margin-left: 5px;
  }
</style>
