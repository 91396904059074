<template>
  <MModal
    ref="modal"
    :apply-disabled="!isValid || isLoading"
    @apply="applySettings"
    @cancel="cancelSettings"
  >
    <template slot="modal-header">
      {{ headerText }}
    </template>
    <template slot="modal-content">
      <div
        class="schedule-control-modal__content"
      >
        <div
          v-if="subtitleText"
          class="schedule-control-modal__subtitle"
        >
          {{ subtitleText }}
        </div>

        <ScheduleControlBasic
          v-if="basicMode"
          :day-value="dayValue"
          :time-value="basicTimeChoice"
          :custom-time-value="customTimeValue"
          @input="updateTime"
        />

        <ScheduleControlAdvanced
          v-if="advancedMode"
          :days="advancedDaysOfWeek"
          :time="advancedTimeOfDay"
          :day-title="advancedDayTitle"
          :hour-title="advancedHourTitle"
          @input="updateAdvancedTime"
        />

        <div
          v-if="!isOnlyAdvanced"
          class="schedule-control-modal__toggle-container"
        >
          <m-link
            v-if="visibleElemToggleAdvancedMode"
            class="schedule-control-modal__toggle-mode_advanced"
            :icon="iconArrow"
            icon-position="after"
            @click="toggleAdvancedMode"
          >
            {{ _('Advanced settings') }}
          </m-link>

          <m-link
            v-if="advancedMode"
            class="schedule-control-modal__toggle-mode_basic"
            :icon="iconArrow"
            icon-position="before"
            @click="toggleStandardMode"
          >
            {{ _('Standard settings') }}
          </m-link>
        </div>
      </div>
    </template>
  </MModal>
</template>

<script>
  import MModal from 'modules/common/components/MModal/MModal';
  import ScheduleControlBasic from 'modules/common/components/SheduleControl/ScheduleControlBasic';
  import iconArrow from 'assets/icons/arrow-back.svg';
  import ScheduleControlAdvanced from 'modules/common/components/SheduleControl/ScheduleControlAdvanced';
  import {
    CUSTOM, ONCE_A_DAY,
    getDatesListById, getHoursListById,
    getRunDayChoices, getRunDayId,
    getRunHourId, getHourItemById
  } from 'modules/common/components/SheduleControl/utils';
  import translation from 'translation';

  export default {
    name: 'ScheduleControlModal',
    components: {ScheduleControlAdvanced, ScheduleControlBasic, MModal},
    props: {
      daysOfWeek: {
        type: Array,
        default: Array
      },
      headerText: {
        type: String,
        default: () => translation.tr('Schedule settings')
      },
      timeOfDay: {
        type: Array,
        default: Array
      },
      isOnlyAdvanced: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      advancedDayTitle: {
        type: String,
        default: () => translation.trc('bidmanager', 'Start days')
      },
      advancedHourTitle: {
        type: String,
        default: () => translation.trc('bidmanager', 'Start hours')
      },
      subtitleText: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        iconArrow,
        isBasic: true,
        basicDaysOfWeek: this.daysOfWeek.slice(),
        basicTimeOfDay: this.timeOfDay.slice(),
        basicTimeChoice: '',
        advancedDaysOfWeek: this.daysOfWeek.slice(),
        advancedTimeOfDay: this.timeOfDay.slice()
      };
    },
    computed: {
      visibleElemToggleAdvancedMode () {
        return this.basicMode && !this.currentHourChoiceItem?.hideToggleAdvancedMode;
      },
      currentHourChoiceItem () {
        return getHourItemById(this.timeValue, {dayValue: this.dayValue});
      },

      // form is invalid if using advanced mode and days or hours are empty
      isValid () {
        return this.basicMode || this.advancedMode && this.advancedDaysOfWeek.length > 0 &&
          this.advancedTimeOfDay.length > 0;
      },
      dayValue () {
        return getRunDayId(this.basicDaysOfWeek);
      },
      customTimeValue () {
        if (this.isCustomTime && this.basicTimeOfDay.length) {
          return this.basicTimeOfDay[0];
        }

        return 0;
      },
      isCustomTime () {
        return this.basicTimeChoice === CUSTOM;
      },
      timeValue () {
        return getRunHourId(this.basicTimeOfDay);
      },
      basicMode () {
        return !this.isOnlyAdvanced && this.isBasic;
      },
      advancedMode () {
        return !this.basicMode;
      }
    },
    watch: {
      currentHourChoiceItem: {
        handler () {
          if (!this.currentHourChoiceItem) {
            this.setDefaultValueTimeForBasic();
          }
        },
        deep: true
      }
    },
    mounted () {
      this.isBasic = Boolean(this.dayValue && this.timeValue);
      if (!this.isBasic) {

        // fill default values for basic mode
        this.basicDaysOfWeek = getRunDayChoices().map(i => i.valuesList)[0];
        this.setDefaultValueTimeForBasic();
      } else {

        // get current choice for interval choice
        this.basicTimeChoice = getRunHourId(this.basicTimeOfDay);
      }
    },
    methods: {
      setDefaultValueTimeForBasic () {
        this.basicTimeOfDay = [this.$store.getters['projects/currentProjectId'] % 24];
        this.basicTimeChoice = ONCE_A_DAY;
      },
      toggleStandardMode () {
        this.isBasic = true;
      },
      toggleAdvancedMode () {
        this.isBasic = false;
        this.advancedTimeOfDay = this.basicTimeOfDay.slice();
        this.advancedDaysOfWeek = this.basicDaysOfWeek.slice();
      },
      updateTime (data) {
        this.basicDaysOfWeek = getDatesListById(data.date);
        this.basicTimeChoice = data.time;

        if (data.time === CUSTOM) {
          this.basicTimeOfDay = [data.customTime];
        } else {
          this.basicTimeOfDay = getHoursListById(data.time);
        }
      },

      updateAdvancedTime (payload) {
        this.advancedTimeOfDay = payload.time;
        this.advancedDaysOfWeek = payload.days;
      },

      removeModal () {
        return this.$refs.modal.removeModal();
      },
      applySettings () {

        // using slice to return new object to prevent recursive render
        if (this.basicMode) {
          return this.$emit('input', {
            daysOfWeek: this.basicDaysOfWeek.slice(),
            timeOfDay: this.basicTimeOfDay.slice()
          });
        }
        return this.$emit('input', {
          daysOfWeek: this.advancedDaysOfWeek.slice(),
          timeOfDay: this.advancedTimeOfDay.slice()
        });
      },
      cancelSettings () {
        this.$emit('cancel');
      }
    }
  };
</script>

<style>
  .schedule-control-modal__content {
    display: flex;

    flex-direction: column;

    box-sizing: border-box;
    width: 561px;
    min-height: 292px;

    padding: 18px;
  }

  .schedule-control-modal__toggle-container {
    font-size: 14px;
    font-weight: var(--medium-font-weight);

    margin-top: auto;
  }

  .schedule-control-modal__toggle-mode_advanced svg {
    transform: rotate(180deg);
  }

  .schedule-control-modal__subtitle {
    font-size: 14px;
    font-weight: 400;

    color: #696969;
    line-height: 20px;

    letter-spacing: -0.03em;

    margin-bottom: 20px;
  }
</style>
