<template>
  <div
    class="channel-select-item"
    :class="{
      'channel-select-item_selectable': isSelectable,
      'channel-select-item_dimmed': isDimmed
    }"
    @click="handleClick"
  >
    <div class="channel-select-item__icon">
      <MProviderIcon :icon="channelIcon" />
    </div>
    <div class="channel-select-item__name">
      {{ channelName }}
    </div>
    <MHelp
      v-if="channel.hint"
      show-on="hover"
      width="250px"
    >
      {{ channel.hint }}
    </MHelp>
  </div>
</template>

<script>
  import {MProviderIcon} from '@marilyn/marilyn-ui';
  import MHelp from 'modules/common/components/MHelp';

  export default {
    name: 'ChannelSelectItem',
    components: {MHelp, MProviderIcon},
    props: {
      channel: {
        type: Object,
        default: Object
      },
      selected: {
        type: Boolean,
        default: false
      },
      dimmed: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      channelIcon () {
        return this.channel.icon;
      },
      channelName () {
        return this.channel.display_name;
      },
      isSelectable () {
        return !this.disabled;
      },
      isDisabled () {
        return this.disabled && !this.selected;
      },
      isDimmed () {
        return this.isDisabled || this.dimmed;
      }
    },
    methods: {
      handleClick () {
        if (!this.isDisabled) {
          this.$emit('channelSelect');
        }
      }
    }
  };
</script>

<style>
  .channel-select-item {
    position: relative;
    display: inline-flex;

    justify-content: center;

    box-sizing: border-box;
    min-width: 150px;

    padding: 14px 20px;

    box-shadow: 0 2px 8px rgba(155, 158, 173, 0.25);

    border-radius: 30px;
    user-select: none;
  }

  .channel-select-item_selectable {
    cursor: pointer;
  }

  .channel-select-item_selectable:hover {
    box-shadow: 0 2px 8px rgba(58, 63, 82, 0.25);
    filter: opacity(1);
  }

  .channel-select-item_dimmed {
    filter: opacity(0.5);
  }

  .channel-select-item__icon {
    display: inline-flex;

    align-items: center;

    width: 16px;
    height: 16px;
  }

  .channel-select-item__icon svg {
    width: 16px;
  }

  .channel-select-item__name {
    font-size: 14px;
    margin-left: 6px;
  }

  .channel-select-item .m-help {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  .channel-select-item .m-help svg {
    color: var(--action-text-color);
  }

</style>
