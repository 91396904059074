<template>
  <div class="m-chart-container">
    <div
      v-if="showTitles"
      class="m-chart-title m-chart-title_left"
      :style="{height}"
    >
      <div
        v-overflow-title="titles[0].replace('________', '')"
        class="m-chart-title__text"
      >
        {{ titles[0] }}
      </div>
    </div>
    <div
      ref="chart"
      class="m-chart"
    />
    <div
      v-if="showTitles"
      class="m-chart-title m-chart-title_right"
      :style="{height}"
    >
      <div
        v-overflow-title="titles[1].replace('_  _  _  _  _', '')"
        class="m-chart-title__text"
      >
        {{ titles[1] }}
      </div>
    </div>
  </div>
</template>

<script>
  import ApexCharts from 'apexcharts';
  import moment from 'moment';
  import defaultsDeep from 'lodash.defaultsdeep';
  import AppEventBus from 'utils/appEventBus';
  import translation from 'translation';
  import _ from 'lodash';

  _.noConflict();

  // value of chart, before it was actually initialized
  const defaultChart = {
    destroy () {
      // do nothing
    }
  };

  function formatDate (date) {
    return moment(date).format(translation.dateFormatter.momentDateFormat());
  }

  const X_AXIS_TYPE_FORMATTER_MAP = {
    datetime: formatDate
  };

  export default {
    name: 'MChart',
    props: {
      titles: {
        type: Array,
        default: Array
      },
      type: {
        type: String,
        default: 'line'
      },
      series: {
        type: Array,
        required: true
      },
      width: {
        type: [String, Number],
        default: '100%'
      },
      height: {
        type: [String, Number],
        default: 'auto'
      },
      options: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        chart: defaultChart
      };
    },
    computed: {
      showTitles () {
        return this.titles.length === 2;
      },
      filteredSeries () {
        function process (items) {
          return items.map(item => {
            if (Array.isArray(item)) {
              return process(item);
            }
            if (typeof item === 'object') {
              return item;
            }

            // filter null values from array
            if (isNaN(item)) {
              return 0;
            }
            return item;
          });
        }

        return process(this.series);
      }
    },
    watch: {
      series () {
        this.updateSeries();
      },
      options () {
        this.updateOptions();
      }
    },
    mounted () {
      this.initChart();
      AppEventBus.$on('PAGE_RESIZE', this.forceUpdateChart);
      this.$on('hook:destroyed', () => {
        AppEventBus.$off('PAGE_RESIZE', this.forceUpdateChart);
      });
    },
    beforeDestroy () {
      this.destroyChart();
    },
    methods: {
      initChart () {
        const config = this.buildChartConfig();
        this.chart = new ApexCharts(this.$refs.chart, config);
        return this.chart.render();
      },
      destroyChart () {
        this.chart.destroy();
      },
      forceUpdateChart () {
        this.chart.update();
      },
      setXAxisDefaultFormatter (config) {
        const axisType = config.xaxis.type;
        const defaultFormatter = X_AXIS_TYPE_FORMATTER_MAP[axisType];
        if (!defaultFormatter) {
          return config;
        }
        return defaultsDeep(config, {xaxis: {labels: {formatter: defaultFormatter}}});
      },
      buildChartOptions () {
        const defaultOptions = {
          chart: {
            animations: {
              enabled: false
            },
            type: this.type,
            height: this.height,
            width: this.width,
            events: {...this.$listeners},
            fontFamily: '\'Roboto\', sans-serif'
          }
        };

        let config = defaultsDeep(
          {},
          _.cloneDeep(this.options),
          defaultOptions
        );

        if (config.xaxis && config.xaxis.type){
          this.setXAxisDefaultFormatter(config);
        }
        return config;
      },
      buildChartConfig () {
        const config = this.buildChartOptions();
        config.series = _.cloneDeep(this.filteredSeries);
        return config;
      },
      updateSeries () {
        this.chart.updateSeries(_.cloneDeep(this.filteredSeries));
      },
      updateOptions () {
        this.chart.updateOptions(_.cloneDeep(this.options));
      }
    }
  };
</script>

<style>
  .m-chart-container {
    display: flex;
  }

  .m-chart {
    flex-grow: 1;
  }

  .m-chart-title {
    margin-bottom: 46px;
  }

  .m-chart-title__text {
    font-size: 12px;

    color: var(--gray-text-color);
    text-overflow: ellipsis;

    text-align: center;

    overflow: hidden;

    max-width: 28px;
    height: 100%;

    transform: rotateZ(180deg);

    text-orientation: mixed;
    writing-mode: vertical-rl;
  }

  .m-chart-title_left {
    margin-left: 4px;
    margin-right: 2px;
  }

  .m-chart-title_right {
    margin-right: 4px;
  }

  .apexcharts-legend-series {
    display: inline-flex;
    align-items: center;
  }
</style>
