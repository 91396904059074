<template>
  <div class="shop-clients-cases">
    <div class="shop-clients-cases__container">
      <div class="shop-clients-cases__left-content">
        <h3 class="shop-clients-cases__title">
          Кейсы наших клиентов
        </h3>

        <span class="shop-clients-cases__description">
          Делимся тем, как технологии Marilyn делают рекламу на маркетплейсах эффективнее
        </span>

        <a
          :href="allCasesUrl"
          target="_blank"
          class="shop-clients-cases__button"
        >
          <MButton
            secondary
          >
            Читать все
          </MButton>
        </a>
      </div>

      <div class="shop-clients-cases__items-case">
        <ClientCaseItem
          v-for="item in items"
          :key="item.id"
          :title="item.title"
          :description="item.description"
          :icon="item.icon"
          :link="item.link"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ClientCaseItem from 'components/seller/shop/ClientCaseItem';

  const ALL_CASES_URL = 'https://mymarilyn.ru/cases/';

  export default {
    name: 'SellerClientsCases',
    components: {
      ClientCaseItem
    },
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        allCasesUrl: ALL_CASES_URL
      };
    }
  };
</script>

<style>
  .shop-clients-cases__container {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }

  .shop-clients-cases__left-content {
    display: inline-flex;
    flex-direction: column;
  }

  .shop-clients-cases__title {
    font-size: 20px;
    font-weight: 500;

    color: #000000;
    line-height: 24px;

    letter-spacing: -0.03em;

    margin: 0 0 16px;
  }

  .shop-clients-cases__description {
    font-size: 14px;
    font-weight: 400;

    color: #696969;
    line-height: 16px;

    margin: 0 0 32px;
  }

  .shop-clients-cases__button {
    align-self: flex-start;
  }

  .shop-clients-cases__items-case {
    display: flex;

    flex-direction: column;
    flex-grow: 1;

    margin-left: 32px;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
</style>
