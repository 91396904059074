<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    class="bar-widget"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="argumentsValues.name"
        :metrics="argumentsValues.metrics"
        :profiles="profiles"
        :external-href="externalHref"
        @click="$emit('cancel')"
      />
    </template>
    <template>
      <div
        v-if="isBarWidgetChartVisible"
        ref="plotContainer"
        class="bar-widget__plot"
      >
        <BarWidgetChart
          :statistics="barWidgetStatistics"
          :metric="targetMetric"
          :metric-name="metrics[targetMetric].name"
          :grouping="dsParams.grouping"
          :metric-type="metricType"
          :color-getter="colorGetter"
          height="240px"
        />
      </div>
      <CardNoDataText
        v-else
      />
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import BarWidgetChart from 'components/Dashboards/Widgets/BarWidget/BarWidgetChart';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';

  export default {
    name: 'BarWidget',
    components: {
      WidgetHeader,
      CardNoDataText,
      BarWidgetChart,
      DashboardCard
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      metrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: null
      },
      colorGetter: {
        type: Object,
        required: true
      }
    },
    computed: {
      targetMetric () {
        return this.argumentsValues.metrics[0];
      },
      metricType () {
        return this.metrics[this.targetMetric].type;
      },
      isBarWidgetChartVisible () {
        return this.data.items.length > 0 || this.data.items_total > 0;
      },
      barWidgetStatistics () {
        if (this.data.items.length > 0) {
          return this.data.items;
        } else if (this.data.items_total > 0) {
          return [this.data.total];
        }

        return [];
      }
    }
  };
</script>

<style>
  .bar-widget__plot {
    height: 100%;
    padding: 0 15px;
  }
</style>
