<template>
  <div>
    <OnboardingTextSection>
      Для удобства группировку обычно отражают в<br> <b>названии проекта</b>.
    </OnboardingTextSection>

    <OnboardingTextSection>
      <div class="grouping-campaign-step__note-wrapper">
        <span class="grouping-campaign-step__subtitle">Товарные категории.</span>
        Например, корм для кошек,<br> корм для собак, стиральные порошки.
      </div>
      <div class="grouping-campaign-step__note-wrapper">
        <span class="grouping-campaign-step__subtitle">Рекламные активности.</span>
        Например, лендинг,<br> социальные сети, медиа.
      </div>
      <div class="grouping-campaign-step__note-wrapper">
        <span class="grouping-campaign-step__subtitle">География.</span>
        Продукт Москва, Продукт СПб, Продукт<br> Регионы.
      </div>
    </OnboardingTextSection>

    <OnboardingTextSection>
      <b>Лучше не делать отдельные проекты для<br>
        отдельных рекламных площадок</b>, например, только<br>
      для Яндекс.Директ или Google.Ads, иначе вы не<br>
      сможете анализировать статистику в комплексе.
    </OnboardingTextSection>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import marySpeaking from 'assets/mascot/mary-speaking.png';

  export default {
    name: 'GroupingCampaignsStep',
    components: {OnboardingTextSection},
    data () {
      return {
        marySpeaking
      };
    }
  };
</script>

<style>
  .grouping-campaign-step__note-wrapper {
    margin-top: 5px;
  }

  .grouping-campaign-step__subtitle {
    font-weight: var(--bold-font-weight);
    color: var(--action-text-color);
  }
</style>
