<template>
  <div>
    <OnboardingTextSection>
      Итак, вы — рекламодатель или агентство, и у вас<br>
      есть рекламные кампании на площадках. Также у<br>
      вас есть Бренд(ы) или Клиент(ы), кампании которых<br>
      вы продвигаете.
    </OnboardingTextSection>

    <OnboardingTextSection>
      В Marilyn для решения ваших задач есть:
    </OnboardingTextSection>

    <OnboardingTextSection>
      <MIcon
        :icon="iconDot"
        class="onboarding-account-sidebar-tree__icon-dot"
      />
      <b>Проект</b> — контейнер для ваших рекламных<br>
      кампаний с инструментами управления и<br>
      автоматизации.
    </OnboardingTextSection>

    <OnboardingTextSection>
      <MIcon
        :icon="iconArrow"
      />
      <b>Аккаунт</b> — контейнер <b>над проектом</b>,<br>
      обеспечивающий нужную вам структуру.
    </OnboardingTextSection>

    <OnboardingImageSection
      width="138px"
      :image="ezImage"
      align="center"
      class="onboarding-account-sidebar-tree__mascot"
    />
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import {MIcon} from '@marilyn/marilyn-ui';
  import iconDot from 'assets/icons/bullet.svg';
  import iconArrow from 'assets/icons/bullet-arrow.svg';
  import ezImage from 'assets/mascot/mary-it-is-easy.png';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';

  export default {
    name: 'AccountSidebarTree',
    components: {OnboardingImageSection, OnboardingTextSection, MIcon},
    data () {
      return {
        iconDot,
        iconArrow,
        ezImage
      };
    }
  };
</script>

<style>
  .onboarding-account-sidebar-tree__icon-dot.m-icon {
    width: 5px;
    height: 5px;

    padding: 6px;
  }

  .onboarding-account-sidebar-tree__mascot {
    padding-top: 26px;
  }
</style>
