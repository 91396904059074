<template>
  <div
    v-if="metricSeries && metricSeries.length"
    class="kpi-widget-chart"
  >
    <div class="kpi-widget-chart__plot">
      <MChart
        ref="chart"
        :series="metricSeries"
        :options="chartOptions"
      />
    </div>
    <KpiWidgetLegend
      :period="period"
      :legend="legend"
      :colors="colors"
      :selected-index="selectedDataPointIndex"
      class="kpi-widget-chart__legend"
      :class="{
        'kpi-widget-chart-legend__expanded': isExtendedChart,
        'kpi-widget-chart-legend__collapsed': !isExtendedChart
      }"
      @selectIndex="selectedDataPointIndex = $event"
    />
  </div>
</template>

<script>
  import MChart from 'legacy/modules/common/components/MChart/MChart';
  import moment from 'moment-wrapper';
  import translation from 'translation';
  import KpiWidgetLegend from 'components/Dashboards/Widgets/KpiWidget/KpiWidgetLegend';
  import widgetColors from 'utils/design/chartColors';

  export default {
    name: 'KpiWidgetChart',
    components: {
      KpiWidgetLegend,
      MChart
    },
    props: {
      statistics: {
        type: Array,
        default: Array
      },
      metric: {
        type: String,
        default: ''
      },
      grouping: {
        type: String,
        default: ''
      },
      height: {
        type: String,
        default: '100%'
      },
      period: {
        type: Object,
        default: Object
      },
      planFactMetrics: {
        type: Array,
        default: Array
      }
    },
    data: () => ({
      selectedDataPointIndex: null
    }),
    computed: {
      colors () {
        return widgetColors();
      },
      metricSeries () {
        const periodInMinutes = this.period.totalDays * 24;
        const periodPassedMinutes = moment().diff(this.period.dateStart, 'hours');
        let progress = 0;
        if (periodPassedMinutes > 0) {
          progress = periodPassedMinutes / periodInMinutes * 100;
        }
        progress = Math.ceil(progress);

        let metricData = this.planFactMetrics.map(el => {
          if (el.plan && !isNaN(el.plan)) {
            let percent = Number((Number(this.statistics[0][el.metric]) / Number(el.plan) * 100).toFixed(1));

            if (percent > 0 && percent < 1) {
              return 1;
            }

            return percent;
          }

          return 0;
        });

        return [progress, ...metricData].map(el => el > 100 ? 100 : el);
      },
      legend () {
        return this.planFactMetrics.map(el => {
          let fact = this.statistics[0][el.metric];
          let percent = 0;

          if (el.plan && !isNaN(el.plan)) {
            percent = (Number(fact) / Number(el.plan) * 100).toFixed(1);
          }

          let metricName = el.metric === 'cost_fact' ? translation.tr('Budget') : el.metricName;

          return {
            metricName,
            metricType: el.metricType,
            plan: el.plan,
            percent,
            fact
          };
        });
      },
      labels () {
        return [
          translation.tr('Period'), ...this.legend.map(el => {
            if (el.metricName.length > 10) {
              return `${el.metricName.slice(0, 8)}...`;
            }
            return el.metricName;
          })
        ];
      },
      totalColor () {
        if (this.selectedDataPointIndex === null) {
          return '#4a4a4a';
        }
        return this.colors[this.selectedDataPointIndex];
      },
      totalLabel () {
        if (this.selectedDataPointIndex !== null) {
          return this.labels[this.selectedDataPointIndex];
        }
        if (this.period.totalDays > 1) {
          return translation.tr('Day');
        }
        return '';
      },
      totalVal () {
        if (this.selectedDataPointIndex !== null) {
          return `${this.metricSeries[this.selectedDataPointIndex]}%`;
        }
        if (this.period.totalDays > 1) {
          let days = 0;
          if (moment(this.period.dateEnd).
            isBefore(moment())) {
            days = this.period.totalDays;
          } else {
            days = Math.abs(moment(this.period.dateStart).
              diff(moment(), 'days')) + 1;
          }
          return `${days}/${this.period.totalDays}`;
        } else if (this.period.totalDays === 1) {
          return moment(this.period.dateStart).
            formatDate();
        }
        return '';
      },
      isExtendedChart () {
        return this.planFactMetrics.length && this.planFactMetrics.length > 2;
      },
      chartOptions () {
        let padding = {
          top: -18,
          right: -18,
          bottom: -18,
          left: -18
        };
        if (this.isExtendedChart) {
          padding = {
            top: -5,
            right: -5,
            bottom: -5,
            left: -5
          };
        }

        return {
          grid: {
            padding
          },
          colors: this.colors,
          chart: {
            type: 'radialBar',
            events: {
              dataPointSelection: (event, chartContext, config) => {

                // $nextFrame fixes bug https://github.com/apexcharts/apexcharts.js/issues/1813
                this.$nextFrame(() => {
                  const idx = config.dataPointIndex;
                  if (idx === this.selectedDataPointIndex) {
                    this.selectedDataPointIndex = null;
                    return;
                  }
                  this.selectedDataPointIndex = idx;
                });
              }
            }
          },
          labels: this.labels,
          stroke: {
            lineCap: 'round'
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: this.isExtendedChart ? '45%' : '55%'
              },
              track: {
                background: '#eaeaea'
              },
              dataLabels: {
                name: {
                  offsetY: this.period.totalDays > 1 ? -5 : -8
                },
                value: {
                  show: true,
                  fontSize: this.isExtendedChart ? '16px' : '14px',
                  fontWeight: 600,
                  offsetY: this.period.totalDays > 1 ? 0 : -6
                },
                total: {
                  show: true,
                  label: this.totalLabel,
                  fontSize: this.isExtendedChart ? '16px' : '14px',
                  color: this.totalColor,
                  formatter: () => this.totalVal
                }
              }
            }
          }
        };
      }
    }
  };
</script>

<style>
  .kpi-widget-chart {
    display: flex;

    align-items: center;

    width: 100%;
  }

  .kpi-widget-chart__plot {
    display: flex;

    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;

    max-width: 314px;
    height: 222px;
  }

  .kpi-widget-chart-legend__expanded {
    flex-shrink: 1;
    flex-basis: 50%;

    min-width: 372px;
  }

  .kpi-widget-chart-legend__collapsed {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .kpi-widget-chart__legend {
    overflow: hidden;
    flex-grow: 1;
  }
</style>
