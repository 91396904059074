<template>
  <div
    :class="{'m-radiobutton__group_disabled': disabled}"
    class="m-radiobutton__group"
  >
    <div
      v-if="groupLabel"
      class="m-radiobutton-group__label"
    >
      {{ groupLabel }}
    </div>
    <div class="m-radiobutton-group__control">
      <div
        v-for="item in choices"
        :key="item[idAttr]"

        class="m-radiobutton-group__item"
      >
        <MRadiobutton
          :key="item[idAttr]"
          :value="item[idAttr] === value"
          :disabled="disabled || disabledItemIds.includes(item[idAttr])"
          :label-text="item.name"
          @selected="selectValue(item)"
        />
        <div
          v-if="withDescription"
          class="m-radiobutton-group__item-description"
        >
          {{ item[descriptionKey] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MRadiobutton from 'legacy/modules/common/components/MRadiobutton';

  export default {
    name: 'MRadiobuttonGroup',
    components: {MRadiobutton},
    props: {
      idAttr: {
        type: String,
        default: 'id'
      },
      choices: {
        type: Array,
        required: true
      },
      value: {
        type: String,
        required: true
      },
      disabledItemIds: {
        type: Array,
        default: () => []
      },
      groupLabel: {
        type: String,
        default: null
      },
      disabled: Boolean,
      withDescription: Boolean,
      descriptionKey: {
        type: String,
        default: 'description'
      }
    },
    methods: {
      selectValue (item) {
        this.$emit('input', item[this.idAttr]);
      }
    }
  };
</script>

<style>
  .m-radiobutton__group {
    display: flex;
    align-items: center;
  }

  .m-radiobutton-group__label {
    font-weight: var(--medium-font-weight);
    margin-right: 16px;
  }

  .m-radiobutton-group__control {
    display: flex;
  }

  .m-radiobutton__group_disabled .m-radiobutton-group__label {
    color: var(--gray-text-color);
  }

  .m-radiobutton-group__item {
    margin-right: 44px;
  }

  .m-radiobutton-group__item-description {
    font-size: 12px;

    color: var(--gray-text-color);

    margin-top: 4px;
    margin-left: 23px;
  }
</style>
