<template>
  <div
    v-if="isExpanded"
    class="kpi-widget-expanded-legend kpi-widget-legend"
  >
    <table class="kpi-widget-legend-table">
      <tr
        :class="{'kpi-widget-legend__item_active': selectedIndex === 0}"
        @click="selectIndex(0)"
      >
        <td class="kpi-widget-table__metric-name">
          <div
            class="kpi-widget__metric-marker"
            :style="{background: colors[0]}"
          />
          <div class="kpi-widget__metric-name">
            {{ _("Period") }}
          </div>
        </td>
        <td class="kpi-widget-table__metric-val">
          {{ formattedPeriod.dateStart }}
        </td>
        <td class="kpi-widget-table__metric-val">
          {{ formattedPeriod.dateEnd }}
        </td>
        <td />
      </tr>
      <tr>
        <td class="kpi-widget-table__header" />
        <td class="kpi-widget-table__header">
          {{ _("Plan") }}
        </td>
        <td class="kpi-widget-table__header">
          {{ _("Fact") }}
        </td>
        <td class="kpi-widget-table__header">
          {{ _("Dynamic") }}
        </td>
      </tr>
      <tr
        v-for="(item, idx) in legend"
        :key="idx"
        :class="{'kpi-widget-legend__item_active': selectedIndex === idx + 1}"
        @click="selectIndex(idx + 1)"
      >
        <td class="kpi-widget-table__metric-name">
          <div
            class="kpi-widget__metric-marker"
            :style="{background: colors[idx + 1]}"
          />
          <div
            v-overflow-title="item.metricName"
            class="kpi-widget__metric-name"
          >
            {{ item.metricName }}
          </div>
        </td>
        <td class="kpi-widget-table__metric-val">
          <MNumeric
            :value="item.plan"
            :type="item.metricType"
          />
        </td>
        <td class="kpi-widget-table__metric-val">
          <MNumeric
            :value="item.fact"
            :type="item.metricType"
          />
        </td>
        <td class="kpi-widget-table__metric-progress">
          <MNumeric
            :value="item.percent"
            type="percent"
          />
        </td>
      </tr>
    </table>
  </div>
  <div
    v-else
    class="kpi-widget-collapsed-legend kpi-widget-legend"
  >
    <div
      class="kpi-widget-collapsed-legend__item"
      :class="{'kpi-widget-legend__item_active': selectedIndex === 0}"
      @click="selectIndex(0)"
    >
      <div class="kpi-widget-legend-item__header">
        <div
          class="kpi-widget__metric-marker"
          :style="{background: colors[0]}"
        />
        <div class="kpi-widget-legend-header__metric-name">
          {{ _("Period") }}
        </div>
        <div class="kpi-widget-legend-header__metric-progress" />
      </div>
      <div class="kpi-widget-legend-item__stat-row">
        <div class="kpi-widget-legend-stat-row__name">
          {{ pgettext("KPI_WIDGET", "Start") }}
        </div>
        <div class="kpi-widget-legend-stat-row__value">
          {{ formattedPeriod.dateStart }}
        </div>
      </div>
      <div class="kpi-widget-legend-item__stat-row">
        <div class="kpi-widget-legend-stat-row__name">
          {{ pgettext("KPI_WIDGET", "End") }}
        </div>
        <div class="kpi-widget-legend-stat-row__value">
          {{ formattedPeriod.dateEnd }}
        </div>
      </div>
    </div>
    <div
      v-for="(item, idx) in legend"
      :key="idx"
      class="kpi-widget-collapsed-legend__item"
      :class="{'kpi-widget-legend__item_active': selectedIndex === idx + 1}"
      @click="selectIndex(idx + 1)"
    >
      <div class="kpi-widget-legend-item__header">
        <div
          class="kpi-widget__metric-marker"
          :style="{background: colors[idx+1]}"
        />
        <div
          v-overflow-title="item.metricName"
          class="kpi-widget-legend-header__metric-name"
        >
          {{ item.metricName }}
        </div>
        <div class="kpi-widget-legend-header__metric-progress">
          <MNumeric
            :value="item.percent"
            type="percent"
          />
        </div>
      </div>
      <div class="kpi-widget-legend-item__stat-row">
        <div class="kpi-widget-legend-stat-row__name">
          {{ _("Plan") }}
        </div>
        <div class="kpi-widget-legend-stat-row__value">
          <MNumeric
            :value="item.plan"
            :type="item.metricType"
          />
        </div>
      </div>
      <div class="kpi-widget-legend-item__stat-row">
        <div class="kpi-widget-legend-stat-row__name">
          {{ _("Fact") }}
        </div>
        <div class="kpi-widget-legend-stat-row__value">
          <MNumeric
            :value="item.fact"
            :type="item.metricType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-wrapper';
  import MNumeric from 'legacy/modules/common/components/MNumeric/MNumeric';

  export default {
    name: 'KpiWidgetLegend',
    components: {MNumeric},
    props: {
      period: {
        type: Object,
        required: true
      },
      legend: {
        type: Array,
        required: true
      },
      colors: {
        type: Array,
        required: true
      },
      selectedIndex: {
        type: Number,
        default: null
      }
    },
    computed: {
      isExpanded () {
        return this.legend && this.legend.length > 2;
      },
      formattedPeriod () {
        return {
          dateStart: moment(this.period.dateStart).formatDateShort(),
          dateEnd: moment(this.period.dateEnd).formatDateShort()
        };
      }
    },
    methods: {
      selectIndex (idx) {
        if (idx === this.selectedIndex) {
          return this.$emit('selectIndex', null);
        }
        this.$emit('selectIndex', idx);
      }
    }
  };
</script>

<style>
  .kpi-widget-collapsed-legend {
    margin-right: 10px;
  }

  @media (max-width: 1390px) {
    .kpi-widget-collapsed-legend__item {
      font-size: 12px;
      width: 110px;
    }
  }

  @media (min-width: 1390px) {
    .kpi-widget-collapsed-legend__item {
      font-size: 14px;
      width: 148px;
    }
  }

  .kpi-widget-collapsed-legend__item {
    color: var(--text-main-color);
  }

  .kpi-widget-collapsed-legend__item:not(:first-child) {
    margin-top: 20px;
  }

  .kpi-widget-legend-item__header {
    display: flex;

    align-items: center;

    width: 100%;
  }

  .kpi-widget-legend-header__metric-name {
    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    flex-grow: 1;

    margin-left: 6px;
  }

  .kpi-widget-legend-header__metric-progress {
    color: var(--action-hover-color);

    flex-grow: 0;
    flex-shrink: 0;

    margin-left: auto;
  }

  .kpi-widget__metric-marker {
    flex-grow: 0;
    flex-shrink: 0;

    width: 6px;
    height: 6px;

    border-radius: 50%;
  }

  .kpi-widget-legend-item__stat-row {
    display: flex;

    align-items: center;

    margin-top: 6px;
    margin-left: 12px;
  }

  .kpi-widget-legend-stat-row__value {
    color: var(--secondary-text-color);
    margin-left: auto;
  }

  .kpi-widget-legend-stat-row__name {
    margin-right: 3px;
  }

  .kpi-widget-expanded-legend {
    margin-right: 12px;
  }

  @media (max-width: 1500px) {
    .kpi-widget-legend-table {
      font-size: 12px;
    }
  }

  @media (min-width: 1500px) {
    .kpi-widget-legend-table {
      font-size: 14px;
    }
  }

  .kpi-widget-legend-table {
    color: var(--text-main-color);

    width: 100%;

    table-layout: fixed;
  }

  .kpi-widget-legend__item_active {
    font-weight: var(--medium-font-weight);
  }

  .kpi-widget-legend-table tr td {
    text-overflow: ellipsis;

    overflow: hidden;

    padding-right: 12px;
  }

  .kpi-widget-legend-table tr:not(:first-child) td:not(.kpi-widget-table__header) {
    padding-top: 8px;
  }

  .kpi-widget-table__metric-name {
    display: flex;

    align-items: center;

    max-width: 150px;
  }

  .kpi-widget-table__metric-name .kpi-widget__metric-name {
    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    margin-left: 6px;
  }

  .kpi-widget-table__metric-val,
  .kpi-widget-table__header,
  .kpi-widget-table__metric-progress {
    text-align: right;
    padding-left: 12px;
  }

  .kpi-widget-table__metric-val {
    color: var(--secondary-text-color);
  }

  .kpi-widget-table__header {
    font-weight: var(--medium-font-weight);

    padding-top: 12px;
    padding-bottom: 10px;
  }

  .kpi-widget-table__metric-progress {
    color: var(--action-hover-color);
  }

</style>
