<template>
  <Transition
    name="fade"
    :duration="{ enter: 500, leave: 500 }"
  >
    <div :class="['m-notification__layout', 'm-notification__layout-' + layout]">
      <TransitionGroup
        tag="div"
        name="m-notification__list"
        @afterLeave="onAfterLeave"
      >
        <MNotification
          v-for="notice in notificationList"
          :key="notice.id"
          :type="notice.type"
          :timeout="notice.timeout"
          :can-close-notification-before-click="notice.canCloseNotificationBeforeClick"
          :header="notice.header"
          :text="notice.text"
          :link-action="notice.linkAction"
          :on-close="notice.onClose"
          :link-url="notice.linkUrl"
          :link-text="notice.linkText"
          :close-by-button="notice.closeByButton"
          @closeNoty="() => removeNotification(notice)"
        />
      </TransitionGroup>
    </div>
  </Transition>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex';
  import MNotification from 'components/common/MNotification/MNotification';
  import {REMOVE_NOTIFICATION, REMOVE_QUEUE} from 'store/modules/notifications/notifications';

  export default {
    name: 'MNotificationsLayout',
    components: {MNotification},
    props: {
      container: {
        type: String,
        default: ''
      },
      layout: {
        type: String,
        default: 'bottomRight'
      }
    },
    computed: {
      ...mapGetters('notifications', ['queues']),
      notificationList () {
        return this.queues[this.container].queue;
      }
    },
    methods: {
      ...mapMutations('notifications', {
        removeNotification: REMOVE_NOTIFICATION,
        removeQueue: REMOVE_QUEUE
      }),
      onAfterLeave () {
        if (this.notificationList.length === 0) {
          this.removeQueue(this.container);
        }
      }
    }
  };
</script>

<style>
  .m-notification__layout {
    position: fixed;
    z-index: 9999999;

    max-width: 90%;

    padding: 0;

    margin: 0;

    transform: translateZ(0) scale(1, 1);

    pointer-events: auto;
  }

  .m-notification__layout-bar {
    overflow: hidden;
  }

  .m-notification__layout-top {
    top: 0;
    left: 5%;

    width: 90%;
  }

  .m-notification__layout-topLeft {
    top: 20px;
    left: 20px;

    width: 325px;
  }

  .m-notification__layout-topCenter {
    top: 5%;
    left: 50%;

    width: 325px;

    transform: translate(calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
  }

  .m-notification__layout-topRight {
    top: 20px;
    right: 20px;

    width: 325px;
  }

  .m-notification__layout-bottom {
    bottom: 0;
    left: 5%;

    width: 90%;
  }

  .m-notification__layout-bottomLeft {
    bottom: 20px;
    left: 20px;

    width: 325px;
  }

  .m-notification__layout-bottomCenter {
    bottom: 5%;
    left: 50%;

    width: 325px;

    transform: translate(calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
  }

  .m-notification__layout-bottomRight {
    right: 20px;
    bottom: 20px;

    width: 325px;
  }

  .m-notification__layout-center {
    top: 50%;
    left: 50%;

    width: 325px;

    transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
  }

  .m-notification__layout-centerLeft {
    top: 50%;
    left: 20px;

    width: 325px;

    transform: translate(0, calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
  }

  .m-notification__layout-centerRight {
    top: 50%;
    right: 20px;

    width: 325px;

    transform: translate(0, calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
  }

  .m-notification__list-enter-active {
    transition: transform 400ms ease;
  }

  .m-notification__list-leave-active {
    transition: opacity 400ms ease;
  }

  .m-notification__list-enter {
    transform: translateX(325px);
  }

  .m-notification__layout-centerLeft .m-notification__list-enter,
  .m-notification__layout-topLeft .m-notification__list-enter,
  .m-notification__layout-bottomLeft .m-notification__list-enter {
    transform: translateX(-325px);
  }

  .m-notification__layout-center .m-notification__list-enter,
  .m-notification__layout-topCenter .m-notification__list-enter,
  .m-notification__layout-bottomCenter .m-notification__list-enter {
    transform: translateX(0);
  }

  .m-notification__list-leave-to {
    opacity: 0;
  }

  .m-notification__list-move {
    transition: transform 500ms ease;
  }
</style>
