<template>
  <div class="breadcrumb">
    <template v-show="path.length">
      <BreadcrumbNode
        class="breadcrumb__node"
        :title="rootEl.name"
        :url="rootEl.url"
        :to="rootEl.to"
      >
        {{ rootEl.name }}
      </BreadcrumbNode>
      <m-icon
        class="breadcrumb__separator-icon"
        :icon="arrowRightIcon"
      />
    </template>
    <transition-group
      name="breadcrumb"
      class="breadcrumb__transition-group"
    >
      <BreadcrumbNode
        v-show="skipped.length > 0"
        key="skipped-items"
      >
        <MMenu
          v-model="menuOpen"
          :items="skipped"
          placement="bottom-start"
          offset="-10,5"
          @item-click="menuOpen = false"
        >
          <span class="breadcrumb__node breadcrumb__node_merged">
            ...
          </span>
        </MMenu>
      </BreadcrumbNode>
      <m-icon
        v-show="skipped.length > 0"
        key="skipped-items-icon"
        class="breadcrumb__separator-icon"
        :icon="arrowRightIcon"
      />
      <template v-for="(item, index) in groupedPath">
        <BreadcrumbNode
          :key="'bc-' + index"
          class="breadcrumb__node"
          :class="{'breadcrumb__node_last': isLast(groupedPath, index)}"
          :title="item.name"
          :url="item.url"
          :is-last="isLast(groupedPath, index)"
        >
          {{ item.formattedName }}
        </BreadcrumbNode>
        <m-icon
          v-if="!isLast(groupedPath, index)"
          :key="'bc-icon-' + index"
          class="breadcrumb__separator-icon"
          :icon="arrowRightIcon"
        />
      </template>
    </transition-group>
  </div>
</template>

<script>
  import * as Helpers from 'vue/helpers';
  import MMenu from '@old/modules/common/components/MMenu';

  import arrowRightIcon from 'assets/icons/arrow-right.svg';
  import MMenuLinkItem from 'modules/common/components/MMenuLinkItem';
  import BreadcrumbNode from 'layouts/features/Breadcrumbs/BreadcrumbNode';

  const MAX_SIZE = 512;
  const MAX_ITEM_NAME_LENGTH = 28;
  const ARROW_WIDTH = 4;
  const ARROW_MARGIN = 7;

  const calcBreadcrumbsLength = (path, rootEl) => {
    const str = path.map(item => item.formattedName).join('') + rootEl.name;
    const bcItems = path.length;

    const strPxLength = Helpers.stringPxLength(str);

    // breadcrumbs width in pixels = strWidthPx + (arrow width + left arrowMargin + right arrowMargin) * arrows count
    return strPxLength + (ARROW_WIDTH + ARROW_MARGIN * 2) * bcItems;
  };

  export default {
    name: 'Breadcrumbs',
    components: {MMenu, BreadcrumbNode},
    props: {
      path: {
        type: Array,
        default: Array
      },
      rootEl: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        skipped: [],
        groupedPath: [],
        menuOpen: false,
        arrowRightIcon
      };
    },
    watch: {
      path () {
        this.calcPathNodes();
      }
    },
    mounted () {
      this.calcPathNodes();
    },
    methods: {

      /**
       * Prepare account path for breadcrumbs according to guideline:
       * Limit account length to MAX_ITEM_NAME_LENGTH
       * Limit breadcrumbs length to MAX_SIZE (make group if total length is higher than MAX_SIZE)
       */
      calcPathNodes () {
        this.skipped = [];
        const newPath = this.path.map((item) => {
          if (item.name && item.name.length > MAX_ITEM_NAME_LENGTH && this.path[this.path.length - 1] !== item) {
            item.title = item.name;
            item.formattedName = `${item.name.substring(0, MAX_ITEM_NAME_LENGTH)}...`;
          } else {
            item.formattedName = item.name || '';
          }
          return item;
        });

        if (calcBreadcrumbsLength(newPath, this.rootEl) > MAX_SIZE) {
          const popCount = this.path.length;
          for (let i = 0; i < popCount - 2; i += 1) {
            this.skipped.push({id: i, component: MMenuLinkItem, props: newPath[0]});
            newPath.splice(0, 1);
          }
        }
        this.groupedPath = newPath;
      },
      isLast (list, index) {
        return list.length - 1 === index;
      }
    }
  };
</script>

<style scoped>
  .breadcrumb {
    /* hack to disable cursor:text on text entities */
    cursor: default;

    display: flex;

    justify-content: flex-start;
    align-items: center;

    padding: unset;

    margin: 32px 0 0;

    /* clear bootstrap styles */
    background: unset;
  }

  .breadcrumb__transition-group {
    display: flex;

    justify-content: flex-start;
    align-items: center;
  }

  .breadcrumb__node,
  .breadcrumb__node:hover,
  .breadcrumb__node:active,
  .breadcrumb__node:focus {
    color: var(--gray-text-color);
  }

  .breadcrumb__node_last,
  .breadcrumb__node_last:hover,
  .breadcrumb__node_last:active,
  .breadcrumb__node_last:focus {
    color: var(--action-color);
  }

  .breadcrumb__separator-icon {
    width: var(--tiny-icon-size);
    height: var(--tiny-icon-size);

    margin-left: var(--icon-margin);
    margin-right: var(--icon-margin);
  }

  .breadcrumb-enter-active,
  .breadcrumb-leave-active {
    transition: opacity var(--transition-duration);
  }

  .breadcrumb__node_merged {
    cursor: pointer;
  }

  .breadcrumb-enter,
  .breadcrumb-leave-to {
    opacity: 0;
  }

</style>
