<template>
  <div
    ref="modal-wrapper"
    :class="{
      'm-modal__wrapper-hidden': componentIsHidden
    }"
    class="m-modal m-modal__screen-tint"
  >
    <div
      ref="wrapper"
      class="m-modal__position-wrapper"
      :class="{
        'm-modal__position-wrapper_fullscreened': isFullscreened
      }"
      @click="handleOuter"
    >
      <div
        ref="container"
        class="m-modal__container"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import BodyScroll from 'utils/BodyScroll';

  export default {
    name: 'MModalWrapper',
    props: {
      closeByOutsideClick: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        componentIsHidden: true, // is modal hidden
        isFullscreened: false // modal is bigger than screen
      };
    },
    watch: {
      componentIsHidden () {
        if (!this.componentIsHidden) {
          this.$emit('opened');
        }
      }
    },
    mounted () {
      document.addEventListener('keyup', this.handleOuter);
      BodyScroll.disable();
      this.$nextTick(() => {
        this.componentIsHidden = false;
      });
    },
    destroyed () {
      document.removeEventListener('keyup', this.handleOuter);
    },
    methods: {
      hide () {
        this.componentIsHidden = true;
      },
      handleOuter (e) {
        if ((e.target === this.$refs.wrapper || e.key === 'Escape') && this.closeByOutsideClick) {
          this.removeModal().then(() => {
            this.$refs['modal-wrapper'].remove();
          });
        }
      },

      /**
       * Hide modal function
       * Returns promise that is resolved when animation is ended
       * @returns {Promise<any>}
       */
      removeModal () {
        return new Promise(resolve => {
          this.hide();
          BodyScroll.enable();

          // wait 300ms while animation is playing
          setTimeout(() => {
            resolve();
          }, 300);
        });
      }
    }
  };
</script>

<style>
  .m-modal {
    --background-tint-color: var(--modal-background-tint-color);
    --modal-background-color: var(--background-color);

    transition: opacity 200ms;
  }

  .m-modal__wrapper-hidden {
    opacity: 0;
  }

  .m-modal__screen-tint {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--popover-z-index);

    width: 100%;
    height: 100%;

    background-color: var(--background-tint-color);
  }

  .m-modal__position-wrapper {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }

  .m-modal__position-wrapper.m-modal__position-wrapper_fullscreened {
    overflow-y: auto;

    align-items: flex-start;

    padding-top: 8px;
    padding-bottom: 8px;
  }

  .m-modal__container {
    overflow: hidden;

    display: flex;

    flex-direction: column;

    box-sizing: border-box;

    background-color: var(--modal-background-color);
    box-shadow: 0 4px 15px rgba(168, 168, 168, 0.25);

    border-radius: 5px;
  }
</style>
