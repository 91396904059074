<template>
  <div class="card-no-data-text">
    <slot>
      <!--      Нет данных за указанный период-->
      {{ pgettext('dashboards', 'No data available for current period') }}
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'CardNoDataText'
  };
</script>

<style>
  .card-no-data-text {
    font-size: 14px;

    color: var(--panel-text-color);

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    margin-top: -20px;
  }
</style>
