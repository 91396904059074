<template>
  <component
    :is="rootTag"
    class="widget-header"
    :href="externalHref"
    @click="$emit('click')"
  >
    <div
      class="widget-header__names"
      :class="{'widget-header__multiple-names': names.length > 1}"
    >
      <div
        v-for="(n, i) in names"
        :key="`widget_header_${i}`"
        class="widget_header__item"
      >
        <div class="widget-header__name">
          {{ n }}
        </div>
        <GoalProfileName
          v-if="currentProviderIds[i] && showGoalProfileName"
          :provider-id="currentProviderIds[i]"
          :goal="currentGoals[i]"
          :profile="currentProfiles[i]"
          class="widget-header__wa"
        />
      </div>
    </div>
  </component>
</template>

<script>
  import GoalProfileName from 'legacy/modules/common/components/GoalProfileName';
  import parseMetric from 'utils/webanalytics/parseMetric';

  export default {
    name: 'WidgetHeader',
    components: {GoalProfileName},
    props: {
      name: {
        type: String,
        default: ''
      },
      metrics: {
        type: Array,
        default: Array
      },
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: undefined
      },
      showGoalProfileName: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      names () {
        if (this.currentProfiles.every(p => p && p.id === this.currentProfiles[0].id) ||
          this.currentGoals.every(p => p && p.id === this.currentGoals[0].id) ||
          this.isWaMetrics.every(val => val === false)
        ) {
          return [this.name];
        }

        return this.name.split('&');
      },
      rootTag () {
        return this.externalHref ? 'a' : 'div';
      },
      goals () {
        return this.profiles.map(p => p.children).flat();
      },
      parsedMetrics () {
        return this.metrics.map(m => parseMetric(m));
      },
      isWaMetrics () {
        return this.parsedMetrics.map(m => m.profileId || m.goalId).map(m => Boolean(m));
      },
      currentProviderIds () {
        let profiles = [];

        this.currentProfiles.forEach((p, i) => {
          if (this.isWaMetrics[i] === true) {
            if (!p) {
              const profileId = this.currentGoals[i] ? this.currentGoals[i].profile_id : null;
              let profile = this.profiles.find(p => p.id === profileId);
              profiles[i] = profile ? profile.provider_id : false;
            } else {
              profiles[i] = p.provider_id;
            }
          } else {
            profiles[i] = false;
          }
        });

        return profiles;
      },
      currentProfiles () {
        return this.parsedMetrics.map(m => this.profiles.find(p => p.id === m.profileId));
      },
      currentGoals () {
        return this.parsedMetrics.map(m => this.goals.find(g => g.id === m.goalId));
      }
    }
  };
</script>

<style>
  .widget-header {
    color: inherit;
  }

  .widget-header__wa {
    overflow: hidden;

    width: 100%;

    margin-left: 8px;
  }

  .widget-header__wa .labeled-icon__icon {
    margin-right: 0;
  }

  .widget-header__wa .labeled-icon__icon .m-icon {
    height: 12px;
  }

  .widget-header__wa .labeled-icon__container {
    font-size: 12px;
    font-weight: var(--main-font-weight);

    color: var(--secondary-text-color);
  }

  .widget_header__item {
    display: flex;
    align-items: flex-end;
  }

  .widget-header__multiple-names {
    flex-direction: column;
  }
</style>
