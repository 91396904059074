:root {
  --background-color: #FFFFFF;
  --background-gray-color: #F9F9F9;
  --panel-background-color: #F6F7F9;
  --gray-table-color: #F5F5F5;
  --gray-color: #EAEAEA;
  --border-color: #D4D4D4;
  --gray-text-color: #8D8D8D;
  --panel-text-color: #696969;
  --secondary-text-color: #4F4F4F;
  --shading-color: rgba(60, 64, 75, 0.7);
  --text-color: #242424;
  --text-main-color: #242424;
  --text-link-blue-hover-color: #165FA8;

  /* action colors */
  --action-active-color: rgba(1, 24, 51, 0.3);
  --action-hover-color: #165FA8;
  --action-color: #4786C6;
  --action-text-color: #5585C1;
  --action-disabled-color: #A4C5E7;
  --action-border-color: #CEE3F8;
  --action-secondary-color: #E0EFFF;
  --action-background-color: #EBF4FD;

  /* sidebar colors */
  --sidebar-active-children-hover-background-color: #D7EAFD;
  --sidebar-expand-control-hover-background-color: #DDDCDC;
  --sidebar-expand-control-active-hover-background-color: #3670AB;
  --sidebar-expand-control-active-parent-hover-background-color: #BFDDFC;

  /* success colors */
  --success-color: #4B924D;
  --success-hover-color: #3E7540;
  --success-disabled-color: #B7DFB8;
  --success-background-color: #F2FFF2;

  /* warning colors */
  --warning-color: #E3AE24;

  /* error colors */
  --error-color: #C6474F;
  --error-hover-color: #A92F36;
  --error-active-color: rgba(71, 26, 26, 0.25);
  --error-disabled-color: #EAADB0;
  --error-background-color: #FFF3F3;

  /* secondary colors */
  --secondary-button-active-color: rgba(143, 152, 184, 0.3);
  --secondary-button-hover-color: #D9DEF1;
  --secondary-button-color-hover: #D9DEF1;
  --secondary-button-color: #EEF0F9;
  --secondary-button-disabled-color: #F5F5F5;
  --secondary-button-disabled-text-color: #8D8D8D;

  /* TODO */
  --modal-background-tint-color: rgba(60, 64, 75, 0.7);

  /* todo: remove this colors */
  --main-color: #4786C6;
  --title-color: #101010;

  /* sticky header colors */
  --sticky-header-cell-shadow-color: rgba(126, 126, 126, 0.1);
  --sticky-cell-vertical-shadow-color: rgba(126, 126, 126, 0.06);

  /* skeleton loader colors */
  --skeleton-loader-background-color: #F4F4F7;

  /* floating card shadow color (dashboard cards, table container e.t.c) */
  --floating-card-shadow-color: rgba(45, 54, 65, 0.15);
  --floating-card-shadow: 0px 2px 8px var(--floating-card-shadow-color);
}
