<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    class="bar-widget"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="argumentsValues.name"
        :metrics="argumentsValues.metrics"
        :profiles="profiles"
        :external-href="externalHref"
      />
    </template>
    <template>
      <div
        v-if="data.items.length > 0"
        ref="plotContainer"
        class="bar-widget__plot"
      >
        <PeriodsComparisonWidgetChart
          :statistics="data.items"
          :metric="targetMetric"
          :grouping="dsParams.grouping"
          :metric-type="metricType"
          :comparison-periods="comparisonPeriods"
          height="240px"
        />
      </div>
      <CardNoDataText
        v-else
      />
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import PeriodsComparisonWidgetChart from 'components/Dashboards/Widgets/PeriodsComparisonWidget/PeriodsComparisonWidgetChart';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';

  export default {
    name: 'PeriodsComparisonWidget',
    components: {
      WidgetHeader,
      CardNoDataText,
      PeriodsComparisonWidgetChart,
      DashboardCard
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      metrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: null
      },
      comparisonPeriods: {
        type: Object,
        default: Object
      }
    },
    computed: {
      targetMetric () {
        return this.argumentsValues.metrics[0];
      },
      metricType () {
        return this.metrics[this.targetMetric].type;
      }
    }
  };
</script>

<style>
  .bar-widget__plot {
    height: 100%;
    padding: 0 15px;
  }
</style>
