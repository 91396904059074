
<template>
  <div class="m-error-wrapper">
    <div
      class="m-error-wrapper__control"
      :class="{'m-error-wrapper__active': showError }"
    >
      <slot ref="wrappedInput" />
    </div>
    <div
      v-if="showError"
      v-overflow-title="errorText"
      class="m-error_wrapper__error"
    >
      {{ errorText }}
    </div>
  </div>
</template>

<script>
  import MErrorWrapperMixin from 'legacy/modules/common/components/MErrorWrapper/MErrorWrapperMixin';

  export default {
    name: 'MErrorWrapper',
    mixins: [MErrorWrapperMixin],
    props: {
      doNotHideError: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        valueChanged: false
      };
    },
    computed: {
      errorText () {
        const text = this._errorGetter(this.errorPath);
        if (Array.isArray(text)) {
          return text.join(', ');
        }
        return text;
      },
      hasError () {
        return Boolean(this.errorText);
      },
      showError () {
        return this.hasError && (!this.valueChanged || this.frontendValidation);
      }
    },
    watch: {
      errorText () {
        this.valueChanged = false;
      }
    },
    updated () {
      const component = this.$slots.default[0];
      const componentInstance = component.componentInstance;

      // element may be regular html element, not a Vue component
      if (componentInstance && !this.doNotHideError) {
        componentInstance.$on('input', this.handleControlInput);
        componentInstance.$on('change', this.handleControlInput);
      }
    },
    methods: {
      handleControlInput () {
        if (this.hasError) {
          this.valueChanged = true;
        }
      }
    }
  };
</script>

<style scoped>
  .m-error-wrapper {
    --error-text-color: var(--error-color);
    display: flex;
    flex-direction: column;
  }

  .m-error_wrapper__error {
    color: var(--error-text-color);
    margin-top: 7px;
  }
</style>
