<template>
  <MTableCell>
    <div class="m-table-cell-gray-text">
      <span>{{ itemText }}</span>
    </div>
  </MTableCell>
</template>

<script>
  import {mTableCells} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTableCellGrayText',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    props: {
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      itemText () {
        return this.tableRow[this.itemKey];
      }
    }
  };
</script>

<style>
  .m-table-cell-gray-text {
    font-size: 12px;
    font-weight: 400;

    color: #A8A8A8;
    line-height: 14px;

    letter-spacing: -0.01em;
  }
</style>
