<template>
  <li
    :title="title"
    :class="{
      'tag-list__tag_active': selected && !disabled,
      'tag-list__tag_small': small,
      'tag-list__tag_gray': gray,
      'tag-list__tag_disabled': disabled
    }"
    class="tag-list__tag"
    @click="onClick"
  >
    <MProviderIcon
      v-if="Boolean(icon)"
      :icon="icon"
      class="tag-list__tag-icon"
    />
    {{ text }}
  </li>
</template>

<script>
  import {MProviderIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'TagListTag',

    components: {MProviderIcon},

    model: {
      prop: 'active',
      event: 'toggle'
    },

    props: {
      text: {
        type: String,
        default: ''
      },
      enableToggling: {
        type: Boolean,
        default: true
      },
      active: Boolean,
      disabled: Boolean,
      small: Boolean,
      gray: Boolean,
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: Object,
        default: undefined
      }
    },

    data () {
      return {
        selected: this.active
      };
    },

    watch: {
      active (val) {
        this.selected = val;
      },

      selected (val) {
        this.$emit('toggle', val);
      }
    },

    methods: {
      onClick () {
        if (this.disabled) {
          return;
        }

        if (this.enableToggling) {
          this.selected = !this.selected;
        }
        this.$emit('click');
      }
    }
  };
</script>

<style>
  @define-mixin fixedHover $border-color, $color {
    color: $color;
    border-color: $border-color;

    &:hover {
      color: $color;
      border-color: $border-color;
    }
  }

  .tag-list__tag {
    color: var(--secondary-text-color);
    line-height: 24px;
    text-overflow: ellipsis;
    font-size: 12px;

    white-space: nowrap;

    overflow: hidden;
    cursor: pointer;

    list-style: none;

    display: inline-flex;

    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    height: 28px;

    padding-left: 12px;
    padding-right: 12px;

    margin: 0 14px 0 0;

    background-color: var(--background-color);

    border: solid 1px var(--border-color);

    border-radius: 30px;

    transition: all var(--transition-duration) ease-in-out;

    &:hover {
      color: var(--action-hover-color);
      border-color: var(--action-hover-color);
    }

    &.tag-list__tag_disabled {
      cursor: not-allowed;
      opacity: 0.5;
      @mixin fixedHover var(--border-color), var(--border-color);
    }

    &.tag-list__tag_active {
      color: var(--action-color);
      border-color: var(--action-color);
    }

    &.tag-list__tag_small {
      line-height: 20px;

      height: 24px;

      padding-left: 8px;
      padding-right: 8px;

      margin: 0 12px 0 0;
    }

    &.tag-list__tag_gray {
      color: var(--secondary-text-color);

      background-color: var(--gray-table-color);

      border: none;

      &:hover {
        background-color: var(--gray-color);
      }

      &.tag-list__tag_active {
        background-color: var(--secondary-button-color);

        &:hover {
          color: var(--secondary-text-color);
          background-color: var(--secondary-button-hover-color);
        }
      }
    }

    & .tag-list__tag-icon {
      height: 12px;
    }
  }
</style>
