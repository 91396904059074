<template>
  <div>
    <OnboardingTextSection>
      Если вы часто используете какой-то аккаунт,<br> добавьте его в список «Важные».
    </OnboardingTextSection>

    <OnboardingTextSection>
      Для этого нажмите на звездочку, появляющуюся<br>
      рядом с названием аккаунта при наведении.<br>
      Каждый раз, когда вы нажимаете на звездочку,<br>
      я радуюсь:)
    </OnboardingTextSection>

    <div class="important-accounts-image-wrapper">
      <OnboardingImageSection
        class="important-accounts-image__mary"
        width="135px"
        :image="pointingMary"
      />
      <OnboardingImageSection
        class="important-accounts-image__important"
        width="184px"
        :image="sidebarImage"
      />
    </div>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';

  import pointingMary from 'assets/mascot/mary-is-pointing.png';
  import sidebarImage from 'onboarding/onboardings/SidebarNavigation/assets/important-block-sidebar.png';

  export default {
    name: 'ImportantAccountsStep',
    components: {
      OnboardingTextSection,
      OnboardingImageSection
    },
    data () {
      return {
        pointingMary,
        sidebarImage
      };
    }
  };
</script>

<style>
  .important-accounts-image-wrapper {
    display: flex;

    justify-content: space-around;
    align-items: center;

    padding-top: 16px;
  }

  .important-accounts-image-wrapper div {
    align-items: center;
    padding-top: 0;
  }

  .important-accounts-image__important img {
    border-radius: 7px;
  }

  .important-accounts-image__mary img {
    height: 124px;
  }
</style>
