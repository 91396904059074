<template>
  <div
    class="connect-new-shop-form"
    :class="{
      '_is-modal': isModal,
      '_is-show-promo-banner': showPromoBanner
    }"
  >
    <div class="connect-new-shop-form__header">
      <div class="connect-new__header-main">
        <h3 class="connect-new__title">
          <template v-if="isRecover">
            Восстановление магазина
          </template>

          <template v-else>
            Добавление магазина
          </template>
        </h3>

        <div
          v-if="isModal"
          class="create-seller-shop-modal__close"
          @click="$emit('close-modal')"
        >
          <MIcon :icon="iconCross" />
        </div>
      </div>

      <span class="connect-new__subtitle">
        Поделитесь доступом к магазину, чтобы сервис автоматически управлял
        ставками ваших рекламных кампаний и повышал их эффективность
      </span>

      <div class="channel-select__pick-list">
        <ChannelSelectItem
          v-for="channel in getAllChannels"
          :key="channel.id"
          :channel="channel"
          :selected="channel.channelId === activeChannelId"
          :disabled="isRecover"
          :dimmed="
            Boolean(activeChannelId) && channel.channelId !== activeChannelId
          "
          class="channel-select__pick-item"
          @channelSelect="handleChannelSelect(channel)"
        />
      </div>
    </div>

    <div>
      <template v-if="showPromoBanner">
        <PromoBanner @try-free-clicked="handleTryFreeClick" />
      </template>

      <template v-else>
        <div class="connect-new-shop-description">
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-for="description in currentPickChannel.descriptionLists"
            :key="description"
            class="connect-new-shop-description__item"
            v-html="description"
          />
          <!-- eslint-enable -->
        </div>

        <div class="connect-new-shop-form__content">
          <MErrorWrapper
            v-for="formItem in currentPickChannel.formData"
            :key="formItem.id"
            :error-object="formErrors"
            :error-path="formItem.id"
            class="connect-new-shop-input"
          >
            <MInput
              :label="formItem.label"
              :value="value[formItem.id]"
              @input="handleChangeValue(formItem.id, $event)"
              @blur="checkFormValidate(formItem.id)"
            />
          </MErrorWrapper>
        </div>

        <div class="connect-new-shop-form__footer">
          <NotifyCard
            :is-show-icon-warn="false"
          >
            <template v-slot:header>
              <div class="connect-new-shop-form__notify-header">
                <MIcon
                  :icon="iconWarningFilled"
                  class="connect-new-shop-form__notify-header-icon"
                />
                <span>Управление статусом рекламы переедет в Marilyn</span>
              </div>
            </template>

            <div class="connect-new-shop-form__notify_text">
              <template v-if="isRecover">
                <!-- eslint-disable-next-line max-len -->
                Как только вы восстановите магазин, он снова будет связан с Marilyn — управлять статусом вашей рекламы нужно будет в сервисе, не на маркетплейсе
              </template>

              <template v-else>
                <div class="connect-new-shop-form__notify-content-with-icon">
                  <!-- eslint-disable-next-line max-len -->
                  <span>Так&nbsp;сервис сможет останавливать или&nbsp;возобновлять кампании в&nbsp;нужное время и&nbsp;добьётся лучших результатов. Можно будет менять статус и&nbsp;вручную, но&nbsp;именно в&nbsp;Marilyn, не&nbsp;на&nbsp;маркетплейсе.</span>
                  <MIcon
                    :icon="iconTransformManageStatus"
                    class="connect-new-shop-form__notify-content-icon"
                  />
                </div>
              </template>
            </div>
          </NotifyCard>

          <MButton
            class="connect-new-shop-form__button-submit"
            large
            :is-loading="isLoading"
            :disabled="!isValidate"
            @click="handleSubmit"
          >
            <template v-if="isRecover">
              Восстановить
            </template>

            <template v-else>
              Добавить
            </template>
          </MButton>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import {
    getSellerConnectionData,
    getConnectionById,
    getErrorDataByConnectionId
  } from 'utils/seller/sellerConnectionData';
  import ChannelSelectItem from 'components/common/ChannelSelect/ChannelSelectItem.vue';
  import MErrorWrapper from 'legacy/modules/common/components/MErrorWrapper/MErrorWrapper';
  import NotifyCard from 'components/common/NotifyCard';
  import PromoBanner from 'components/common/PromoBanner';
  import {CREATE_NEW_SHOP} from 'store/modules/seller/shopsSeller';
  import {mapActions, mapGetters} from 'vuex';
  import ApiBadRequestError from 'utils/api/errors/ApiBadRequestError';
  import {closeAllNotifications} from 'components/common/MNotification/MNotificationManager';
  import iconCross from 'assets/icons/cross.svg';
  import iconWarningFilled from 'assets/icons/warning-filled.svg';
  import iconTransformManageStatus from 'assets/seller/transform-manage-status.svg';
  import {shopSellerService} from 'services/seller';

  export default {
    name: 'ConnectNewShopForm',
    components: {
      NotifyCard,
      ChannelSelectItem,
      MErrorWrapper,
      PromoBanner
    },
    props: {
      isModal: {
        type: Boolean,
        default: false
      },
      isRecover: {
        type: Boolean,
        default: false
      },
      recoverShop: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        activeChannelId: getSellerConnectionData()[0].channelId,
        formErrors: {},
        value: {},
        isLoading: false,

        iconCross,
        iconWarningFilled,
        iconTransformManageStatus
      };
    },
    computed: {
      ...mapGetters('sellerUser', ['currentUser']),
      getAllChannels () {
        if (this.checkDateForNewWBFlow()) {
          return [...getSellerConnectionData().reverse()];
        }
        return getSellerConnectionData();
      },
      showPromoBanner () {
        return this.checkDateForNewWBFlow() && this.activeChannelId === 23;
      },
      currentPickChannel () {
        return getConnectionById(this.activeChannelId);
      },
      isValidate () {
        const valueKeysList = Object.keys(this.value);
        return (
          Object.keys(this.formErrors).filter((errorKey) =>
            valueKeysList.includes(errorKey)
          ).length === 0
        );
      }
    },
    watch: {
      currentPickChannel: {
        handler () {
          this.value = {};
          this.formErrors = {};
          closeAllNotifications();

          this.currentPickChannel.formData.forEach((formItem) => {
            this.handleChangeValue(formItem.id, formItem?.defaultValue || '');
          });
        },
        immediate: true
      }
    },
    mounted () {
      if (this.isRecover) {
        this.setValueForRecover();
      }

      if (this.checkDateForNewWBFlow()) {
        this.activeChannelId = getSellerConnectionData()[1].channelId;
      }
    },
    methods: {
      ...mapActions('sellerShops', {
        createNewShop: CREATE_NEW_SHOP
      }),
      checkDateForNewWBFlow () {
        const createdAccount = new Date(this.currentUser?.created_at);
        const comparisonDate = new Date('2025-03-01');

        return createdAccount > comparisonDate;
      },
      handleTryFreeClick () {
        window.open(
          'https://mp.tochka.com/?utm_source=tochkareklama&utm_medium=bider&utm_campaign=tochkaid',
          '_blank'
        );
      },
      setValueForRecover () {
        const {name, channel_id} = this.recoverShop;
        this.activeChannelId = channel_id;
        this.$nextTick(() => this.handleChangeValue('name', name));
      },
      handleChannelSelect (channel) {
        this.activeChannelId = channel.channelId;
      },
      handleChangeValue (id, newValue) {
        if (newValue && this.formErrors[id]) {
          this.$delete(this.formErrors, id);
        }

        this.$set(this.value, id, newValue);
      },
      checkFormValidate (onlyCheck = null) {
        const compileErrors = {};

        this.currentPickChannel.formData.forEach((formItem) => {
          if (formItem.required && !this.value[formItem.id]) {
            compileErrors[formItem.id] = formItem.required;
          }
        });

        if (onlyCheck) {
          if (compileErrors[onlyCheck]) {
            this.$set(this.formErrors, onlyCheck, compileErrors[onlyCheck]);
          }

          return;
        }

        this.formErrors = compileErrors;
      },
      handleHttpErrors (e) {
        const errors = e.getValidationErrors();
        const message = e.getErrorMessage();
        this.formErrors = errors || [];

        if (message) {
          this.$notify({
            type: 'error',
            header: 'Ошибка',
            text: message,
            timeout: 0,
            canCloseNotificationBeforeClick: false
          });

          return;
        }

        if (errors) {
          const {title, text} = getErrorDataByConnectionId(
            this.activeChannelId
          );

          this.$notify({
            type: 'error',
            header: title,
            timeout: 0,
            canCloseNotificationBeforeClick: false,
            text
          });

          return;
        }

        throw e;
      },
      async handleSubmit () {
        this.isLoading = true;
        closeAllNotifications();

        this.checkFormValidate();
        if (!this.isValidate) {
          this.isLoading = false;
          return;
        }

        const compileParams = {
          ...this.value,
          channel_id: this.activeChannelId
        };

        try {
          if (this.isRecover) {
            const shopId = this.recoverShop.id;
            await shopSellerService.restoreShop(shopId, compileParams);

            this.$nextTick(() =>
              this.$notify({
                type: 'success',
                header: 'Магазин восстановлен'
              })
            );

            this.$emit('update-current-page');
            this.$emit('close-modal');
          } else {
            await this.createNewShop(compileParams);
            this.$emit('success-create-shop');
          }
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            return this.handleHttpErrors(e);
          }
          throw e;
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style>
  .connect-new-shop-form {
    &._is-show-promo-banner {
      height: 716px;
    }

    &._is-modal {
      width: 640px;
      padding: 20px 20px 16px;

      .connect-new__header-main {
        display: flex;
        justify-content: space-between;
      }

      .channel-select__pick-list {
        margin-top: 32px;
      }
    }
  }

  .create-seller-shop-modal__close {
    color: var(--secondary-text-color);

    cursor: pointer;

    width: 24px;
    height: 24px;

    &:hover {
      color: var(--text-color);
    }
  }

  .connect-new__title {
    font-size: 20px;
    font-weight: 500;

    color: #242424;
    line-height: 24px;

    letter-spacing: -0.03em;

    margin: 0;
  }

  .connect-new__header-main {
    margin: 0 0 8px;
  }

  .connect-new__subtitle {
    font-size: 14px;
    font-weight: 400;

    color: #696969;
    line-height: 20px;

    letter-spacing: -0.03em;
  }

  .channel-select__pick-list {
    margin-top: 16px;
  }

  .connect-new-shop-form__header {
    margin-bottom: 32px;
  }

  .channel-select__pick-item {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .connect-new-shop-description {
    margin-bottom: 32px;
  }

  .connect-new-shop-description__item {
    font-size: 14px;
    font-weight: 400;

    color: #4F4F4F;
    line-height: 20px;

    letter-spacing: -0.03em;

    margin: 0;

    * {
      vertical-align: middle;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .connect-new-shop-input {
    .m-error_wrapper__error {
      font-size: 12px;
      line-height: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .connect-new-shop-form__content {
    margin-bottom: 32px;
  }

  .connect-new-shop-form__notify_text {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #242424;
  }

  .connect-new-shop-form__button-submit {
    margin-top: 32px;
  }

  .connect-new-shop-form__notify-content-with-icon {
    display: flex;
    align-items: center;

    .connect-new-shop-form__notify-content-icon {
      flex-shrink: 0;
      width: 72px;
      height: 20px;
      margin-left: 16px;
    }
  }

  .connect-new-shop-form__notify-header {
    display: flex;
    align-items: center;

    .connect-new-shop-form__notify-header-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
</style>
