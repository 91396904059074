.floating-card {
    box-shadow: var(--floating-card-shadow);
    border-radius: 4px;
}

.floating-button.m-button {
    display: flex;
    box-shadow: var(--floating-card-shadow);
    width: 100%;
    align-items: center;
    justify-content: center;
    background: var(--background-color) !important;
    color: var(--action-color) !important;
}

.floating-button.m-button:not(.m-button_disabled):hover {
    box-shadow: 0 0 12px rgba(151, 151, 151, 0.4);
}

.floating-button.m-button.m-button_disabled {
    color: var(--action-disabled-color) !important;
}
