<template>
  <MModal
    ref="modal"
    class="create-seller-shop-modal"
    :close-by-outside-click="false"
  >
    <template v-slot:modal-content>
      <ConnectNewShopForm
        is-modal
        :is-recover="isRecover"
        :recover-shop="recoverShop"
        @close-modal="$emit('closeModal')"
        @success-create-shop="handleSuccessCreateShop"
        @update-current-page="$emit('update-current-page')"
      />
    </template>
  </MModal>
</template>

<script>
  import ConnectNewShopForm from 'components/seller/shop/ConnectNewShopForm';
  import appEventBus from 'utils/appEventBus';
  import successIcon from 'assets/icons/success-icon.svg';

  export default {
    name: 'CreateSellerShopModal',
    components: {
      ConnectNewShopForm
    },
    props: {
      isRecover: {
        type: Boolean,
        default: false
      },
      recoverShop: {
        type: Object,
        default: null
      }
    },
    methods: {
      openModal (params) {
        appEventBus.$emit('SHOW_SUCCESS_CREATE_SHOP_MODAL', params);
      },
      handleSuccessCreateShop () {
        this.openModal({
          contentTitle: 'Магазин добавлен — вы можете запускать стратегии и менять статус рекламы в Marilyn',
          styleList: {'z-index': 'calc(var(--popover-z-index) + 3)'},
          contentText: `
            <p>
              Останавливайте и&nbsp;возобновляйте кампании именно здесь, не на маркетплейсе.
              Так&nbsp;сервис сможет синхронизировать изменение статуса и стратегию.
            </p>
            <p>
              Если кампания сама остановится на маркетплейсе из-за нехватки бюджета,
              после пополнения обязательно возобновите её в&nbsp;Marilyn.
            </p>
          `,
          positionButtonIsCenter: true,
          contentButton: 'Хорошо',
          contentIcon: successIcon
        });

        this.$emit('closeModal');
      }
    }
  };
</script>

<style>
  .create-seller-shop-modal {
    .m-modal__header,
    .m-modal__footer {
      display: none;
    }
  }
</style>
