<template>
  <router-link
    v-if="isRouterLink"
    :to="to"
  >
    <slot />
  </router-link>
  <component
    :is="componentType"
    v-else
    v-bind="componentProps"
  >
    <slot />
  </component>
</template>

<script>
  export default {
    name: 'BreadcrumbNode',
    props: {
      url: {
        type: String,
        default: ''
      },
      isLast: {
        type: Boolean,
        default: false
      },
      to: {
        type: Object,
        default: undefined
      }
    },
    computed: {
      isLink () {
        return this.url && !this.isLast;
      },
      isRouterLink () {
        return Boolean(this.to);
      },
      componentType () {
        if (this.isLink) {
          return 'a';
        }
        return 'div';
      },
      componentProps () {
        if (this.isLink) {
          return {
            href: this.url
          };
        }
        return {};
      }
    }
  };
</script>

<style scoped>
  a,
  div {
    position: relative;
    display: inline-block;
  }
</style>
