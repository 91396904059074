<template>
  <div
    :id="elId"
    :ref="elId"
    class="tour-step"
    :style="styles"
    :class="{
      [`tour-step_${params.placement}`]: true,
      [step.customClass]: Boolean(step.customClass),
      'tour-step_hidden': !stepReady
    }"
  >
    <MTourHeader
      v-if="step.header"
      :size="step.headerSize"
      @stop="stop"
    >
      {{ step.header }}
    </MTourHeader>
    <MTourBody>
      <component
        :is="step.component"
      />
    </MTourBody>
    <MTourStepControls
      :ok-text="okText"
      :current-index="currentIndex"
      :steps-count="stepsCount"
      :hide-finish-button="step.hideFinishButton"
      :not-hide-back-button-on-last-step="step.notHideBackButtonOnLastStep"
      @next="nextStep"
      @stop="stop"
      @prev="previousStep"
    />
    <div
      v-show="arrowSideOffset"
      class="tour-step__arrow"
      :class="`tour-step__arrow_${params.placement}`"
      :style="customStyles"
    />
  </div>
</template>

<script>
  import Popper from 'popper.js';
  import jump from 'jump.js';
  import sum from 'hash-sum';
  import MTourHeader from 'onboarding/components/MTour/MTourHeader';
  import MTourStepControls from 'onboarding/components/MTour/MTourStepControls';
  import MTourBody from 'onboarding/components/MTour/MTourBody';
  import MTourBodyLine from 'onboarding/components/MTour/MTourBodyLine';
  import createHighlight from 'onboarding/utils/createHighlight';
  import _ from 'lodash';
  import {HINT_SHOW_TIME} from 'appConstants';
  _.noConflict();

  const DEFAULT_STEP_OPTIONS = {
    placement: 'bottom',
    enableScrolling: true,
    duration: null,
    offset: null,
    modifiers: {
      arrow: {
        element: '.tour-step__arrow'
      }
    }
  };

  // how arrow is positioned
  const HORIZONTAL_POSITIONS = new Set(['top', 'bottom']);
  const VERTICAL_POSITIONS = new Set(['left', 'right']);

  export default {
    name: 'MTourTip',
    components: {
      MTourStepControls,
      MTourHeader,
      MTourBody,
      MTourBodyLine
    },
    props: {
      step: {
        type: Object,
        default: Object
      },
      previousStep: {
        type: Function,
        default () {}
      },
      nextStep: {
        type: Function,
        default () {}
      },
      stop: {
        type: Function,
        default () {}
      },
      restart: {
        type: Function,
        default () {}
      },
      isFirst: {
        type: Boolean
      },
      isLast: {
        type: Boolean
      },
      labels: {
        type: Object,
        default: Object
      },
      active: {
        type: Boolean,
        default: false
      },
      stepsCount: {
        type: Number,
        default: 0
      },
      currentIndex: {
        type: Number,
        default: 0
      },
      width: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        elId: `v-step-${sum(this.step.target)}`,
        popper: null,
        arrowSideOffset: null,
        stepReady: false
      };
    },
    computed: {
      isHint () {
        return this.step.isHint;
      },
      okText () {
        return this.step.okText ? this.step.okText : this.defaultOkText;
      },
      defaultOkText () {
        return 'Завершить';
      },
      styles () {
        const params = {};
        if (this.width) {
          params['--tour-step-width'] = this.width;
        }
        if (this.arrowSideOffset) {
          const operator = HORIZONTAL_POSITIONS.has(this.params.placement) ? '+' : '-';
          params['--arrow-side-offset'] = `calc(${this.arrowSideOffset}px ${operator} var(--tour-step-arrow-size))`;
        }
        return params;
      },
      params () {
        return {
          ...DEFAULT_STEP_OPTIONS,
          ...this.step.params
        };
      },
      customStyles () {
        if (this.step.params.customArrowStyle) {
          return this.step.params.customArrowStyle;
        }
        return '';
      }
    },
    watch: {
      active () {
        if (this.active && !this.popper) {
          this.createStep();
        }
      }
    },
    mounted () {
      // auto close hint (type) onboarding or close on click outside
      if (this.isHint) {
        const clickListener = () => {
          if (this.active) {
            this.stop();
          }
        };

        this.$nextFrame(() => {
          document.addEventListener('click', clickListener, {once: true});
        });

        setTimeout(() => {
          if (this.active) {
            this.stop();
          }
        }, HINT_SHOW_TIME);
      }
    },
    methods: {
      createHighlight (targetElement) {
        const {mask, remove, toggle, offsetX, offsetY} = createHighlight(targetElement, this.step.highlightOptions);
        this.$watch('active', toggle);
        this.$on('hook:beforeDestroy', remove);
        return {highlightEl: mask, offsetX, offsetY};
      },
      createStep () {
        const targetElement = document.querySelector(this.step.target);
        let popperTargetEl = targetElement;

        // init popper and highlighter after nextStep callbacks are applied
        this.$nextFrame(async () => {
          if (targetElement) {

            let popperParams = this.params;
            if (this.params.enableScrolling) {
              if (this.step.duration || this.step.offset) {
                const jumpOptions = {
                  duration: this.step.duration || 1000,
                  offset: this.step.offset || 0,
                  callback: undefined,
                  a11y: false
                };

                jump(targetElement, jumpOptions);
              } else {
                await this.$scrollTo(
                  this.step.target,

                  // if undefined this function will enable scrolling by default
                  popperParams.enableScrollingAnimation || false,
                  true
                );
              }
            }

            if (this.step.highlightTarget) {
              let highlightTarget = targetElement;
              if (!_.isBoolean(this.step.highlightTarget)) {
                highlightTarget = document.querySelector(this.step.highlightTarget);
              }

              const {highlightEl, offsetX, offsetY} = this.createHighlight(highlightTarget);

              const popperOffsetY = ['top', 'bottom'].includes(this.params.placement) ? offsetY : 0;
              const popperOffsetX = ['right', 'left'].includes(this.params.placement) ? offsetX : 0;

              popperParams = _.defaultsDeep(this.params, {
                modifiers: {
                  offset: {
                    offset: [popperOffsetY, popperOffsetX].join(',')
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'viewport'
                  }
                }
              });

              if (this.step.attachToHighlight) {
                popperTargetEl = highlightEl;
              }
            }

            this.popper = new Popper(
              popperTargetEl,
              this.$refs[this.elId],
              popperParams
            );

            this.$nextFrame(() => {
              this.stepReady = true;
              this.updateArrowOffset(popperTargetEl, this.$refs[this.elId]);
            });
          } else {
            console.error(`[MTour] The target element ${this.step.target} of [.${this.elId}] does not exist!`);
            this.$emit('targetNotFound', this.step);
          }
        });
      },
      updateArrowOffset (targetEl, tourEl) {
        const tourPlacement = this.params.placement;

        const targetBounding = targetEl.getBoundingClientRect();
        const tourBounding = tourEl.getBoundingClientRect();

        const targetCenterX = targetBounding.x + targetBounding.width / 2;
        const targetCenterY = targetBounding.y + targetBounding.height / 2;

        const arrowHorizontalPosition = targetCenterX - tourBounding.x;
        const arrowVerticalPosition = targetCenterY - tourBounding.y;

        if (HORIZONTAL_POSITIONS.has(tourPlacement)) {
          this.arrowSideOffset = arrowHorizontalPosition;
        } else if (VERTICAL_POSITIONS.has(tourPlacement)) {
          this.arrowSideOffset = arrowVerticalPosition;
        }
      }
    }
  };
</script>

<style>
  .tour-step {
    --tour-step-arrow-size: 6px;
    --tour-step-width: 328px;
    --arrow-side-offset: calc(50% - var(--tour-step-arrow-size));
    --arrow-depth-offset: calc(-1 * var(--tour-step-arrow-size));

    position: absolute;
    z-index: calc(var(--popover-z-index) + 2);

    box-sizing: border-box;
    width: var(--tour-step-width);

    padding: 16px 24px;

    margin: 14px;

    background-color: var(--background-color);

    box-shadow: 0 16px 40px rgba(48, 56, 71, 0.3);

    border-radius: 8px;

    pointer-events: all;
  }

  .tour-step_hidden {
    opacity: 0;
    pointer-events: none;
  }

  .tour-step__header {
    color: var(--title-color);
    margin-bottom: 8px;
  }

  .tour-step__body {
    color: var(--secondary-text-color);
    margin-bottom: 18px;
  }

  .tour-step__arrow {
    position: absolute;

    width: 0;
    height: 0;

    margin-left: 0;
    margin-right: 0;

    border-color: var(--background-color);

    border-style: solid;
  }

  /* tour-step__arrow styles */
  .tour-step__arrow_top {
    bottom: var(--arrow-depth-offset);
    left: var(--arrow-side-offset);

    margin-top: 0;
    margin-bottom: 0;

    border-color: var(--background-color) transparent transparent transparent;

    border-width: var(--tour-step-arrow-size) var(--tour-step-arrow-size) 0 var(--tour-step-arrow-size);
  }

  .tour-step__arrow_bottom {
    top: var(--arrow-depth-offset);
    left: var(--arrow-side-offset);

    margin-top: 0;
    margin-bottom: 0;

    border-color: transparent transparent var(--background-color) transparent;

    border-width: 0 var(--tour-step-arrow-size) var(--tour-step-arrow-size) var(--tour-step-arrow-size);
  }

  .tour-step__arrow_right {
    top: var(--arrow-side-offset);
    left: var(--arrow-depth-offset);

    margin-left: 0;
    margin-right: 0;

    border-color: transparent var(--background-color) transparent transparent;

    border-width: var(--tour-step-arrow-size) var(--tour-step-arrow-size) var(--tour-step-arrow-size) 0;
  }

  .tour-step__arrow_left {
    top: var(--arrow-side-offset);
    right: var(--arrow-depth-offset);

    margin-left: 0;
    margin-right: 0;

    border-color: transparent transparent transparent var(--background-color);

    border-width: var(--tour-step-arrow-size) 0 var(--tour-step-arrow-size) var(--tour-step-arrow-size);
  }
</style>
