<template>
  <div class="seller-shop-page">
    <div class="container">
      <ShopSellerList
        :items="getShops"
        @update-current-page="updateCurrentPage"
      />

      <SellerClientsCases
        :items="getClientsCases"
        class="seller-shop-page__cases"
      />
    </div>
  </div>
</template>

<script>
  import ShopSellerList from 'components/seller/shop/ShopSellerList';
  import SellerClientsCases from 'components/seller/shop/SellerClientsCases';
  import PropsDependentPage from 'pages/mixins/PropsDependentPage';
  import {mapActions, mapGetters} from 'vuex';
  import {FETCH_SHOP_CURRENT_USER} from 'store/modules/seller/shopsSeller';
  import {getSellerClientsCasesData} from 'utils/seller/sellerClientsCasesData';

  export default {
    name: 'SellerShopPage',
    components: {
      ShopSellerList,
      SellerClientsCases
    },
    mixins: [PropsDependentPage],
    data () {
      return {
        getClientsCases: getSellerClientsCasesData()
      };
    },
    computed: {
      ...mapGetters('sellerShops', ['getShops'])
    },
    methods: {
      ...mapActions('sellerShops', {
        fetchShopCurrentUser: FETCH_SHOP_CURRENT_USER
      }),
      async updateCurrentPage () {
        this.isLoading = true;
        await this.fetchShopCurrentUser();
        this.isLoading = false;
      },
      fetchDataAsync () {
        return [this.fetchShopCurrentUser()];
      }
    }
  };
</script>

<style>
  .seller-shop-page__cases {
    margin-top: 150px;
  }

  .seller-shop-page {
    padding-bottom: 78px;
  }
</style>
