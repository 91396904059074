<template>
  <div class="progress-widget-pagination">
    <div
      v-if="isProjectDashboard"
      class="progress-widget-pagination__link-container"
    >
      <MLink
        :icon="iconArrow"
        :href="ordersOrPeriodsListUrl"
        icon-position="after"
        class="progress-widget-pagination-link"
      >
        {{ allEntitiesText }}
      </MLink>
    </div>
    <div
      v-if="multiplePages"
      class="progress-widget-pagination__controls"
    >
      <div
        class="progress-widget-pagination-controls__up"
        :class="{'progress-widget-pagination-controls__up_disabled': !allowPageUp}"
      >
        <MIcon
          :icon="iconUp"
          @click="pageUp"
        />
      </div>
      <div
        class="progress-widget-pagination-controls__pages"
      >
        {{ currentPage }} {{ pgettext("page_x_of_y", "of") }} {{ pagesCount }}
      </div>
      <div
        :class="{'progress-widget-pagination-controls__down_disabled': !allowPageDown}"
        class="progress-widget-pagination-controls__down"
      >
        <MIcon
          :icon="iconUp"
          @click="pageDown"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import MLink from 'legacy/modules/common/components/MLink';
  import iconUp from 'assets/icons/arrow-up.svg';
  import iconArrow from 'assets/icons/arrow.svg';
  import MIcon from 'legacy/modules/common/components/MIcon';
  import appContext from 'legacy/appcontext';
  import translation from 'translation';
  import {mapGetters} from 'vuex';

  export default {
    name: 'ProgressWidgetPagination',
    components: {MLink, MIcon},
    props: {
      pagesCount: {
        type: Number,
        default: 0
      },
      currentPage: {
        type: Number,
        default: 1
      },
      type: {
        type: String,
        default: 'order',
        choices: ['order', 'period']
      }
    },
    data () {
      return {
        iconArrow,
        iconUp
      };
    },
    computed: {
      ...mapGetters('projects', ['currentProject']),
      isProjectDashboard () {
        // assume is project dashboard if we have current project
        return this.currentProject;
      },
      multiplePages () {
        return this.pagesCount > 1;
      },
      ordersOrPeriodsListUrl () {
        if (this.type === 'order') {
          return appContext.url('ordersList', appContext.urlContext());
        }
        return appContext.url('ordersList', appContext.urlContext(), {entity: this.type});
      },
      allowPageUp () {
        return this.currentPage !== 1;
      },
      allowPageDown () {
        return this.currentPage < this.pagesCount;
      },
      allEntitiesText () {
        if (this.type === 'order') {
          return translation.trc('dashboards', 'All orders');
        }
        return translation.trc('dashboards', 'All periods');
      }
    },
    methods: {
      pageUp () {
        if (this.allowPageUp) {
          return this.$emit('pageUp');
        }
      },
      pageDown () {
        if (this.allowPageDown) {
          return this.$emit('pageDown');
        }
      }
    }
  };
</script>

<style>
  .progress-widget-pagination {
    display: flex;

    flex-direction: row;
    align-items: center;

    height: 30px;

    padding-left: 20px;
    padding-right: 14px;

    border-top: 1px solid var(--border-color);
  }

  .progress-widget-pagination-link {
    font-weight: var(--medium-font-weight);
  }

  .progress-widget-pagination__controls {
    display: flex;

    align-items: center;

    margin-left: auto;
  }

  .progress-widget-pagination-controls__pages {
    color: var(--secondary-text-color);
    margin: 0 4px;
  }

  .progress-widget-pagination-controls__up,
  .progress-widget-pagination-controls__down {
    width: 14px;
    height: 14px;

    user-select: none;
  }

  .progress-widget-pagination-controls__up svg,
  .progress-widget-pagination-controls__down svg {
    color: var(--text-color);

    cursor: pointer;

    width: 14px;
    height: 14px;
  }

  .progress-widget-pagination-controls__down svg {
    transform: rotate(180deg);
  }

  .progress-widget-pagination-controls__up_disabled svg,
  .progress-widget-pagination-controls__down_disabled svg {
    color: var(--gray-text-color);
    cursor: not-allowed;
  }

  .progress-widget-pagination-controls__up:hover svg,
  .progress-widget-pagination-controls__down:hover svg {
    color: var(--secondary-text-color);
  }

  .progress-widget-pagination-controls__up_disabled:hover svg,
  .progress-widget-pagination-controls__down_disabled:hover svg {
    color: var(--gray-text-color);
  }

</style>
