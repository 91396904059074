<template>
  <TagList
    class="channel-tags"
    :tags="channelTags"
    :value="localValue"
    :enable-toggling="false"
    :disabled="disabled"
    @input="itemsChanged"
  />
</template>

<script>
  import TagList from 'legacy/modules/common/components/TagList';
  import _ from 'lodash';
  _.noConflict();

  export default {
    name: 'ChannelTags',
    components: {TagList},
    props: {
      channels: {
        type: Array,
        required: true
      },
      value: {
        type: Array,
        default: Array
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        localValue: this.value
      };
    },
    computed: {
      channelTags () {
        return this.channels.map(channel => ({
          id: channel.id,
          icon: channel.icon,
          title: channel.display_name
        }));
      }
    },
    watch: {
      value (val) {
        this.localValue = val;
      }
    },
    mounted () {
      if (this.value.length === 0) {
        this.localValue = this.channelTags.map(i => i.id);
      } else {
        this.localValue = this.value;
      }
    },
    methods: {
      itemsChanged (item) {

        /*
         * Do not trigger input if nan are selected (all selected in backbone view,
         * but Tags component returns id of all ids in this.channels)
         */
        if (item.length > 0 && !(this.value.length === 0 && item.length === this.channels.length)) {
          this.localValue = item;
          this.$emit('input', this.localValue);
        }
      }
    }
  };
</script>

<style>
  .channel-tags .tag-list__tag {
    width: 46px;
  }
</style>
