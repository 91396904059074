<template>
  <div>
    <OnboardingTextSection>
      Если при <b>создании или в настройках проекта</b> вы<br>
      включили функцию <b>«Останавливать и запускать<br>
        размещения»</b>, то изменение <b>статуса</b> рекламных<br>
      кампаний в этой таблице будет изменять статус<br>
      кампаний на площадках.
    </OnboardingTextSection>

    <OnboardingTextSection>
      Отключить данную функцию можно во вкладке<br>
      <b>«Настройки»</b> меню проекта.
    </OnboardingTextSection>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import ezImage from 'assets/mascot/mary-it-is-easy.png';


  export default {
    name: 'PlacementsStatusCellStep',
    components: {OnboardingTextSection},
    data () {
      return {
        ezImage
      };
    }
  };
</script>

<style>
  .onboarding-placements-tab__mascot {
    padding-top: 26px;
  }
</style>
