<template>
  <MChart
    ref="chart"
    :titles="metricTitles"
    :series="resultMetricSeries"
    :options="chartOptions"
    :height="height"
  />
</template>

<script>
  import MChart from 'legacy/modules/common/components/MChart/MChart';
  import formatNumber from 'legacy/modules/common/components/MNumeric/formatNumber';
  import getDateRange from 'utils/dateRange/getDateRange';
  import insertMissedOrderedObjects from 'utils/object/insertMissedOrderedObjects';
  import moment from 'moment-wrapper';
  import _ from 'lodash';
  import ColorGetterNestedMixin from 'components/Dashboards/Widgets/mixins/ColorGetterNestedMixin';
  _.noConflict();

  const AXIS_TEXT_COLOR = '#8D8D8D';
  const STROKE_SIZE = 8;
  const METRIC_SYMBOLS_LIMIT = 38;

  export default {
    name: 'LineWidgetChart',
    components: {
      MChart
    },
    mixins: [ColorGetterNestedMixin],
    props: {
      height: {
        type: String,
        default: '100%'
      },
      statistics: {
        type: Array,
        default: Array
      },
      metric: {
        type: Array,
        default: Array
      },
      grouping: {
        type: String,
        default: ''
      },
      xAxis: {
        type: String,
        default: 'date'
      },
      metricsData: {
        type: Object,
        default: Object
      },
      showMarkers: {
        type: Boolean,
        default: false
      },
      dateStart: {
        type: String,
        default: ''
      },
      dateEnd: {
        type: String,
        default: ''
      }
    },
    computed: {
      areTwoMetrics () {
        return this.metric.length === 2;
      },
      metricTitles () {
        if (this.metric.length < 2) {
          return [];
        }
        return this.metric.map((m, i) =>
          `${i === 0 ? '________' : '_  _  _  _  _'} ${this.metricsData[this.metric[i]].name}`
        );
      },
      hideTooltipTitle () {
        return this.grouping === 'no' && !this.areTwoMetrics;
      },
      metricSeries () {
        let groupById = this.grouping === 'label' ? 'label_name' : `${this.grouping}_id`;
        const groupedStats = _.groupBy(this.statistics, groupById);
        return Object.values(groupedStats).map(stat => ({
          id: stat[0][groupById],
          name: stat[0][`${this.grouping}_name`],
          data: insertMissedOrderedObjects(
            stat,
            this.dateSeries,
            'date',
            (d) => ({
              date: d,
              [this.metric[0]]: 0
            })
          )
        }));
      },
      resultMetricSeries () {
        return this.metricSeries.reduce((acc, s) => {
          this.metric.forEach(metric => {
            let resultData = {
              name: s.name,
              nameWithMetric: s.name ? `${s.name}_${this.metricsData[metric].name}` : this.metricsData[metric].name,
              data: []
            };

            // replace `null` values with 0
            resultData.data = s.data.map(d => d[metric]).map(value => _.isNil(value) ? 0 : value);
            acc.push(resultData);
          });

          return acc;
        }, []);
      },
      dashArray () {
        /*
         * series for the right (additional) yaxis are in even positions
         * these series are drawn with a dotted line
         */
        if (this.areTwoMetrics) {
          return this.resultMetricSeries.map((el, i) => {
            if (i % 2 === 0) {
              return 0;
            }
            return STROKE_SIZE;
          });
        }
        return 0;
      },
      colors () {
        const _colorsData = this.metricSeries.reduce((acc, el, i) => {
          if (!acc[el.name]) {
            acc[el.name] = {};
            acc[el.name].color = this.chartColors[i];
          }
          return acc;
        }, {});

        return this.resultMetricSeries.map(el => _colorsData[el.name].color);
      },
      yAxisLimits () {
        if (this.areTwoMetrics) {
          return this.resultMetricSeries.reduce((acc, el, i) => {

            // we can get null or undefined value in data elements
            const filteredData = el.data.filter(val => Boolean(val));

            // Math.min() for nothing is infinity, and this breaks apexchart plot
            let max = filteredData.length > 0 ? Math.max(...filteredData) : 0;
            let min = filteredData.length > 0 ? Math.min(...filteredData) : 0;
            if (i % 2 === 0) {
              acc.left.max = acc.left.max > max ? acc.left.max : max;
              acc.left.min = acc.left.min < min ? acc.left.min : min;
            } else {
              acc.right.max = acc.right.max > max ? acc.right.max : max;
              acc.right.min = acc.right.min < min ? acc.right.min : min;
            }
            return acc;
          }, {
            left: {
              max: 0,
              min: this.resultMetricSeries[0] && this.resultMetricSeries[0].data
                ? this.resultMetricSeries[0].data[0] : 0
            },
            right: {
              max: 0,
              min: this.resultMetricSeries[1] && this.resultMetricSeries[1].data
                ? this.resultMetricSeries[1].data[1] : 0
            }
          });
        }

        return this.resultMetricSeries.reduce((acc, el) => {

          // we can get null or undefined value in data elements
          const filteredData = el.data.filter(val => Boolean(val));

          let max = Math.max(...filteredData);
          let min = Math.min(...filteredData);
          acc.max = acc.max > max ? acc.max : max;
          acc.min = acc.min < min ? acc.min : min;
          return acc;
        }, {min: 0, max: 0});
      },
      yAxisOptions () {
        return this.metric.map((m, i) => ({
          max: this.areTwoMetrics
            ? i === 0
              ? this.yAxisLimits.left.max
              : this.yAxisLimits.right.max
            : this.yAxisLimits.max,
          min: this.areTwoMetrics
            ? i === 0
              ? this.yAxisLimits.left.min
              : this.yAxisLimits.right.min
            : this.yAxisLimits.min,
          forceNiceScale: true,
          seriesName: this.metric[i],
          opposite: i === 1,
          show: true,
          showAlways: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            offsetX: -5,
            style: {
              color: AXIS_TEXT_COLOR,
              fontSize: '12px'
            },
            formatter: (x, data, config) => {
              let formatType = 'integer';

              if (this.areTwoMetrics) {
                // for tooltip labels
                if (data && (data.seriesIndex || data.seriesIndex === 0)) {
                  let ind = data.seriesIndex % 2 === 0 ? 0 : 1;
                  formatType = this.metric[ind] ? this.metricsData[this.metric[ind]].type : 'integer';
                }

                // for yaxis labels and for crosshairs
                if (typeof data === 'number' && config || !data && !config) {
                  formatType = this.metricsData[this.metric[i]].type;
                }
              } else {
                formatType = this.metricsData[this.metric[0]].type;
              }

              return formatNumber(x, formatType, {withCurrencySymbol: true});
            }
          },
          tooltip: {
            enabled: this.areTwoMetrics
          }
        }));
      },
      tooltipTitles () {
        return this.resultMetricSeries.map((s) => ({
          formatter: this.tooltipValueFormatter.bind(this),
          title: {
            formatter: () => _.truncate(
              this.hideTooltipTitle ? '' : this.areTwoMetrics ? `${s.nameWithMetric}:` : `${s.name}:`,
              {length: METRIC_SYMBOLS_LIMIT}
            )
          }
        }));
      },
      dateSeries () {
        return getDateRange(new Date(this.dateStart), new Date(this.dateEnd)).
          map(i => moment(i).
            formatDateApi());
      },
      showLegend () {
        return this.grouping !== 'no';
      },
      chartOptions () {
        return {
          grid: {
            padding: {
              top: -22,
              right: 15,
              bottom: 10,
              left: 5
            }
          },
          colors: this.colors,
          markers: {
            size: this.showMarkers ? 5 : 0
          },
          stroke: {
            width: 2,
            dashArray: this.dashArray,
            curve: 'smooth'
          },
          tooltip: {
            y: this.tooltipTitles
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          legend: {
            markers: {
              show: false
            },
            formatter: (seriesName, opts) => {
              if (this.showLegend && this.areTwoMetrics && opts.seriesIndex % 2 === 0 || !this.areTwoMetrics) {

                const markerWrapper = document.createElement('div');
                const markerCircle = document.createElement('div');
                const markerText = document.createElement('span');
                const name = document.createTextNode(seriesName);
                markerWrapper.appendChild(markerCircle);
                markerText.appendChild(name);
                markerWrapper.appendChild(markerText);
                markerWrapper.className = 'apexcharts-legend-marker-wrapper';
                markerCircle.className = 'apexcharts-legend-marker--custom';
                markerCircle.style.backgroundColor = this.colors[opts.seriesIndex];
                return markerWrapper.outerHTML;
              }
              return '<span style="display: none"></span>';
            },
            showForSingleSeries: this.showLegend,
            onItemClick: {
              toggleDataSeries: false
            },
            onItemHover: {
              highlightDataSeries: false
            },
            itemMargin: {
              horizontal: 4,
              vertical: 8
            },
            horizontalAlign: 'center'
          },
          xaxis: {
            type: 'datetime',
            categories: this.dateSeries,
            labels: {
              style: {
                colors: AXIS_TEXT_COLOR,
                fontSize: '12px'
              },
              rotate: 0
            },
            tickAmount: 3
          },
          yaxis: this.yAxisOptions
        };
      }
    },
    methods: {
      tooltipValueFormatter (value, {series, seriesIndex, dataPointIndex}) {
        let formatType = 'number';
        let hidePercents = false;
        if (this.areTwoMetrics) {
          hidePercents = series.length === 2;
          let metricIndex = seriesIndex % 2 === 0 ? 0 : 1;
          formatType = this.metric[metricIndex].type;
        } else {
          hidePercents = series.length === 1;
          formatType = this.metric[0].type;
        }

        const formattedValue = formatNumber(value, formatType, {withCurrencySymbol: true});

        const total = series.reduce((acc, s, idx) => {
          if (this.areTwoMetrics) {

            // if are from same group
            if (seriesIndex % 2 === idx % 2) {
              return acc + s[dataPointIndex];
            }
            return acc;
          }
          return acc + s[dataPointIndex];
        }, 0);
        const percent = value / total * 100;

        // do not show percent if it is NaN due to zero division "error"
        if (isNaN(percent) || hidePercents) {
          return formattedValue;
        }

        return `${formattedValue} (${percent.toFixed(0)}%)`;
      }
    }
  };
</script>

<style>
  .line-widget__plot {
    & .apexcharts-legend-marker {
      display: none;
    }

    & .apexcharts-legend-marker-wrapper,
    & .apexcharts-legend-marker--custom {
      display: flex;
      align-items: center;
    }

    & .apexcharts-legend-marker--custom {
      width: 12px;
      height: 12px;

      margin-right: 4px;

      border-radius: 50%;
    }
  }
</style>
