<template>
  <button
    class="m-button"
    :class="{
      'm-button_disabled': disabled,
      'm-button_light': light,
      'm-button_critical': critical,
      'm-button_gray': gray,
      'm-button_secondary': secondary,
      'm-button_link': link,
      'm-button_small': small,
      'm-button_large': large,
      'm-button_loading': isLoading
    }"
    :title="title"
    @click="handleClick"
  >
    <SmallSpinner
      v-show="isLoading"
      class="m-button__spinner"
      :radius="5.5"
      :stroke-width="1.5"
    />
    <m-icon
      v-if="Boolean(icon) && !iconOnTheRight"
      class="m-button__icon"
      :class="{'m-button__icon_large': large}"
      :icon="icon"
    />
    <span class="m-button__text">
      <slot />
    </span>
    <m-icon
      v-if="Boolean(icon) && iconOnTheRight"
      class="m-button__icon m-button__icon_right"
      :icon="icon"
    />
  </button>
</template>

<script>
  import {SmallSpinner} from '@marilyn/marilyn-ui';

  export default {
    name: 'MButton',
    components: {SmallSpinner},
    props: {
      critical: Boolean,
      secondary: Boolean,
      gray: Boolean,
      link: Boolean,
      light: Boolean,
      small: Boolean,
      large: Boolean,
      disabled: Boolean,
      iconOnTheRight: Boolean,
      isLoading: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: Object,
        default: null
      }
    },
    computed: {
      isActive () {
        return !this.disabled && !this.isLoading;
      }
    },
    created () {
      if (this.critical + this.secondary + this.gray > 1) {
        throw new Error('Can not set `critical`, `secondary` or `gray` props together');
      }
      if (this.small && this.large) {
        throw new Error('Can not set both `small` and `large` props');
      }
    },
    methods: {
      handleClick (e) {
        if (!this.isActive) {

          // ignore user action if button is not active
          return;
        }
        this.$emit('click', e);
      }
    }
  };
</script>

<style>
  @define-mixin button $color, $background-color, $hover-background-color,
    $active-shadow-color, $disabled-background-color, $disabled-text-color {
    color: $color;

    &:not(.m-button_disabled):not(.m-button_link) {
      background-color: $background-color;

      &:hover {
        background-color: $hover-background-color;
      }

      &:active {
        box-shadow: inset 0 2px 4px $active-shadow-color;

        & .m-button__text,
        & .m-button__icon {
          padding-top: 2px; /* cause of height of the text is less then block height on 1px */
        }
      }

      &.m-button_light {
        color: $background-color;

        background-color: var(--background-color);

        border: 1px solid $background-color;

        &:hover {
          color: $color;
          background-color: $hover-background-color;
        }
      }
    }

    &.m-button_disabled {
      color: $disabled-text-color;
      background-color: $disabled-background-color;

      &.m-button_light {
        color: $disabled-background-color;

        background-color: var(--background-color);

        border: 1px solid $disabled-background-color;
      }
    }
  }

  .m-button {
    --secondary-button-hover-color: var(--secondary-button-color-hover);

    font-family: inherit; /* stylelint-disable-line sh-waqar/declaration-use-variable */
    font-size: var(--main-font-size);
    font-weight: var(--main-font-weight);

    /* reset bootstrap styles */
    line-height: unset;

    cursor: pointer;

    /* for spinner absolute positioning */
    position: relative;
    display: inline-flex;

    align-items: center;

    box-sizing: border-box;
    height: 32px;

    padding: 0 14px;

    background-color: unset; /* stylelint-disable-line sh-waqar/declaration-use-variable */

    border: unset;

    border-radius: 5px;

    transition: var(--transition-duration) ease-in-out;

    &.m-button_disabled {
      cursor: not-allowed;
    }

    /* stylelint plugin stylelint-group-selectors does not understand mixins */

    /* stylelint-disable plugin/stylelint-group-selectors */
    &:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading) {
      @mixin button var(--background-color), var(--action-color),
        var(--action-hover-color), var(--action-active-color),
        var(--action-disabled-color), var(--background-color);
    }

    &.m-button_critical {
      @mixin button var(--background-color), var(--error-color),
        var(--error-hover-color), var(--error-active-color),
        var(--error-disabled-color), var(--background-color);
    }

    &.m-button_gray {
      @mixin button var(--background-color), var(--gray-text-color),
        var(--panel-text-color), var(--secondary-text-color), var(--border-color),
        var(--background-color);
    }

    &.m-button_secondary {
      @mixin button var(--secondary-text-color), var(--secondary-button-color),
        var(--secondary-button-hover-color), var(--secondary-button-active-color),
        var(--secondary-button-disabled-color),
        var(--secondary-button-disabled-text-color);
    }
    /* stylelint-enable */
    &.m-button_link {
      font-weight: var(--medium-font-weight);

      padding: 0;

      border: none;

      &:not(.m-button_critical):not(.m-button_secondary):not(.m-button_disabled):not(.m-button_gray) {
        color: var(--action-color);

        &:hover {
          color: var(--action-hover-color);
        }
      }

      &.m-button_critical {
        color: var(--error-color);

        &:hover {
          color: var(--error-hover-color);
        }
      }

      &.m-button_gray {
        color: var(--gray-text-color);

        &:hover {
          color: var(--panel-text-color);
        }
      }

      &.m-button_secondary {
        color: var(--secondary-button-color);

        &:hover {
          color: var(--secondary-button-hover-color);
        }
      }

      &.m-button_disabled {
        color: var(--action-disabled-color);
      }
    }

    &.m-button_small {
      font-size: var(--secondary-font-size);
      height: 28px;
    }

    &.m-button_large {
      font-size: 16px;

      height: 42px;

      padding: 0 32px;
    }

    & .m-button__icon {
      width: var(--main-line-height);
      height: var(--main-line-height);

      margin-right: 4px;
    }

    & .m-button__icon_right {
      margin-left: 4px;
      margin-right: 0;
    }

    &.m-button_loading {
      cursor: not-allowed;

      background-color: var(--gray-table-color);

      /* setting border via box-shadow, because `border` property adds pixels to button size */
      box-shadow: inset 0 0 0 1px var(--border-color);

      /* set opacity to zero for icon and text to preserve button content width */
      & .m-button__icon,
      & .m-button__text {
        opacity: 0;
      }
    }
  }

  .m-button__spinner {
    /* center spinner inside button */
    position: absolute;
    top: 50%;
    left: 50%;

    /* spinner dimensions are 14px */
    margin-top: -7px;
    margin-left: -7px;
  }
</style>
