<template>
  <div
    class="m-radiobutton"
    :class="{'m-radiobutton_disabled': disabled, 'm-radiobutton_checked': value}"
    @click="input"
  >
    <div
      key="checked"
      class="m-radiobutton__check"
    />
    <input
      :checked="value"
      type="checkbox"
      class="m-radiobutton__hidden"
      @change="input($event.target.checked)"
    >
    <label
      class="m-radiobutton__label"
    >{{ labelText }}
    </label>
    <div
      v-if="description"
      class="m-radiobutton__description"
    >
      {{ description }}
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MRadiobutton',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      labelText: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      description: {
        type: String,
        default: null
      }
    },
    methods: {
      input () {
        if (this.disabled) {
          return;
        }
        if (!this.value) {
          this.$emit('selected');
        }
      }
    }
  };

</script>
<style>
  .m-radiobutton__label {
    color: var(--text-color);

    /* deal with application.css */
    line-height: var(--main-line-height);

    padding: 0;

    margin: 0;
    margin-right: 16px;

    user-select: none;
  }

  .m-radiobutton {
    /* constants. Change from the outside */
    --empty-color: var(--background-color);
    --default-color: var(--border-color);
    --checked-color: var(--action-color);
    --disabled-color: var(--gray-color);
    --disabled-checked: var(--action-disabled-color);
    --disabled-text-color: var(--gray-text-color);

    /* variables. Shouldn't be changed outside */
    --check-border-color: var(--gray-text-color);
    --check-background-color: var(--empty-color);
    --check-border-width: 1px;

    cursor: pointer;

    display: flex;

    align-items: center;

    &:not(.m-radiobutton_checked):not(.m-radiobutton_disabled):hover {
      --check-border-color: var(--checked-color);
    }

    &.m-radiobutton_disabled {
      --check-background-color: var(--disabled-color);
      --check-border-color: var(--disabled-color);
      --text-color: var(--disabled-text-color);

      cursor: not-allowed;

      & .m-radiobutton__label {
        /* deal with application.css */
        cursor: not-allowed;
      }
    }

    &.m-radiobutton_checked {
      --check-border-width: 5px;
      --check-border-color: var(--checked-color);
    }

    &.m-radiobutton_disabled.m-radiobutton_checked {
      --check-border-color: var(--disabled-checked);
      --check-background-color: var(--background-color);
    }

    & .m-radiobutton__check {
      box-sizing: border-box;
      width: 14px;
      height: 14px;

      margin-right: 8px;

      background-color: var(--check-background-color);

      border: var(--check-border-width) solid var(--check-border-color);

      border-radius: 50%;

      user-select: none;
    }
  }

  .m-radiobutton__hidden {
    display: none;
  }
</style>
