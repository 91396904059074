<template>
  <div class="schedule-control-basic">
    <div class="schedule-control-basic__form">
      <MSelectControl
        class="schedule-control-basic__form-option"
        :label="label.date"
        :choices="dayChoices"
        :value="dayValue"
        :is-in-modal="true"
        @input="update('date', $event)"
      />

      <MSelectControl
        class="schedule-control-basic__form-option schedule-control-basic__form-interval"
        :label="label.interval"
        :choices="intervalChoices"
        :value="timeValue"
        :is-in-modal="true"
        @input="update('time', $event)"
      />

      <MSelectControl
        v-if="isCustomTime"
        class="schedule-control-basic__form-option schedule-control-basic__form-custom-time"
        :label="label.customTime"
        :choices="hourChoices"
        :value="customTimeValue"
        :is-in-modal="true"
        @input="update('customTime', $event)"
      />
    </div>
    <div class="schedule-control-basic__verbose">
      <div class="schedule-control-basic__verbose-title">
        {{ pgettext("schedule_control", "Schedule") }}
      </div>
      <div class="schedule-control-basic__verbose-text">
        {{ verboseRunType }}
      </div>
    </div>
  </div>
</template>

<script>
  import MSelectControl from 'modules/common/components/MSelectControl';
  import {
    CUSTOM,
    getFormattedHourList,
    getHoursChoices,
    getRunDayChoices, getVerboseRunScheduleFromIds
  } from 'modules/common/components/SheduleControl/utils';
  import translation from 'translation';

  export default {
    name: 'ScheduleControlBasic',
    components: {MSelectControl},
    props: {
      dayValue: {
        type: String,
        default: ''
      },
      timeValue: {
        type: String,
        default: ''
      },
      customTimeValue: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        label: {
          date: translation.tr('Date'),
          interval: translation.tr('Interval'),
          customTime: translation.tr('Time')
        },
        dayChoices: getRunDayChoices(),
        hourChoices: getFormattedHourList()
      };
    },
    computed: {
      intervalChoices () {
        return getHoursChoices({dayValue: this.dayValue});
      },
      isCustomTime () {
        return this.timeValue === CUSTOM;
      },
      verboseRunType () {
        if (this.dayValue && this.timeValue) {
          return getVerboseRunScheduleFromIds(this.dayValue, this.timeValue, this.customTimeValue);
        }
        return '';
      },
      defaultEmitData () {
        return {
          date: this.dayValue,
          time: this.timeValue,
          customTime: this.customTimeValue
        };
      }
    },
    methods: {
      update (key, value) {
        const data = this.defaultEmitData;
        data[key] = value;
        this.$emit('input', data);
      }
    }
  };
</script>

<style>
  .schedule-control-basic {
    display: flex;
  }

  .schedule-control-basic__form {
    flex: 0 0 196px;
    box-sizing: border-box;
  }

  .schedule-control-basic__form-option {
    width: 178px;
  }

  .schedule-control-basic__form-interval {
    margin-top: 22px;
  }

  .schedule-control-basic__verbose {
    font-size: 14px;

    color: var(--text-main-color);

    box-sizing: border-box;

    padding-left: 24px;
  }

  .schedule-control-basic__verbose-title {
    font-weight: var(--bold-font-weight);
    line-height: 20px;
  }

  .schedule-control-basic__verbose-text {
    line-height: 20px;
    flex-grow: 1;
  }

  .schedule-control-basic__form-custom-time {
    width: 80px;
    margin-top: 22px;
  }
</style>
