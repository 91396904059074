<template>
  <MModalWrapper ref="wrapper">
    <div
      class="m-modal__header"
      :class="{'m-modal__header_bordered': headerWithBorder}"
    >
      <slot name="modal-header" />
    </div>
    <div class="m-modal__content">
      <slot name="modal-content" />
    </div>
    <div class="m-modal__footer">
      <slot name="modal-footer">
        <m-button
          class="m-modal__button-apply"
          :disabled="applyDisabled"
          :is-loading="applyLoading"
          @click="$emit('apply')"
        >
          <slot name="apply-button">
            {{ _('Apply') }}
          </slot>
        </m-button>
        <m-button
          class="m-modal__button-cancel"
          secondary
          @click="$emit('cancel')"
        >
          <slot name="cancel-button">
            {{ _('Cancel') }}
          </slot>
        </m-button>
      </slot>
    </div>
    <div
      v-show="showSubfooter"
      ref="subfooter"
      class="m-modal__sub-footer"
    >
      <slot name="modal-subfooter" />
    </div>
  </MModalWrapper>
</template>

<script>
  import MModalWrapper from 'modules/common/components/MModal/MModalWrapper';

  /**
   * Wrapper component for modals
   * Has 3 slots + 1 toggable (show/hide)
   */
  export default {
    name: 'MModal',
    components: {MModalWrapper},
    props: {

      // flag to show subfooter (slot `modal-subfooter`)
      showSubfooter: {
        type: Boolean,
        default: false
      },

      // flag to disable apply button
      applyDisabled: {
        type: Boolean,
        default: false
      },
      applyLoading: {
        type: Boolean,
        default: false
      },
      headerWithBorder: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        bottomPadding: 0 // padding to center modal with closed subfooter and save it's position without it
      };
    },
    computed: {

      /**
       * returns value for margin-bottom
       * this allows make modal centered and not moving while toggling subfooter
       * @returns {string}
       */
      footerMargin () {
        return this.showSubfooter ? '0' : `${this.bottomPadding}px`;
      }
    },
    watch: {
      footerMargin (val) {
        this.$refs.wrapper.$refs.container.style.setProperty('margin-bottom', val);
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.calcModalOffsets();
      });
    },
    methods: {
      removeModal () {
        return this.$refs.wrapper.removeModal();
      },
      calcModalOffsets () {

        /*
         * hack to prevent modal jumps when subfooter is triggered
         * made without VUE js and reactivity - because it is easier (less code) and faster
         * no need to make multiple this.$nextTick wrappers
         */
        const elContainer = this.$refs.wrapper.$refs.container;
        const elSubfooter = this.$refs.subfooter;
        const subfooterDisplay = elSubfooter.style.display;

        // hide subfooter to get height of element without it
        elSubfooter.style.display = 'none';
        let size = elContainer.getBoundingClientRect().height;

        // show subfooter to get height of element with it
        elSubfooter.style.display = 'block';

        // count diff of height with subfooter height and without
        const fullSize = elContainer.getBoundingClientRect().height;
        size = size - fullSize;

        // do not make padding if modal is bigger than screen
        if (fullSize > window.innerHeight) {
          this.$refs.wrapper.isFullscreened = true;
          size = 0;
        }

        // return previous state of element
        elSubfooter.style.display = subfooterDisplay;

        this.bottomPadding = Math.abs(size);
      }
    }
  };
</script>

<style>
  .m-modal__header {
    font-size: 18px;
    font-weight: var(--medium-font-weight);

    color: var(--text-main-color);

    flex-shrink: 0;

    padding: 15px;
  }

  .m-modal__header_bordered {
    border-bottom: 1px solid var(--border-color);
  }

  .m-modal__content {
    overflow-y: auto;

    flex-grow: 1;
    flex-shrink: 1;

    min-height: 0;
  }

  .m-modal__footer,
  .m-modal__sub-footer {
    padding: 16px;
    border-top: 1px solid var(--border-color);
  }

  .m-modal__footer {
    flex-shrink: 0;
    padding: 16px 18px;
  }

  .m-modal__button-cancel {
    margin-left: 21px;
  }
</style>
