<template>
  <MTableCell
    class="m-table-cell-link__container m-table-column__cell_ellipsis"
  >
    <template v-if="showLink">
      <m-icon
        v-if="channelIcon"
        :icon="channelIcon"
        class="m-table-cell__channel-icon"
      />
      <MLink
        class="m-table-cell__ellipsis-wrapper"
        :href="orderUrl"
      >
        {{ tableRow[itemKey] }}
      </MLink>
    </template>
    <template v-else>
      <span>
        {{ tableRow[itemKey] }}
      </span>
    </template>
  </MTableCell>
</template>

<script>
  import appContext from 'legacy/appcontext';
  import ProjectDependentCellMixin from 'legacy/modules/common/components/MTable/cells/mixins/ProjectDependentCellMixin';
  import {mapGetters} from 'vuex';
  import {MLink, mTableCells} from '@marilyn/marilyn-ui';


  export default {
    name: 'MTableCellOrderLink',
    components: {
      MLink,
      MTableCell: mTableCells.MTableCell
    },
    mixins: [ProjectDependentCellMixin],
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      ...mapGetters('channels', ['channelIcons']),
      orderUrl () {
        const urlContext = appContext.urlContext({
          account_id: this.accountId,
          project_id: this.projectId,
          order_id: this.orderId
        });
        return appContext.url('orderDetails', urlContext);
      },
      channelIcon () {
        const channelId = this.tableRow.channel_id;
        if (!channelId) {
          return false;
        }
        return this.channelIcons[channelId];
      },
      orderId () {
        return this.tableRow.order_id;
      },
      showLink () {
        return Boolean(this.orderId) && appContext.can_user('READ_ORDER');
      }
    }
  };
</script>

<style>
  .m-table-cell__channel-icon {
    flex-shrink: 0;

    width: 12px;
    height: 12px;

    margin-right: 8px;
  }
</style>
