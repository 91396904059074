<template>
  <div>
    <OnboardingTextSection>
      Не можете найти нужные данные?<br>
      Воспользуйтесь кнопкой «Поиск по аккаунтам».<br>
      Откроется отдельная страница, где вы найдете всё,<br>что требуется.
    </OnboardingTextSection>

    <OnboardingImageSection
      width="360px"
      :image="accountSearchImage"
      align="center"
      class="onboarding-account-sidebar-tree__mascot"
    />
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';

  import accountSearchImage from 'onboarding/onboardings/SidebarNavigation/assets/account-searching.png';

  export default {
    name: 'AccountsSearchStep',
    components: {OnboardingImageSection, OnboardingTextSection},
    data () {
      return {
        accountSearchImage
      };
    }
  };
</script>

<style>
  .onboarding-account-sidebar-tree__mascot {
    padding-top: 26px;
  }
</style>
