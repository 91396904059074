<template>
  <MTableCellIcon
    :icon="circleIcon"
    :class="{
      'm-table-cell__account-status_active': isActive,
      'm-table-cell__account-status_archived': isArchived,
    }"
    :title="accountStatusText"
    class="m-table-cell__account-status"
  />
</template>

<script>
  import MTableCellIcon from 'modules/common/components/MTable/cells/MTableCellIcon';
  import circleIcon from 'assets/icons/bullet.svg';
  import {mapGetters} from 'vuex';

  export default {
    name: 'MTableCellAccountStatus',
    components: {MTableCellIcon},
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      },
      showColumn: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        circleIcon
      };
    },
    computed: {
      ...mapGetters('enums', ['getAccountStatusById']),
      isArchived () {
        return this.tableRow[this.itemKey] === 'ARCHIVED';
      },
      isActive () {
        return this.tableRow[this.itemKey] === 'ACTIVE';
      },
      accountStatusText () {
        const enumVal = this.getAccountStatusById(this.tableRow[this.itemKey]);
        if (!enumVal) {
          return '';
        }
        return enumVal.name;
      }
    }
  };
</script>

<style>
  .m-table-cell__account-status {
    --color-active: var(--success-color);
    --color-archived: var(--gray-text-color);
  }

  .m-table-cell__account-status svg {
    width: 8px;
    height: 8px;
  }

  .m-table-cell__account-status_active svg {
    color: var(--color-active);
  }

  .m-table-cell__account-status_archived svg {
    color: var(--color-archived);
  }
</style>
