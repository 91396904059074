<template>
  <div
    ref="wrapper"
    class="dynamic-table-height-wrapper"
    :style="cssVars"
  >
    <slot />
  </div>
</template>

<script>
  import createObserver from 'utils/observers/createObserver';
  import _ from 'lodash';
  _.noConflict();

  const DEFAULT_TABLE_MAX_HEIGHT = 550;
  const OBSERVER_THROTTLE_TIMER = 300;
  const PAGINATION_HEIGHT = 68;
  const SETTINGS_HEIGHT = 43;
  const PAGE_PADDING_OFFSET = 14;

  export default {
    name: 'DynamicTableHeightWrapper',
    props: {
      paginatedTable: {
        type: Boolean,
        default: false
      },
      withSettings: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        pageRemainingHeight: DEFAULT_TABLE_MAX_HEIGHT
      };
    },
    computed: {
      tableMaxHeight () {
        let size = this.pageRemainingHeight;
        if (this.paginatedTable) {
          size = size - PAGINATION_HEIGHT;
        }
        if (this.withSettings) {
          size = size - SETTINGS_HEIGHT;
        }
        if (!this.paginatedTable && !this.withSettings) {
          size = size - PAGE_PADDING_OFFSET;
        }

        return DEFAULT_TABLE_MAX_HEIGHT < size ? size : DEFAULT_TABLE_MAX_HEIGHT;
      },
      cssVars () {
        return {
          '--table-max-height': `${this.tableMaxHeight}px`
        };
      }
    },
    mounted () {
      this.calcTableMaxHeight();
      const {observerDestructor} = createObserver(
        'MutationObserver',
        document.querySelector('#page-layout-content'),
        _.throttle(this.calcTableMaxHeight, OBSERVER_THROTTLE_TIMER),
        {childList: true, subtree: true}
      );

      window.addEventListener('resize', this.calcTableMaxHeight);

      this.$on('hook:beforeDestroy', () => {
        observerDestructor();
        window.removeEventListener('resize', this.calcTableMaxHeight);
      });
    },
    methods: {
      calcTableMaxHeight () {
        if (!this.$refs.wrapper) {
          return;
        }

        const childElements = this.$refs.wrapper.children;
        if (childElements.length !== 1) {
          console.warn(`[DynamicSizeWrapper] Expecting one child element, getting [${childElements.length}]`);
        }
        const tableEl = childElements[0];

        const tableElBoundingBox = tableEl.getBoundingClientRect();

        // to make it easier we assume that table is at page bottom side
        const viewportHeight = window.innerHeight;
        const tableTopOffset = tableElBoundingBox.top + window.scrollY;
        this.pageRemainingHeight = viewportHeight - tableTopOffset;
      }
    }
  };
</script>

<style>
  .dynamic-table-height-wrapper .m-table {
    box-sizing: border-box;
    max-height: var(--table-max-height);
  }

</style>
