<template>
  <div
    class="notify-block"
    :class="classList"
  >
    <header
      v-if="isHasHeader"
      class="notify-block__header"
    >
      <slot name="header" />
    </header>

    <div class="notify-block__content-container">
      <div
        v-if="isShowIconWarn"
        class="notify-block__icon-container"
      >
        <MIcon
          :icon="iconWarn"
        />
      </div>
      <div class="notify-block__content">
        <slot />
      </div>

      <div
        v-if="closeButton"
        class="notify-block__close"
        @click="$emit('close-click')"
      >
        <MIcon
          :icon="iconCross"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import iconWarn from 'assets/icons/circle-error.svg';
  import MIcon from 'components/common/MIcon';
  import iconCross from 'assets/icons/cross.svg';

  export default {
    name: 'NotifyCard',
    components: {MIcon},
    props: {
      notyType: {
        type: String,
        default: 'default',
        options: ['default', 'warning', 'orange']
      },
      size: {
        type: String,
        default: 'default',
        options: ['small', 'default']
      },
      isFloatingCard: {
        type: Boolean,
        default: true
      },
      closeButton: {
        type: Boolean,
        default: false
      },
      isShowIconWarn: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        iconWarn,
        iconCross
      };
    },
    computed: {
      classList () {
        return {
          'notify-block_floating': this.isFloatingCard,
          'notify-block-warning': this.isWarning,
          'notify-block-orange': this.isOrange,
          'notify-block-without-icon': !this.isShowIconWarn,
          'notify-block-with-header': this.isHasHeader,
          [`notify-block-size_${this.size}`]: this.size
        };
      },
      isHasHeader () {
        return Boolean(this.$slots.header);
      },
      isWarning () {
        return this.notyType === 'warning';
      },
      isOrange () {
        return this.notyType === 'orange';
      }
    }
  };
</script>

<style>
  .notify-block {
    --padding-content: 10px;
    --padding-vertical-with-header-content: 6px;

    display: inline-flex;

    flex-direction: column;

    border-radius: 4px;

    &.notify-block_floating {
      --padding-content: 16px;
      --padding-vertical-with-header-content: 12px;
    }
  }

  .notify-block__content-container {
    display: flex;
    align-items: center;
  }

  .notify-block__header {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #FF91001A;
    height: 32px;
    padding: 0 var(--padding-content);
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #242424;
  }

  .notify-block_floating {
    box-shadow: var(--floating-card-shadow);
  }

  .notify-block__icon-container {
    padding: var(--padding-content);
  }

  .notify-block__icon-container svg {
    color: var(--gray-text-color);

    width: 26px;
    height: 26px;
  }

  .notify-block__content {
    font-size: 12px;

    color: var(--secondary-text-color);

    padding: var(--padding-content) var(--padding-content) var(--padding-content) 0;
  }

  .notify-block.notify-block-without-icon {
    .notify-block__content {
      padding: var(--padding-content);
    }
  }

  .notify-block.notify-block-with-header {
    .notify-block__content {
      padding-top: var(--padding-vertical-with-header-content);
      padding-bottom: var(--padding-vertical-with-header-content);
    }
  }

  .notify-block__content > *:not(:first-child) {
    padding-top: 4px;
  }

  .notify-block.notify-block-warning {
    background-color: var(--error-background-color);
  }

  .notify-block.notify-block-warning .notify-block__icon-container svg {
    color: var(--error-color);
  }

  .notify-block-orange {
    background-color: #FCF5E1;

    .notify-block__icon-container svg {
      color: #A8A8A8;
    }
  }

  .notify-block__close {
    color: var(--secondary-text-color);

    cursor: pointer;

    flex-shrink: 0;
    align-self: flex-start;

    width: 20px;
    height: 20px;

    margin: 6px;

    &:hover {
      color: var(--text-color);
    }
  }

  .notify-block-size_small {
    display: flex;

    align-items: center;

    padding: 12px 16px;

    .notify-block__content {
      padding: 0;
    }

    .notify-block__content {
      line-height: 16px;
    }

    .notify-block__icon-container {
      flex-shrink: 0;

      width: 16px;
      height: 16px;

      padding: 0;

      margin-right: 8px;

      svg {
        color: #E3AE24;

        width: 100%;
        height: 100%;
      }
    }
  }
</style>
