<template>
  <div>
    <OnboardingTextSection>
      В аккаунтах и проектах мое Меню отличается.<br>
      Первая вкладка поможет вам сориентироваться,<br>
      где вы находитесь.
    </OnboardingTextSection>

    <div class="onboarding-account-menu__images">
      <OnboardingImageSection
        :image="mascotSpeaking"
        width="135px"
        class="onboarding-account-menu-images__image onboarding-account-menu-images__mascot"
      />
      <OnboardingImageSection
        :image="accountTabs"
        class="onboarding-account-menu-images__image onboarding-account-menu-images__account-types"
      />
    </div>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';
  import mascotSpeaking from 'assets/mascot/mary-speaking.png';
  import accountTabs from 'onboarding/onboardings/AccountsListOnboarding/assets/account-tabs.png';

  export default {
    name: 'AccountMenuStep',
    components: {OnboardingImageSection, OnboardingTextSection},
    data () {
      return {
        mascotSpeaking,
        accountTabs
      };
    }
  };
</script>

<style>
  .onboarding-account-menu__images {
    display: flex;
    align-items: flex-end;
  }

  .onboarding-account-menu-images__image {
    display: block;
  }

  .onboarding-account-menu-images__mascot {
    flex-grow: 0;
    flex-shrink: 0;

    width: 135px;

    margin-right: 8px;
  }

  .onboarding-account-menu-images__account-types {
    flex-grow: 1;
  }

</style>
