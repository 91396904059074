<template>
  <div class="order-progress-stat">
    <div class="order-progress-stat__order-info">
      <MLink
        :icon="icon"
        :href="orderOrPeriodLink"
        class="order-progress-stat-order-link"
      >
        <div
          v-overflow-title="orderOrPeriodName"
          class="order-progress-stat-order-link__ellipsis-wrapper"
        >
          {{ orderOrPeriodName }}
        </div>
      </MLink>
      <div class="order-progress-stat-order__progress">
        <MNumeric
          :value="progressPercentage"
          type="percent"
          :allow-decimal-padding="false"
        />
      </div>
    </div>
    <div class="order-progress-stat-progress-bar">
      <div
        :style="{width: `${progressBarPercentage || 0}%`}"
        :class="`order-progress-stat-progress-bar__progress-bar_${progressStatus}`"
        class="order-progress-stat-progress-bar__fill"
      />
    </div>
  </div>
</template>

<script>
  import MLink from 'legacy/modules/common/components/MLink';
  import {mapGetters} from 'vuex';
  import MNumeric from 'legacy/modules/common/components/MNumeric/MNumeric';
  import appContext from 'legacy/appcontext';

  export default {
    name: 'ProgressStat',
    components: {MNumeric, MLink},
    props: {
      data: {
        type: Object,
        default: Object
      },
      planMetrics: {
        type: Object,
        default: Object
      },
      planMetric: {
        type: String,
        default: ''
      },
      factMetric: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'order',
        choices: ['order', 'period']
      }
    },
    computed: {
      ...mapGetters('channels', ['channelIcons']),
      orderOrPeriodLink () {
        const urlContext = appContext.urlContext();
        if (this.type === 'order') {
          return appContext.url('orderDetails', {
            account_id: urlContext.account_id || this.data.account_id,
            project_id: urlContext.project_id || this.data.project_id,
            order_id: this.data.id
          });
        }
        return appContext.url('orderPeriodDetails', {
          account_id: urlContext.account_id || this.data.account_id,
          project_id: urlContext.project_id || this.data.project_id,
          order_id: this.data.order_id,
          period_id: this.data.id
        });
      },
      icon () {
        return this.channelIcons[this.data.channel_id];
      },
      orderOrPeriodName () {
        return this.data.name;
      },
      progressPercentage () {
        return parseInt(this.progress);
      },
      progressBarPercentage () {
        if (this.progress === Infinity) {
          return 0;
        }
        return Math.min(parseInt(this.progressPercentage), 100);
      },
      progress () {
        return this.data[this.factMetric] / this.data[this.planMetric] * 100;
      },
      progressStatus () {
        const progressType = this.planMetrics[this.planMetric];
        const coef = progressType === 'absolute' ? parseFloat(this.data.progress_dates) : 100;
        if (this.progress < coef * 0.8 || this.progress > coef * 1.2) {
          return 'alert';
        } else if (this.progress < coef * 0.9 || this.progress > coef * 1.1) {
          return 'warning';
        }
        return 'ok';
      }
    }
  };
</script>

<style>
  .order-progress-stat-progress-bar {
    width: 100%;
    height: 6px;

    background: var(--panel-background-color);
  }

  .order-progress-stat-progress-bar__fill {
    width: 30%;
    height: 100%;
  }

  .order-progress-stat-progress-bar__progress-bar_ok {
    background: var(--success-color);
  }

  .order-progress-stat-progress-bar__progress-bar_warning {
    background: var(--warning-color);
  }

  .order-progress-stat-progress-bar__progress-bar_alert {
    background: var(--error-color);
  }

  .order-progress-stat__order-info {
    display: flex;
    width: 100%;
  }

  .order-progress-stat-order-link {
    font-size: 12px;

    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    display: flex;

    width: 100%;

    margin-bottom: 8px;
  }

  .order-progress-stat-order__progress {
    padding-left: 2px;
    margin-left: auto;
  }

  .order-progress-stat-order-link__ellipsis-wrapper {
    text-overflow: ellipsis;

    overflow: hidden;

    min-width: 0;
  }

  .order-progress-stat-order-link svg {
    flex-grow: 0;
    flex-shrink: 0;

    margin-right: 6px;
  }
</style>
