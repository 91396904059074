<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    class="line-widget"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="lineWidgetName"
        :metrics="lineWidgetMetric"
        :profiles="profiles"
        :external-href="externalHref"
        @click="$emit('cancel')"
      />
    </template>
    <template>
      <div
        v-if="data.items.length > 0"
        ref="plotContainer"
        class="line-widget__plot"
      >
        <LineWidgetChart
          :statistics="data.items"
          :metric="lineWidgetMetric"
          :grouping="dsParams.grouping"
          :metrics-data="metricsData"
          :show-markers="showMarkers"
          :date-start="dateStart"
          :date-end="dateEnd"
          :color-getter="colorGetter"
          height="240px"
        />
      </div>
      <CardNoDataText
        v-else
      />
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import LineWidgetChart from 'components/Dashboards/Widgets/LineWidget/LineWidgetChart';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';
  import makeSimpleMetrics from 'legacy/modules/common/components/MetricSelect/makeSimpleMetrics';
  import {parseDate} from 'utils/Date/dateUtils';
  import parseMetric from 'utils/webanalytics/parseMetric';

  export default {
    name: 'LineWidget',
    components: {
      CardNoDataText,
      LineWidgetChart,
      DashboardCard,
      WidgetHeader
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      metrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: null
      },
      colorGetter: {
        type: Object,
        required: true
      }
    },
    computed: {
      lineWidgetMetric () {
        return this.argumentsValues.metrics;
      },
      dateStart () {
        return this.dsParams.dateStart;
      },
      dateEnd () {
        return this.dsParams.dateEnd;
      },
      areTwoMetrics () {
        return this.lineWidgetMetric && this.lineWidgetMetric.length === 2;
      },
      lineWidgetName () {
        let name;
        if (this.argumentsValues.name) {
          name = this.argumentsValues.name;
        } else if (this.areTwoMetrics) {
          name = this.lineWidgetMetric.map(m => this.simpleMetrics.find(el => el.id === m).name).
            join(' & ');
          this.$emit('input', {id: 'name', value: name});
        } else {
          name = this.simpleMetrics.find(el => el.id === this.lineWidgetMetric[0]).name;
          this.$emit('input', {id: 'name', value: name});
        }

        return name;
      },
      simpleMetrics () {
        return makeSimpleMetrics(Object.values(this.metrics));
      },
      metricsData () {
        return this.lineWidgetMetric.reduce((acc, metric) => {
          const parsed = parseMetric(metric);
          acc[metric] = {
            type: this.simpleMetrics.find(el => el.id === parsed.simpleMetricId).type,
            name: this.simpleMetrics.find(el => el.id === parsed.simpleMetricId).name
          };
          return acc;
        }, {});
      },
      showMarkers () {
        return parseDate(this.dsParams.dateStart).getTime() === parseDate(this.dsParams.dateEnd).getTime();
      }
    }
  };
</script>

<style>
  .line-widget__plot {
    overflow: hidden;
    padding: 0 5px;
  }

  .line-widget__plot .apexcharts-yaxis {
    left: 10px;
  }
</style>
