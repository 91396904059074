<template>
  <div
    :class="{'m-table-cell-clickable-wrapper': enabled}"
  >
    <slot />
  </div>
</template>

<script>

  export default {
    name: 'MTableCellClickableWrapper',
    props: {
      enabled: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style>
  .m-table-cell-clickable-wrapper {
    cursor: pointer;

    position: relative;
    display: inline-flex;
    top: -2px;
    left: -6px;

    justify-content: center;
    align-items: center;

    padding: 2px 6px;

    border-radius: 2px;
  }

  .m-table-cell-clickable-wrapper:hover {
    background: var(--action-border-color);
  }

  .m-table__row_disabled .m-table-cell-clickable-wrapper:hover {
    background: var(--border-color);
  }
</style>
