<template>
  <BasePopover
    v-model="open"
    class="m-help"
    :popper-class="['m-help__popper', popperClass]"
    :placement="placement"
    :options="popperOptions"
    :visible-arrow="visibleArrow"
    :show-on="showOn"
    offset="0,5"
  >
    <template slot="popper">
      <div
        class="m-help__content"
        :style="{ width }"
      >
        <slot />
      </div>
    </template>
    <template slot="reference">
      <m-icon
        v-bind="$attrs"
        class="m-help__icon"
        :class="{ 'm-help__icon_active': open }"
        :icon="helpIcon"
      />
    </template>
  </BasePopover>
</template>

<script>
  import {BasePopover} from '@marilyn/marilyn-ui';
  import helpIcon from 'assets/icons/help.svg';

  export default {
    name: 'MHelp',
    components: {BasePopover},
    inheritAttrs: false,
    props: {
      placement: {
        type: BasePopover.props.placement.type,
        required: BasePopover.props.placement.required,
        default: BasePopover.props.placement.default
      },
      width: {
        type: String,
        required: true
      },
      popperClass: {
        type: BasePopover.props.popperClass.type,
        default: BasePopover.props.popperClass.default
      },
      popperOptions: {
        type: Object,
        default: Object
      },
      visibleArrow: {
        type: Boolean,
        default: true
      },
      showOn: {
        type: BasePopover.props.showOn.type,
        default: BasePopover.props.showOn.default,
        choices: BasePopover.props.showOn.choices
      }
    },
    data () {
      return {
        open: false,
        helpIcon
      };
    }
  };
</script>

<style>
  .m-help {
    cursor: pointer;

    width: 14px;
    height: 14px;
  }

  .m-help__popper {
    box-shadow: 0 0 10px rgba(134, 134, 134, 0.25);
  }

  .m-help__content {
    font-size: 12px;

    color: var(--secondary-text-color);

    box-sizing: border-box;

    padding: 12px;
  }

  .m-help__icon {
    color: var(--panel-text-color);

    width: 14px;
    height: 14px;
  }

  .m-help__icon:hover,
  .m-help__icon_active {
    color: var(--action-color);
  }
</style>
