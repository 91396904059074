<template>
  <MButton
    :icon="iconRefresh"
    :style="style"
    class="refresh-button"
    :class="{'refresh-button_loading': isLoading}"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
  import iconRefresh from 'assets/icons/refresh.svg';
  import {MButton} from '@marilyn/marilyn-ui';

  export default {
    name: 'RefreshButton',
    components: {MButton},
    props: {
      iconSize: {
        type: Number,
        default: undefined
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        iconRefresh
      };
    },
    computed: {
      style () {
        return {'--icon-size': this.iconSize ? `${this.iconSize}px` : 'var(--main-line-height)'};
      }
    }
  };
</script>

<style>
  .refresh-button .m-button__icon {
    width: var(--icon-size);
    height: var(--icon-size);
  }

  .refresh-button_loading .m-button__icon {
    animation: refresh-button-rotating 1000ms linear infinite;
  }

  @keyframes refresh-button-rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-360deg);
    }
  }
</style>
