:root {
  /* Shadows and borders */
  --button-box-shadow: 0 2px 8px rgba(72, 72, 72, 0.25);
  --button-hover-box-shadow: 0 2px 8px rgba(39, 39, 39, 0.3);
  --box-shadow: 0px 2px 12px rgba(103, 103, 103, 0.25);
  --dropdown-box-shadow: 0px 2px 8px rgba(163, 163, 163, 0.25);
  --border-radius: 5px;

  /* Transitions */
  --transition-duration: 150ms;
  --transition-hover-translate-duration: 300ms;

  /* Fonts */
  --main-font-family: 'Roboto', sans-serif;
  --main-font-weight: 400;
  --medium-font-weight: 500;
  --bold-font-weight: 700;
  --big-number-font-size: 40px;
  --big-number-line-height: 47px;
  --title-font-size: 18px;
  --title-line-height: 21px;
  --subtitle-font-size: 16px;
  --subtitle-line-height: 19px;
  --main-font-size: 14px;
  --main-line-height: 16px;
  --secondary-font-size: 12px;
  --secondary-line-height: 14px;

  /* Icons */
  --main-icon-size: 18px;
  --small-icon-size: 14px;
  --tiny-icon-size: 6px;
  --icon-border-radius: 2px;
  --icon-margin: 6px;
  --label-offset: 8px;

  /* Sidebar */
  --sidebar-line-height: 28px;
  --sidebar-left-padding: 20px;

  /* Textarea */
  --textarea-inside-text-padding: 8px;

  /* Calendar */
  --calendar-line-height: 24px;

  /* Time Control */
  --time-control-width: 276px;
}
