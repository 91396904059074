<template>
  <button
    class="title-desc-block-button"
    :class="{
      '_active': active
    }"
    @click="$emit('pick-goal')"
  >
    <span class="title-desc-block-button__container">
      <span class="title-desc-block-button__title">
        {{ title }}
      </span>

      <span class="title-desc-block-button__description">
        {{ description }}
      </span>
    </span>
  </button>
</template>

<script>
  export default {
    name: 'TitleDescBlockButton',
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        default: null
      },
      active: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style>
  .title-desc-block-button {
    font-family: inherit;

    cursor: pointer;

    height: 100%;

    padding: 15px;

    background-color: #FFFFFF;

    border: unset;
    border: 1px solid transparent;

    border-radius: 5px;

    outline: none;

    transition: all 0.2s;

    &:not(._active) {
      box-shadow: 0 2px 8px 0 #61616140;
    }

    &:hover {
      box-shadow: 0 2px 30px 0 #61616140;
    }

    &._active {
      background-color: #EBF4FD;
      border: 1px solid #5585C1;
    }
  }

  .title-desc-block-button__container {
    text-align: left;

    display: flex;

    flex-direction: column;
    align-items: flex-start;
  }

  .title-desc-block-button__title {
    font-size: 14px;
    font-weight: 500;

    color: #242424;
    line-height: 16px;

    margin-bottom: 4px;
  }

  .title-desc-block-button__description {
    font-size: 12px;
    font-weight: 400;

    color: #696969;
    line-height: 14px;
  }
</style>
