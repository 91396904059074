<template>
  <div
    class="m-notification"
    :class="{
      'm-notification_success': isSuccess,
      'm-notification_warning': isWarning,
      'm-notification_error': isError,
    }"
    @click.stop="handleRootClick"
  >
    <div
      v-if="closeByButton"
      class="m-notification__close"
      @click="closeNotification"
    >
      <MIcon
        :icon="iconCross"
      />
    </div>
    <div class="m-notification__status-bar" />
    <div class="m-notification__contents">
      <div class="m-notification__header">
        {{ header }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        ref="text"
        v-tooltip="getTextTooltipOptions"
        class="m-notification__text"
        v-html="text"
      />
      <!-- eslint-enable -->
      <div
        v-if="showLink"
        class="m-notification__link"
      >
        <MLink
          :href="linkUrl"
          :icon="iconArrow"
          icon-position="after"
          target="_blank"
          @click="linkAction"
        >
          {{ linkText }}
        </MLink>
      </div>
    </div>
  </div>
</template>

<script>
  import iconCross from 'assets/icons/cross.svg';
  import iconArrow from 'assets/icons/arrow.svg';
  import MIcon from 'legacy/modules/common/components/MIcon';
  import MLink from 'legacy/modules/common/components/MLink';

  export default {
    name: 'MNotification',
    components: {MIcon, MLink},
    props: {
      type: {
        type: String,
        choices: ['success', 'warning', 'error'],
        default: 'success'
      },
      timeout: {
        type: [Number, Boolean],
        default: false
      },
      canCloseNotificationBeforeClick: {
        type: Boolean,
        default: true
      },
      text: {
        type: String,
        default: ''
      },
      header: {
        type: String,
        default: ''
      },
      linkAction: {
        type: Function,
        default: () => null
      },
      onClose: {
        type: Function,
        default: () => null
      },
      linkUrl: {
        type: String,
        default: ''
      },
      linkText: {
        type: String,
        default: ''
      },
      closeByButton: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        iconCross,
        iconArrow,
        timeoutId: null,
        isTextClamped: false
      };
    },
    computed: {
      getTextTooltipOptions () {
        if (!this.isTextClamped) {
          return null;
        }

        return {
          content: this.text,
          classes: 'm-notification__text-tooltip',
          placement: 'top'
        };
      },
      showLink () {
        return (this.linkUrl || this.linkAction) && this.linkText;
      },
      isSuccess () {
        return this.type === 'success';
      },
      isWarning () {
        return this.type === 'warning';
      },
      isError () {
        return this.type === 'error';
      }
    },
    mounted () {
      if (this.timeout) {
        this.timeoutId = setTimeout(() => this.closeNotification(), this.timeout);
      }

      this.checkTextClamped();
    },
    updated () {
      this.checkTextClamped();
    },
    destroyed () {
      clearTimeout(this.timeoutId);
    },
    methods: {
      checkTextClamped () {
        const element = this.$refs.text;
        if (element) {
          this.isTextClamped = element.scrollHeight > element.clientHeight;
        }
      },
      handleRootClick () {
        if (!this.canCloseNotificationBeforeClick) {
          return;
        }

        if (!this.closeByButton) {
          this.closeNotification();
        }
      },
      closeNotification () {
        this.onClose();
        clearTimeout(this.timeoutId);
        this.$emit('closeNoty');
      }
    }
  };
</script>

<style>
  .m-notification {
    overflow: hidden;
    cursor: default;

    position: relative;
    display: inline-flex;

    align-items: stretch;

    width: 100%;

    background-color: var(--background-color);

    box-shadow: 0 2px 12px rgba(71, 71, 71, 0.1);

    border-radius: 5px;
  }

  .m-notification_success {
    --notification-color: var(--success-color);
  }

  .m-notification_warning {
    --notification-color: var(--warning-color);
  }

  .m-notification_error {
    --notification-color: var(--error-color);
  }

  .m-notification__close {
    color: var(--secondary-text-color);

    cursor: pointer;

    position: absolute;
    top: 6px;
    right: 6px;

    width: 14px;
    height: 14px;
  }

  .m-notification__close:hover {
    color: var(--text-color);
  }

  .m-notification__status-bar {
    flex-grow: 0;
    flex-shrink: 0;

    width: 4px;

    background: var(--notification-color);
  }

  .m-notification__contents {
    padding: 14px;
  }

  .m-notification__header {
    font-size: var(--subtitle-font-size);
    color: var(--notification-color);
  }

  .m-notification__text {
    font-size: var(--main-font-size);

    line-height: 18px;
    text-overflow: ellipsis;

    overflow: hidden;

    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */

    margin-top: 4px;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 17;
    line-clamp: 17;
  }

  .m-notification__link {
    font-size: var(--main-font-size);
    margin-top: 8px;
  }

  .m-notification__link .m-link {
    line-height: var(--main-line-height);
  }

  .m-notification__text-tooltip {
    max-width: 60%;
  }

</style>
