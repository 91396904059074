<template>
  <div class="statistics-plot-container floating-card">
    <SpinnerContainer
      :is-loading="isLoading"
    >
      <StatisticsPlotMenu
        v-model="filteredPlotMenuValues"
        :choices="plotMetricsChoices"
        class="statistics-plot-container__menu"
      />
      <div
        v-if="showWarning"
        class="statistics-plot-container__warning"
      >
        {{ warningText }}
      </div>

      <StatisticsPlotChart
        v-else-if="!isLoading && hasStatistics"
        :statistics="statistics"
        :grouping="grouping"
        :date-grouping="dateGrouping"
        :start-date="startDate"
        :end-date="endDate"
        :metrics="filteredPlotMenuValues"
        :metric-choices="plotMetricsChoices"
        class="statistics-plot-container__chart"
      />

      <div
        v-else
        class="statistics-plot-container__dummy"
      />
    </SpinnerContainer>
  </div>
</template>

<script>
  import {SpinnerContainer} from '@marilyn/marilyn-ui';
  import StatisticsPlotChart from 'pages/seller/statistics/components/StatisticsPlotChart';
  import {mapGetters} from 'vuex';
  import StatisticsPlotMenu from 'pages/seller/statistics/components/StatisticsPlotMenu';

  export default {
    name: 'StatisticsPlot',
    components: {StatisticsPlotMenu, SpinnerContainer, StatisticsPlotChart},
    props: {
      plotMetrics: {
        type: Object,
        default: Object
      },
      plotMetricsChoices: {
        type: Array,
        default: Array
      },
      data: {
        type: Array,
        default: Array
      },
      grouping: {
        type: String,
        default: ''
      },
      dateGrouping: {
        type: String,
        default: ''
      },
      startDate: {
        type: Date,
        default: null
      },
      endDate: {
        type: Date,
        default: null
      },
      maxGrouping: {
        type: Number,
        default: 6
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('channels', ['getChannelById']),
      warningText () {
        if (this.tooManyGroups) {
          // eslint-disable-next-line prefer-template
          return this._('Too many elements for selected grouping.') + ' ' +
            this._('Please select no more than {0} in filterings.').format(this.maxGrouping);
        }
        if (this.noDateGrouping) {
          return this._('To display plot please select grouping by date.');
        }
        if (!this.hasStatistics) {
          return this._('No data available for current period');
        }
        return null;
      },
      showWarning () {
        return Boolean(this.warningText);
      },

      hasStatistics () {
        /*
         * if data items is zero or row data is null or every data element has order_name: null ->
         * we have no data (detailed statistics returns one row)
         */
        return this.data.length > 0 && Boolean(this.data[0].date) &&
          !this.statistics.every(el => el.order_name === null);
      },

      statistics () {
        if (this.grouping === 'channel') {
          return this.data.map(row => ({
            ...row,
            channel_name: row.channel_id ? this.getChannelById(row.channel_id).display_name : ''
          }));
        }
        return this.data.filter(el => el.order_name !== null);
      },

      // filtered values that are in available columns
      filteredPlotMenuValues: {
        get () {

          // return value if it is available or return default ('no')
          const getValueOrDefault = (key) => this.plotMetricsChoices.find(i => i.itemKey === key) ? key : 'no';

          return {
            left: getValueOrDefault(this.plotMetrics.left),
            right: getValueOrDefault(this.plotMetrics.right)
          };
        },
        set (val) {
          this.$emit('setPlotMetrics', val);
        }
      },
      hasItems () {
        return this.data.length > 0;
      },
      groups () {
        return Array.from(new Set(this.data.map(row => row[`${this.grouping}_id`])));
      },
      tooManyGroups () {
        return this.groups.length > this.maxGrouping;
      },
      noDateGrouping () {
        return this.dateGrouping === 'no';
      }
    }
  };
</script>

<style>
  .statistics-plot-container {
    padding: 16px 20px;
    margin-bottom: 16px;
  }

  .statistics-plot-container__warning {
    font-size: 18px;

    color: var(--panel-text-color);

    display: flex;

    justify-content: center;
    align-items: center;

    margin-top: 32px;
    margin-bottom: 16px;
  }

  .statistics-plot-container__chart,
  .statistics-plot-container__dummy {
    height: 300px;
  }
</style>
