<template>
  <a
    :href="link"
    target="_blank"
    class="client-case-item"
  >
    <div class="client-case-item__container">
      <div class="client-case-item__info-content">
        <h5 class="client-case-item__title">
          {{ title }}
        </h5>
        <span class="client-case-item__description">
          {{ description }}
        </span>
      </div>

      <div class="client-case-item__logo-content">
        <MProviderIcon
          class="client-case-item__logo"
          :class="{
            [`_${icon.size}-size`]: icon.size
          }"
          :icon="icon"
        />
      </div>
    </div>
  </a>
</template>

<script>
  import {MProviderIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'ClientCaseItem',
    components: {
      MProviderIcon
    },
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      link: {
        type: String,
        required: true
      },
      icon: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style>
  .client-case-item {
    cursor: pointer;

    width: 100%;

    padding: 24px;

    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 #A3A3A340;

    border-radius: 4px;

    transition: all 0.2s;

    &:hover {
      box-shadow: -5px 2px 8px 0 #A3A3A340;
      opacity: 0.75;
    }
  }

  .client-case-item__container {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }

  .client-case-item__info-content {
    display: flex;
    flex-direction: column;
  }

  .client-case-item__title {
    font-size: 16px;
    font-weight: 500;

    color: #242424;
    line-height: 18px;

    letter-spacing: -0.03em;

    margin: 0 0 8px;
  }

  .client-case-item__description {
    font-size: 12px;
    font-weight: 400;

    color: #696969;
    line-height: 16px;

    letter-spacing: -0.03em;
  }

  .client-case-item__logo {
    height: 40px;

    &._half-size {
      height: 20px;
    }

    img {
      height: 100%;
    }
  }
</style>
