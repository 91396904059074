<template>
  <div
    class="progress-widget-table"
    :class="{'progress-widget-table_with-month': showMonth}"
  >
    <div />
    <div v-if="showMonth" />
    <div class="progress-widget-table__header">
      {{ _("Plan") }}
    </div>
    <div class="progress-widget-table__header">
      {{ _("Fact") }}
    </div>
    <template v-for="row in filteredDataWithId">
      <div
        :key="`${row.id}-progress`"
        class="progress-widget-table__cell progress-widget-table__order-progress"
      >
        <ProgressStat
          :data="row"
          :plan-metrics="planMetrics"
          :plan-metric="planMetric"
          :fact-metric="factMetric"
          :type="type"
        />
      </div>
      <div
        v-if="showMonth"
        :key="`${row.id}-month`"
        class="progress-widget-table__cell progress-widget-table__month"
      >
        <span
          v-tooltip.top="getTooltip(row)"
        >
          {{ row.start_date | monthVerbose }}
        </span>
      </div>
      <div
        :key="`${row.id}-plan`"
        class="progress-widget-table__cell progress-widget-table__metric-plan"
      >
        <MNumeric
          :value="row[planMetric]"
          :type="metricType"
          :show-currency-symbol="false"
        />
      </div>
      <div
        :key="`${row.id}-fact`"
        class="progress-widget-table__cell progress-widget-table__metric-fact"
      >
        <MNumeric
          :value="row[factMetric]"
          :type="metricType"
          :show-currency-symbol="false"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import MNumeric from 'legacy/modules/common/components/MNumeric/MNumeric';
  import ProgressStat from 'components/Dashboards/Widgets/ProgressWidget/ProgressStat';
  import moment from 'moment-wrapper';
  import translation from 'translation';

  export default {
    name: 'ProgressWidgetTable',
    components: {ProgressStat, MNumeric},
    filters: {
      monthVerbose (date) {
        return moment(date).format('MMMM');
      }
    },
    props: {
      data: {
        type: Array,
        default: Array
      },
      planMetrics: {
        type: Object,
        default: Object
      },
      planMetric: {
        type: String,
        default: ''
      },
      factMetric: {
        type: String,
        default: ''
      },
      metricType: {
        type: String,
        default: 'integer'
      },
      type: {
        type: String,
        default: 'order',
        choices: ['order', 'period']
      }
    },
    computed: {
      showMonth () {
        return this.type === 'period';
      },
      filteredDataWithId () {
        return this.data.filter(el => Boolean(el.id));
      }
    },
    methods: {
      getTooltip (row) {
        const startFormatted = moment(row.start_date).formatDateShort();
        const endFormatted = moment(row.end_date).formatDateShort();
        const translatedFrom = translation.trc('PROGRESS_WIDGET', 'from');
        const translatedTo = translation.trc('PROGRESS_WIDGET', 'to');

        return {
          content: `${translatedFrom} ${startFormatted} ${translatedTo} ${endFormatted}`,
          trigger: 'hover'
        };
      }
    }
  };
</script>

<style>
  .progress-widget-table {
    overflow: hidden;

    display: grid;

    grid-template-columns: minmax(auto, 2fr) minmax(min-content, 1fr) minmax(min-content, 1fr);

    max-height: 221px;

    padding: 0 20px 0 15px;

    margin-top: -4px;
  }

  .progress-widget-table_with-month {
    grid-template-columns: minmax(auto, 2fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr);
  }

  .progress-widget-table__metric-plan,
  .progress-widget-table__metric-fact,
  .progress-widget-table__month {
    display: flex;

    justify-content: flex-end;
    align-items: flex-end;
  }

  .progress-widget-table__month {
    color: var(--secondary-text-color);
  }

  .progress-widget-table__cell {
    min-width: 0;
    min-height: 0;

    padding: 14px 5px;
  }

  .progress-widget-table__header {
    font-weight: var(--medium-font-weight);

    position: relative;
    display: flex;
    top: 18px;

    justify-content: flex-end;
    align-items: flex-end;

    padding: 0 5px;

    margin-top: -20px;
  }
</style>
