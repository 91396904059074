<template>
  <div class="promo-banner">
    <h3 class="promo-banner__title">
      Управляйте рекламой с биддером Точка Аналитики
    </h3>
    <p class="promo-banner__text">
      Вместе с биддером вы получите анализ карточек и конкурентов, генератор
      SEO-ключей, поиск лучшей ниши и другие удобные инструменты для селлера
    </p>
    <button
      class="promo-banner__button"
      @click="handleTryFree"
    >
      Попробовать бесплатно
    </button>
  </div>
</template>

<script>
  export default {
    name: 'PromoBanner',
    methods: {
      handleTryFree () {
        // Add your action for the free trial button here
        this.$emit('try-free-clicked');
      }
    }
  };
</script>

<style>
  .promo-banner {
    padding: 15px 20px;

    background: var(--Marketing-Amaranth-2-Fixed, #F6D1E9);

    border-radius: 10px;
  }

  .promo-banner__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;

    color: var(--Primitive-Primary-Fixed, #191919);
    line-height: 20px; /* 125% */

    letter-spacing: 0.16px;

    margin: 0 0 5px;
  }

  .promo-banner__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    color: var(--Primitive-Primary-Fixed, #191919);
    line-height: 18px; /* 128.571% */

    letter-spacing: 0.14px;

    max-width: 68ch;

    margin: 0;
  }

  .promo-banner__button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    color: var(--Primitive-Default-Fixed, #FFFFFF);
    line-height: 20px; /* 125% */

    text-align: center;

    letter-spacing: 0.16px;

    cursor: pointer;

    padding: 6px 15px;

    margin: 15px 0 0;

    background: var(--Marketing-Amaranth-6-Fixed, #B44292);

    border: none;

    border-radius: 10px;
  }
</style>
