<template>
  <div
    class="autostrategy-input"
    :class="classList"
  >
    <div class="autostrategy-input__container">
      <div class="autostrategy-input__label">
        <span class="autostrategy-input__label-text">
          {{ inputItem.name }}
        </span>

        <MHelp
          v-if="inputItem.hintText"
          width="224px"
          placement="right"
          show-on="hover"
          class="autostrategy-input__hint"
        >
          {{ inputItem.hintText }}
        </MHelp>
      </div>

      <div class="autostrategy-input__input">
        <MNumericInput
          v-if="controlType === 'numeric'"
          :value="value"
          :vat="vatIfNeeded"
          :type="inputType"
          :min-with-vat="inputItem.minWithVat"
          allow-clear-input
          :minimum-value="customMinValue"
          :maximum-value="customMaxValue"
          :placeholder="inputItem.placeholder"
          @input="handleInput"
        />
        <MSelectControl
          v-if="controlType === 'select'"
          :choices="selectChoices"
          :value="value"
          id-attr="id"
          text-attr="name"
          allow-width-overflow
          is-in-modal
          :placeholder="inputItem.placeholder"
          @input="handleInput"
        />
        <MInput
          v-if="controlType === 'string'"
          :value="value"
          :placeholder="inputItem.placeholder"
          @input="handleInput"
        />
      </div>
    </div>

    <NotifyCard
      v-if="inputItem.notifyText"
      noty-type="orange"
      size="small"
      :is-floating-card="false"
      class="autostrategy-input__notify"
    >
      {{ inputItem.notifyText }}
    </NotifyCard>
  </div>
</template>

<script>
  import MHelp from 'modules/common/components/MHelp';
  import MNumericInput from 'modules/common/components/MNumericInput/MNumericInput.vue';
  import {MSelectControl} from '@marilyn/marilyn-ui';
  import NotifyCard from 'components/common/NotifyCard.vue';
  import {mapGetters} from 'vuex';

  const SELECT_TYPES = ['time', 'choices'];

  export default {
    name: 'SellerAutostrategyInput',
    components: {
      NotifyCard,
      MNumericInput,
      MHelp,
      MSelectControl
    },
    props: {
      inputItem: {
        type: Object,
        required: true
      },
      value: {
        type: [Number, String],
        default: null
      }
    },
    computed: {
      ...mapGetters('sellerUser', {
        vat: 'vatDivider'
      }),
      vatIfNeeded () {
        if (this.inputType === 'currency') {
          return this.vat;
        }
        return false;
      },
      inputType () {
        return this.inputItem.type;
      },
      controlType () {
        if (this.inputType === 'string') {
          return 'string';
        } else if (SELECT_TYPES.includes(this.inputType)) {
          return 'select';
        }
        return 'numeric';
      },
      selectChoices () {
        if (this.inputType === 'time') {
          return Array.from(Array(24)).map((i, x) => x).
            map(i => ({
              id: i,
              name: `${i.toString().padStart(2, '0')}:00`
            }));
        } else if (this.inputType === 'choices') {
          return this.inputItem.args.choices;
        }
        return [];
      },
      customMinValue () {
        return this.inputItem.args?.min ?? null;
      },
      customMaxValue () {
        return this.inputItem.args?.max ?? null;
      },
      classList () {
        const classList = [];

        if (this.inputItem.halfSizeContainer) {
          classList.push('_half-size');
        } else {
          classList.push('_full-size');
        }

        return classList;
      }
    },
    methods: {
      handleInput (newValue) {
        this.$emit('change-value', {
          keyValue: this.inputItem.id,
          newValue
        });
      }
    }
  };
</script>

<style>
  .autostrategy-input {
    display: flex;

    justify-content: space-between;
    align-items: center;

    height: 100%;

    &._full-size {
      .autostrategy-input__container {
        width: calc(50% - (var(--items-input-gap) / 2));
      }
    }

    &._half-size {
      .autostrategy-input__container {
        width: 100%;
      }
    }
  }

  .autostrategy-input__container {
    display: flex;

    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;

    height: 100%;
  }

  .autostrategy-input__label {
    display: flex;

    align-items: center;

    margin-bottom: 8px;
  }

  .autostrategy-input__label-text {
    font-size: 14px;
    font-weight: 500;

    line-height: 16px;
  }

  .autostrategy-input__hint {
    margin-left: 4px;
  }

  .autostrategy-input__notify {
    height: 100%;

    margin-left: var(--items-input-gap);

    border-radius: 8px;
  }
</style>
