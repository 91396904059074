<template>
  <div>
    <OnboardingTextSection>
      <b>Привязка</b> — это аккаунт рекламной площадки,<br>
      привязанный к Marilyn. Если вы хотите добавить<br>
      размещения, а нужной привязки нет, ее можно<br>
      создать прямо отсюда, после клика на кнопку<br>
      «Добавить».
    </OnboardingTextSection>

    <OnboardingTextSection>
      <b>Мой совет:</b> удобнее сначала создать все нужные<br>
      привязки, а после добавлять из них размещения.
    </OnboardingTextSection>

    <OnboardingImageSection
      width="138px"
      :image="pointUpImage"
      align="center"
      class="onboarding-create-placement"
    />
  </div>
</template>

<script>
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import pointUpImage from 'assets/mascot/mary-is-pointing.png';


  export default {
    name: 'PlacementCreateStep',
    components: {OnboardingImageSection, OnboardingTextSection},
    data () {
      return {
        pointUpImage
      };
    }
  };
</script>

<style>
  .onboarding-create-placement__mascot {
    padding-top: 26px;
  }
</style>
