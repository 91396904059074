<template>
  <div>
    <OnboardingTextSection>
      В Marilyn есть 2 структурных элемента — аккаунты и<br> проекты.
    </OnboardingTextSection>

    <OnboardingTextSection>
      <b>Аккаунты</b> — нужны, чтобы гибко настраивать<br>
      доступы для команды и клиентов. Обычно аккаунту<br>
      соответствует название Компании/Бренда/Клиента<br>
      агентства. В боковой панели аккаунты<br> обозначаются стрелочкой
      <MIcon
        class="onboarding-sidebar-structure__icon-bullet-arrow"
        :icon="bulletArrow"
      />
    </OnboardingTextSection>

    <OnboardingTextSection>
      <b>Проекты</b> — нужны для группировки и массового<br>
      управления рекламными кампаниями. Они<br>
      помогают выделить удобные группы по типу<br>
      рекламы, региону и т.д. в рамках каждого бренда<br>
      (аккаунта). В боковой панели проекты<br>
      обозначаются точечкой
      <MIcon
        class="onboarding-sidebar-structure__icon-bullet"
        :icon="bullet"
      />
    </OnboardingTextSection>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import {MIcon} from '@marilyn/marilyn-ui';

  import bulletArrow from 'onboarding/onboardings/SidebarNavigation/assets/right-arrow-hint.svg';
  import bullet from 'assets/icons/bullet.svg';

  export default {
    name: 'SidebarStructureStep',
    components: {
      OnboardingTextSection,
      MIcon
    },
    data () {
      return {
        bulletArrow,
        bullet
      };
    }
  };
</script>

<style>
  .onboarding-sidebar-structure__icon-bullet,
  .onboarding-sidebar-structure__icon-bullet-arrow {
    color: var(--panel-text-color);
  }

  .onboarding-sidebar-structure__icon-bullet-arrow.m-icon {
    top: 1px;

    width: 10px;
    height: 10px;
  }

  .onboarding-sidebar-structure__icon-bullet.m-icon {
    top: -2px;
    left: 2px;

    width: 5px;
    height: 5px;
  }
</style>
