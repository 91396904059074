<template>
  <OnboardingTextSection
    :class="{
      'onboarding-image-section__center': alignCenter
    }"
    class="onboarding-image-section"
  >
    <img
      :src="image"
      :width="width"
      class="onboarding-image-section__image"
    >
  </OnboardingTextSection>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  export default {
    name: 'OnboardingImageSection',
    components: {OnboardingTextSection},
    props: {
      image: {
        type: String,
        required: true
      },
      width: {
        type: String,
        default: 'auto'
      },
      align: {
        type: String,
        default: 'none',
        choices: ['none', 'center']
      }
    },
    computed: {
      alignCenter () {
        return this.align === 'center';
      }
    }
  };
</script>

<style>
  .onboarding-image-section {
    display: flex;
  }

  .onboarding-image-section__center {
    justify-content: center;
  }
</style>
