<template>
  <div>
    <OnboardingTextSection>
      <b>Размещения</b> — это рекламные кампании, которые<br>
      вы ко мне подключили. В системе они находятся<br>
      <b>внутри проекта</b> во вкладке «Размещения».
    </OnboardingTextSection>

    <OnboardingImageSection
      width="138px"
      :image="ezImage"
      align="center"
      class="onboarding-placements-tab__mascot"
    />
  </div>
</template>

<script>
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import ezImage from 'assets/mascot/mary-it-is-easy.png';


  export default {
    name: 'PlacementsTabStep',
    components: {OnboardingImageSection, OnboardingTextSection},
    data () {
      return {
        ezImage
      };
    }
  };
</script>

<style>
  .onboarding-placements-tab__mascot {
    padding-top: 26px;
  }
</style>
