<template>
  <v-tour
    :name="name"
    class="tour-container"
    :callbacks="callbacks"
    :steps="filledSteps"
  >
    <template v-slot:default="tour">
      <MTourTip
        v-for="(step, index) of tour.steps"
        v-show="tour.currentStep === index"
        :key="index"
        :current-index="index"
        :steps-count="tour.steps.length"
        :active="tour.currentStep === index"
        :step="step"
        :previous-step="tour.previousStep"
        :next-step="tour.nextStep"
        :stop="tour.stop"
        :is-first="tour.isFirst"
        :is-last="tour.isLast"
        :labels="tour.labels"
        :width="step.width"
        @restart="restartTour"
        @targetNotFound="targetNotFound"
      />
    </template>
  </v-tour>
</template>
<script>
  import MTourTip from 'onboarding/components/MTour/MTourTip';

  export default {
    name: 'MTour',
    components: {MTourTip},
    props: {
      name: {
        type: String,
        required: true
      },
      steps: {
        type: Array,
        default: Array
      },
      globalEvents: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        currentStep: 0,
        restartedCount: 0,
        finished: false,
        success: true,
        restart: false,
        callbacks: {
          onStart: () => {
            this.applyGlobalEvent();
            this.applyStepEvent(0);
            this.onStepStart(0);
          },
          onNextStep: (currentStep) => {
            this.rollbackStepEvent(currentStep);
            this.applyStepEvent(currentStep + 1);
            this.onStepStart(currentStep + 1);
            this.currentStep = currentStep + 1;
          },
          onPreviousStep: (currentStep) => {
            this.globalEvents.stepBack();
            this.rollbackStepEvent(currentStep);
            this.applyStepEvent(currentStep - 1);
            this.currentStep = currentStep - 1;
          },
          onStop: () => {
            this.rollbackStepEvent(this.currentStep);
            this.rollbackGlobalEvent();
            this.$emit('tourEnd');
          }
        }
      };
    },
    computed: {
      filledSteps () {
        return this.steps.map((item) => {
          if (!item.params.modifiers) {
            item.params.modifiers = {};
          }
          return item;
        });
      }
    },
    mounted () {
      window.addEventListener('hashchange', this.handleNavigate);
      this.$tours[this.name].start();
      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('hashchange', this.handleNavigate);
      });
    },
    errorCaptured () {
      this.success = false;
      this.$tours[this.name].stop();
    },
    methods: {
      handleNavigate () {
        this.success = false;
        this.$tours[this.name].stop();
        window.removeEventListener('hashchange', this.handleNavigate);
      },
      restartTour () {
        this.restartedCount += 1;
        this.finished = true;
        this.restart = true;
        this.$tours[this.name].stop();
        this.restart = false;
        this.$tours[this.name].start();
      },
      targetNotFound () {
        this.success = false;
        this.$tours[this.name].stop();
      },
      onStepStart (stepNum) {
        return this.globalEvents.stepEnter(stepNum);
      },
      applyStepEvent (stepNum) {
        const events = this.steps[stepNum].events;
        if (events && events.on) {
          events.on(this);
        }
      },
      rollbackStepEvent (stepNum) {
        const events = this.steps[stepNum].events;
        if (events && events.off) {
          events.off(this);
        }
      },
      applyGlobalEvent () {
        if (this.globalEvents) {
          this.globalEvents.on();
        }
      },
      rollbackGlobalEvent () {
        if (this.globalEvents) {
          this.globalEvents.off({
            success: this.success,
            finished: this.finished || this.currentStep + 1 === this.steps.length,
            lastStep: this.currentStep + 1,
            restartedCount: this.restartedCount,
            restart: this.restart
          });
        }
      }
    }
  };
</script>
<style>
  .v-tour.tour-container {
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight);

    /* hack to prevent jumping scroll before tour step is positioned */
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    pointer-events: none;
  }
</style>
