<template>
  <ul class="tag-list">
    <TagListTag
      v-if="allButton"
      :active="allActive"
      :title="allText"
      :text="allText"
      :enable-toggling="enableToggling"
      :disabled="disabled"
      :small="small"
      :gray="gray"
      @click="toggleAll"
      @toggle="val => $emit('itemToggled', null, val)"
    />
    <TagListTag
      v-for="(tag, itemIndex) in tags"
      :key="itemIndex"
      :active="!(allActive && allButton) && isActive(tag.id)"
      :icon="tag.icon"
      :title="tag.title"
      :text="tag.text"
      :enable-toggling="enableToggling"
      :disabled="disabled"
      :small="small"
      :gray="gray"
      @click="toggleItem(tag.id)"
      @toggle="val => $emit('itemToggled', tag.id, val)"
    />
  </ul>
</template>

<script>
  import translation from 'translation';
  import TagListTag from 'legacy/modules/common/components/TagListTag';

  export default {
    name: 'TagList',
    components: {TagListTag},
    props: {
      tags: {
        type: Array,
        required: true,
        default: Array
      },
      enableToggling: {
        type: Boolean,
        default: true
      },
      value: {
        type: Array,
        required: true,
        default: Array
      },
      allButton: {
        type: Boolean,
        default: true
      },
      allText: {
        type: String,
        default: () => translation.tr('All')
      },
      disabled: Boolean,
      gray: Boolean,
      small: Boolean
    },
    data () {
      return {
        active: this.value
      };
    },
    computed: {
      allActive () {
        return this.active.length === this.tags.length || this.active.length === 0;
      }
    },
    watch: {
      value (val) {
        this.active = val;
      }
    },
    methods: {
      toggleAll () {
        if (this.allActive) {
          this.$emit('input', []);
        } else {
          this.$emit('input', this.tags.map(item => item.id));
        }
      },
      isActive (tagId) {
        return this.active.includes(tagId);
      },
      toggleItem (itemId) {
        if (this.allActive && this.allButton) {
          this.$emit('input', [itemId]);
        } else if (this.active.includes(itemId)) {
          const newValue = this.active.slice();
          newValue.splice(newValue.indexOf(itemId), 1);
          this.$emit('input', newValue);
        } else if (Array.isArray(this.active)) {
          this.$emit('input', [...this.active, itemId]);
        } else {
          this.$emit('input', itemId);
        }
      }
    }
  };
</script>

<style>
  .tag-list {
    display: flex;
    margin: 0;
  }

  .tag-list .tag-list__tag:first-child {
    margin-left: 0;
  }

  .tag-list .tag-list__tag:last-child {
    margin-right: 0;
  }
</style>
