<template>
  <router-link
    class="header-tab__menu-item"
    :class="{'menu__list-item_active': to.name === $route.name}"
    :to="to"
  >
    {{ name }}
    <MProviderIcon
      v-for="(icon, index) in icons"
      :key="index"
      class="header-tab__menu-item-icon"
      :icon="icon"
    />
  </router-link>
</template>

<script>
  import {MProviderIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'HeaderTabMenuItem',
    components: {MProviderIcon},
    props: {
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      to: {
        type: Object,
        default: null
      },
      icons: {
        type: Array,
        default: () => []
      }
    }
  };
</script>

<style>
  .menu__list-item.header-tab__menu-item {
    text-overflow: ellipsis;

    text-decoration: none;

    white-space: nowrap;

    overflow: hidden;

    display: flex;

    align-items: center;

    height: 28px;
  }

  .header-tab__menu-item-icon {
    width: 16px;
    height: 16px;

    margin-left: 6px;
  }

  .header-tab__menu-item-icon:not(:first-of-type) {
    margin-left: 4px;
  }

</style>
