<template>
  <div class="statistics-filters">
    <div class="filters__group filters__group_channels">
      <ChannelTags
        :value="channelsIds"
        class="filters__channel-tags"
        :channels="channels"
        @input="valueChanged({ channels: $event })"
      />
    </div>
    <div class="filters__group filters__group_groupings">
      <div class="filters-calendar">
        <label
          v-if="!smallLayout || superSmallLayout"
          class="filters-calendar__label"
        >
          {{ _("Date") }}
        </label>
        <DateRangePicker
          :start-date="startDate"
          :end-date="endDate"
          :min-date="projectStartDate"
          :max-date="dateNow"
          hide-clear-button
          @input="updateDateInterval"
        />
      </div>
      <MSelectControl
        :value="dateGrouping"
        :label="labels.groupByDate"
        :label-position="labelPosition"
        :choices="dateGroupingChoices"
        class="filters__date-grouping"
        @input="valueChanged({ dateGrouping: $event })"
      />
      <MSelectControl
        :value="grouping"
        :label="labels.groupBy"
        :label-position="labelPosition"
        :choices="groupingChoices"
        :opt-selected-component="false"
        :opt-component="false"
        :opt-group-compoent="false"
        :allow-width-overflow="true"
        class="filters__grouping"
        @input="valueChanged({ grouping: $event })"
      />
      <StatisticFiltersFiltering
        class="filters__filtering"
        :type-value="filterType"
        :type-choices="filterTypeChoices"
        :entities-value="currentFilterEntities"
        :entities-choices-by-type="filterChoicesByType"
        :label-position="labelPosition"
        :active-channel-ids="channelsIds"
        @input-type="filterTypeChanged"
        @input-entities="filterEntitiesChanged"
        @entities-close="valueChanged"
      />
    </div>
  </div>
</template>

<script>
  import ChannelTags from 'pages/seller/statistics/components/ChannelTags';
  import MSelectControl from 'modules/common/components/MSelectControl';
  import StatisticFiltersFiltering from 'pages/seller/statistics/components/StatisticFiltersFiltering';
  import DateRangePicker from 'legacy/modules/common/components/DateRangePicker/DateRangePicker';
  import moment from 'moment';
  import AppEventBus from 'utils/appEventBus';

  // width of viewport, when components labels position should be changed
  const TRIGGER_LABELS_WIDTH = 1700;
  const TRIGGER_SMALL_MODE_WIDTH = 1400;

  const MAX_SELECTED_PROFILES = 3;

  export default {
    name: 'StatisticsFilters',
    components: {DateRangePicker, MSelectControl, ChannelTags, StatisticFiltersFiltering},
    props: {
      startDate: {
        type: Date,
        default: () => new Date() // `default: Date` not working because it returns String
      },
      endDate: {
        type: Date,
        default: () => new Date()
      },
      channels: {
        type: Array,
        default: Array,
        required: true
      },
      channelsIds: {
        type: Array,
        default: Array
      },
      onFiltersChange: {

        // For integration with Backbone.View
        type: Function,
        default: () => () => {}
      },
      dateGroupingChoices: {
        type: Array,
        default: Array
      },
      dateGrouping: {
        type: String,
        default: null
      },
      groupingChoices: {
        type: Array,
        default: Array
      },
      grouping: {
        type: String,
        default: null
      },
      showPlot: {
        type: Boolean,
        default: true
      },
      filterTypeChoices: {
        type: Array,
        default: Array
      },
      filterType: {
        type: String,
        default: ''
      },
      filterChoicesByType: {
        type: Object,
        default: Object
      },
      filterEntities: {
        type: Array,
        default: Array
      },
      placements: {
        type: Array,
        default: Array
      },
      profilesChoices: {
        type: Array,
        default: Array
      },
      profiles: {
        type: Array,
        default: Array
      },
      goalsChoices: {
        type: Array,
        default: Array
      },
      goals: {
        type: Array,
        default: Array
      },
      webanalyticsDisabled: {
        type: Boolean,
        default: false
      },
      webanalyticsDisabledMessage: {
        type: String,
        default: ''
      },
      projectStartDate: {
        type: Date,
        default: null
      }
    },
    data () {
      return {
        labels: {
          groupByDate: 'Данные по',
          groupBy: 'Группировка',
          infographics: 'Infographics',
          webanalyticsProfiles: 'Web analytics profiles',
          webanalyticsGoals: 'Web analytics goals'
        },
        smallLayout: false,
        superSmallLayout: false,
        labelPosition: 'top',
        waProviders: [],
        dateNow: moment().toDate(),

        // copy  prop value to prevent update while selecting values in multiselect
        currentFilterEntities: [...this.filterEntities],
        currentProfiles: [...this.profiles],
        currentGoals: [...this.goals]
      };
    },
    computed: {
      maxSelectedProfiles () {
        return MAX_SELECTED_PROFILES;
      },
      maxProfilesHintText () {
        return 'You can select up to three web analytics profiles at the same time';
      },
      skeletonHeight () {
        if (!this.smallLayout) {
          return '32px';
        }
        return '56px';
      },
      chosenProfiles () {
        return new Set(this.currentProfiles);
      },
      goalsChoicesFiltered () {
        return this.goalsChoices.filter(i => this.chosenProfiles.has(i.profile_id)).map(i => {

          // highlight deleted goals and add hover tip for them
          if (i.external_deleted) {
            i.highlightInfo = {
              color: '#F5F5F5',
              text: 'No goal'
            };
          }
          return i;
        });
      }
    },
    watch: {
      filterEntities () {
        this.currentFilterEntities = this.filterEntities;
      },
      profiles () {
        this.currentProfiles = this.profiles;
      },
      goals () {
        this.currentGoals = this.goals;
      }
    },
    created () {

      // watch for resize to make filters adaptive
      AppEventBus.$on('PAGE_RESIZE', this.handleResize);
      this.handleResize();

      this.$on('hook:destroyed', () => {
        AppEventBus.$off('PAGE_RESIZE', this.handleResize);
      });
    },
    methods: {
      updateDateInterval (data) {
        if (data.isPickerOpened) {

          // do not save when picker is opened
          return;
        }
        if (data.startDate && data.endDate) {
          this.valueChanged({
            endDate: data.endDate,
            startDate: data.startDate
          });
        } else {
          this.valueChanged({
            endDate: null,
            startDate: null
          });
        }
      },
      handleResize () {
        this.smallLayout = window.innerWidth <= TRIGGER_LABELS_WIDTH;
        this.superSmallLayout = window.innerWidth <= TRIGGER_SMALL_MODE_WIDTH;
        this.labelPosition = this.smallLayout && !this.superSmallLayout ? 'left' : 'top';
      },

      // changedObj = {key: value, ....}
      valueChanged (changedObj = {}) {
        const data = {
          startDate: this.startDate,
          endDate: this.endDate,
          dateGrouping: this.dateGrouping,
          grouping: this.grouping,
          channels: this.channelsIds,
          filterType: this.filterType,
          filtering: this.currentFilterEntities,
          entities: this.entities,
          profiles: this.currentProfiles,
          goals: this.currentGoals
        };

        const newFilters = {
          ...data,
          ...changedObj
        };

        newFilters.goals = this.goalsChoices.filter(
          i => newFilters.goals.includes(i.id) && newFilters.profiles.includes(i.profile_id)
        ).map(i => i.id);

        this.$emit('input', newFilters);

        let showPlot = newFilters.dateGrouping !== 'no';
        this.$emit('triggerShowPlot', showPlot);
      },
      filterTypeChanged (value) {
        const data = {
          filterType: value
        };

        // this.currentFilterType = value;
        if (this.filterEntities.length !== 0) {
          data.filterEntities = [];
        }
        this.valueChanged(data);
      },
      filterEntitiesChanged (value) {
        this.currentFilterEntities = value;
      }
    }
  };
</script>

<style>
  .statistics-filters {
    font-family: var(--main-font-family);

    position: relative;
  }

  .statistics-filters > * {
    margin-bottom: 18px;
  }

  .filters__group {
    display: flex;

    align-items: center;

    margin-bottom: 18px;

    & > *:not(:last-child) {
      margin-right: 22px;
    }
  }

  .filters__group_channels {
    margin-top: 12px;
  }

  .filters__channel-tags {
    flex-grow: 1;
  }

  .filters-calendar {
    flex-shrink: 0;

    box-sizing: border-box;
    width: 182px;
  }

  .filters-calendar__label {
    font-size: var(--main-font-size);
    font-weight: var(--medium-font-weight);

    color: #000000;
    line-height: var(--main-line-height);

    margin-bottom: 8px;
  }

  .filters__group .filters__item,
  .filters__group .select-container__select {
    min-width: 0;
  }

  @media (--extra-large) {
    .filters__date-grouping {
      flex: none;
      width: 140px;
    }

    .filters__grouping {
      flex: none;
      width: 144px;
    }

    .filter-item__profiles {
      flex: 1 1 284px;
    }

    .filter-item__goals {
      flex: 1 3 400px;
    }

    .filters__filtering {
      flex: none;
      width: 420px;
    }

    .stats-filter__filtering {
      width: 208px;
    }
  }

  @media (--large) {
    .filters__date-grouping {
      flex: 0 0 196px;
    }

    .filters__grouping {
      flex: 1 0 174px;
    }

    .filters__filtering {
      flex: 1 0 475px;
      min-width: 475px;
    }

    .stats-filter__filtering {
      flex: 0 0 228px;
    }

    .filter-item__goals {
      width: calc(50% - 11px);
    }

    .filter-item__profiles {
      width: calc(50% - 11px);
    }
  }

  @media (--extra-small), (--small), (--medium) {
    .filters__date-grouping {
      flex: 1 0 140px;
    }

    .filters__grouping {
      flex-grow: 1;
      flex-basis: 174px;
    }

    .filters__filtering {
      flex: 1 1 420px;
      min-width: 0;
    }

    .stats-filter__filtering {
      flex: 0 1 120px;
    }

    .filter-item__goals {
      width: 260px;
    }

    .filter-item__profiles {
      width: 260px;
    }
  }
</style>
