<template>
  <div>
    <OnboardingTextSection>
      <b>Дашборд</b> — это настроеная вами отдельная <br> страница с графиками и диаграммами.
    </OnboardingTextSection>

    <OnboardingTextSection>
      На одном графике можно визуализовать данные <br> сразу по нескольким Брендам.
    </OnboardingTextSection>

    <OnboardingTextSection>
      Дашборд настраивается индивидуально для <br> каждого пользователя.
      Также можно делать общие <br> дашборды для коллег и клиентов.
    </OnboardingTextSection>

    <div class="sidebar-dashboard-menu-step-wrapper">
      <OnboardingImageSection
        width="184px"
        :image="sidebarImage"
        class=""
      />
      <OnboardingImageSection
        width="184px"
        :image="dashboardChartImage"
        class=""
      />
    </div>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';

  import sidebarImage from 'onboarding/onboardings/SidebarNavigation/assets/dashboard-menu.png';
  import dashboardChartImage from 'onboarding/onboardings/SidebarNavigation/assets/dashboard-chart.png';


  export default {
    name: 'SidebarDashboardMenu',
    components: {
      OnboardingTextSection,
      OnboardingImageSection
    },
    data () {
      return {
        sidebarImage,
        dashboardChartImage
      };
    }
  };
</script>

<style>
  .sidebar-dashboard-menu-step-wrapper {
    display: flex;
    justify-content: space-around;
  }
</style>
