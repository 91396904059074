<template>
  <span>
    <template v-if="referenceOnboarding && onboardingCondition">
      <DropdownMenu
        ref="hints-dropdown"
        class="page-title-dropdown-hint"
        popper-class="hints-dropdown-popper"
        :dropdown-text="referenceTitle"
      >
        <ul class="menu__list">
          <MMenuLinkItem
            class="menu__list-item"
            :name="_('Turn on hints')"
            @click.native="turnOnOnboarding"
          />
          <MMenuLinkItem
            v-if="referenceLink"
            class="menu__list-item"
            :name="_('Read in Help')"
            :icons="icons"
            @click.native="openMarilynHelp"
          />
        </ul>
      </DropdownMenu>
    </template>
    <template v-else>
      <a
        v-if="referenceLink"
        target="_blank"
        :href="referenceLink"
        @click="trackHelpClick"
      >{{ referenceTitle }}</a>
    </template>
  </span>
</template>

<script>
  import DropdownMenu from 'legacy/modules/common/components/DropdownMenu';
  import MMenuLinkItem from 'legacy/modules/common/components/MMenuLinkItem';
  import arrow from 'assets/icons/arrow.svg';
  import onboardingManager from 'onboarding/onboardingmanager';

  export default {
    name: 'MHelpPageHelpLink',
    components: {
      DropdownMenu,
      MMenuLinkItem
    },
    props: {
      referenceTitle: {
        type: String,
        required: true
      },
      referenceLink: {
        type: String,
        default: ''
      },
      activeTab: {
        type: Object,
        default: Object
      },
      referenceOnboarding: {
        type: Function,
        default: null
      }
    },
    data () {
      return {
        icons: [arrow],
        onboardingCondition: false
      };
    },
    watch: {
      $route: {
        immediate: true,
        async handler () {
          this.onboardingCondition = false;
          if (!this.referenceOnboarding) {
            return;
          }
          const onboarding = new this.referenceOnboarding();

          this.onboardingCondition = await onboarding.constructor.checkCondition();
        }
      }
    },
    methods: {
      turnOnOnboarding () {
        onboardingManager.scheduleOnboarding(this.referenceOnboarding, {source: 'hint'});

        this.$refs['hints-dropdown'].$refs.popover.closeDropdown();
      },
      openMarilynHelp () {
        window.open(this.referenceLink);

        if (this.activeTab) {
          this.$tracker.track(this.activeTab.id, this.referenceLink);
        }
        this.$refs['hints-dropdown'].$refs.popover.closeDropdown();
      },
      trackHelpClick () {
        this.$tracker.track(this.activeTab.id, this.referenceLink);
      }
    }
  };
</script>

<style>
  .page-title-dropdown-hint {
    display: inline-block;
  }

  .page-title-dropdown-hint span {
    font-size: var(--secondary-font-size);
    font-weight: var(--main-font-size);

    color: var(--action-color);
  }

  .page-title-dropdown-hint span:hover {
    color: var(--action-hover-color);
  }

  .hints-dropdown-popper .menu__list-item_link.menu__list-item {
    display: flex;
  }
</style>
