<template>
  <div class="table-settings-preset-save">
    <MErrorWrapper
      :error-object="formErrors"
      error-path="name"
    >
      <MInput
        :label="presetName"
        :value="name"
        :disabled="Boolean(presetId)"
        :emit-input-on-change="false"
        @input="changePresetName"
      />
    </MErrorWrapper>
    <MRadiobuttonGroup
      v-if="canCreateAccountPreset"
      :disabled="Boolean(presetId)"
      :value="scope"
      class="table-settings-preset-save__save-level-checkbox"
      :group-label="saveOnLevelText"
      :choices="saveLevelChoices"
      @input="changePresetLevel"
    />
  </div>
</template>

<script>
  import translation from 'translation';
  import MInput from 'legacy/modules/common/components/MInput';
  import MRadiobuttonGroup from 'legacy/modules/common/components/MRadiobuttonGroup';
  import {mapGetters} from 'vuex';
  import MErrorWrapper from 'legacy/modules/common/components/MErrorWrapper/MErrorWrapper';

  export default {
    name: 'TableSettingsPresetSaveForm',
    components: {MErrorWrapper, MInput, MRadiobuttonGroup},
    props: {
      presetId: {
        type: Number,
        default: null
      },
      scope: {
        type: String,
        default: 'ACCOUNT'
      },
      name: {
        type: String,
        default: ''
      },
      formErrors: {
        type: Object,
        default: Object
      }
    },
    data () {
      const saveOnLevelText = translation.tr('Save on level');
      return {
        saveAsPresetText: translation.tr('Save as preset'),
        saveOnLevelText: `${saveOnLevelText}:`,
        presetName: translation.tr('Preset name'),
        saveLevelChoices: [
          {id: 'ACCOUNT', name: translation.tr('Account')},
          {id: 'USER', name: translation.tr('User')}
        ],
        activeLevel: 'account'
      };
    },
    computed: {
      ...mapGetters('user', ['canUser']),
      canCreateAccountPreset () {
        return this.canUser('CREATE_ACCOUNT_COLUMN_PRESET');
      }
    },
    methods: {
      changePresetLevel (newVal) {
        this.$emit('changeVal', {key: 'scope', value: newVal});
      },
      changePresetName (newVal) {
        this.$emit('changeVal', {key: 'name', value: newVal});
      }
    }
  };
</script>

<style>
  .table-settings-preset-save {
    --save-separator-border-color: var(--border-color);
  }

  .table-settings-preset-save .m-input__label {
    color: #000000;
  }

  .table-settings-preset-save__main-controls {
    display: flex;
    flex-direction: row;
  }

  .table-settings-preset-save__main-controls button {
    margin-right: 24px;
  }

  .table-settings-preset-save__as-preset-trigger {
    font-size: 14px;
    margin-left: auto;
  }

  .table-settings-preset-save__save-preset {
    color: #000000;

    padding-top: 14px;

    margin-top: 14px;

    border-top: var(--save-separator-border-color) solid 1px;
  }

  .table-settings-preset-save__save-level-checkbox {
    margin-top: 16px;

    & .m-radiobutton__label,
    & .m-radiobutton-group__label {
      font-size: 14px;
    }
  }

</style>
