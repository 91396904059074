<template>
  <div class="accounts-list-page">
    <div class="accounts-search-panel">
      <SearchInput
        class="m-inline-search__accounts"
        :emit-input-on-change="false"
        :value="params.name"
        :placeholder="_('Search')"
        @input="getSearchTextDebounced"
      />
      <MSelectControl
        class="select-container__accounts"
        id-attr="id"
        text-attr="name"
        :value="selectedStatusValue"
        :choices="choices"
        popper-class="popper__accounts"
        @input="getSelectedStatus"
      />

      <MHelpPageHelpLink
        v-if="reference"
        :reference-link="referenceLink"
        :active-tab="activeTab"
        :reference-onboarding="referenceOnboarding"
        :reference-title="referenceTitle"
        class="accounts-search-panel__help"
      />

      <MButton
        v-if="currentUserIsAdmin"
        class="m-button-create__accounts"
        :title="_('Create account')"
        @click="createAccount"
      >
        {{ isSmallWindow ? _('Create') : _('Create account') }}
      </MButton>
    </div>
    <div class="accounts-table">
      <AccountsTable
        :m-table-columns-data="mTableColumnsData"
        :data="data"
        :items-total="itemsTotal"
        :selected-items="selectedItems"
        :is-selectable="false"
        :is-table-loading="isTableLoading"
        :current-page="params.page"
        :per-page="params.per_page"
        :current-sorting="params.sorting"
        @fetch="paginateTable"
        @sort="sortTable"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script>
  import {accountsService} from 'services';
  import AccountsTable from 'pages/accounts/components/AccountsTable';
  import MSelectControl from 'components/common/MSelectControl/MSelectControl';
  import MButton from 'components/common/MButton';
  import MTableCellRouterLink from 'legacy/modules/common/components/MTable/cells/MTableCellRouterLink';
  import MTableCellAccountType from 'legacy/modules/common/components/MTable/cells/MTableCellAccountType';
  import PropsDependentPage from 'pages/mixins/PropsDependentPage';
  import StorageAdapter from 'adapters/StorageAdapter';
  import UrlAdapter from 'adapters/UrlAdapter';
  import {converterArrayWrapper, sortingConverters} from 'adapters/converters';
  import iconActive from 'assets/icons/icon-active.svg';
  import AppEventBus from 'utils/appEventBus';
  import translation from 'translation';
  import {mapActions, mapGetters} from 'vuex';
  import MTableCellAccountStatus from 'legacy/modules/common/components/MTable/cells/MTableCellAccountStatus';
  import iconMagnifier from 'assets/icons/search.svg';
  import {referenceDictionary} from 'components/common/HeaderTabs/referenceDictionary';
  import MHelpPageHelpLink from 'components/common/HeaderTabs/MHelpPageHelpLink';
  import {SearchInput} from '@marilyn/marilyn-ui';
  import _ from 'lodash';
  import {API_SEARCH_INPUT_DEBOUNCE_TIME} from 'appConstants';
  import {convertSorting} from 'utils/api/requestSortingConverters';
  _.noConflict();

  const DEFAULT_PAGE_PARAMS = {
    page: 1,
    sorting: [
      {
        name: 'name',
        direction: 'asc'
      }
    ],
    name: null,
    status: ['ACTIVE']
  };

  export default {
    name: 'AccountsListPage',
    components: {
      AccountsTable,
      MSelectControl,
      MButton,
      MHelpPageHelpLink,
      SearchInput
    },
    mixins: [PropsDependentPage],
    props: {
      accountId: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        iconMagnifier,
        isTableLoading: true,
        choices: [
          {
            id: 'ALL_ACTIVE',
            name: this._('All active'),
            icons: [iconActive],
            position: 'right'
          },
          {id: 'ALL', name: this._('All')}
        ],
        data: [],
        selectedItems: [],
        itemsTotal: 0,
        selectedStatusValue: 'ALL_ACTIVE',
        params: _.cloneDeep(DEFAULT_PAGE_PARAMS),
        windowWidth: undefined,
        isSearchInFocus: true,
        storageAdapter: undefined,
        urlAdapter: undefined
      };
    },
    computed: {
      ...mapGetters('enums', ['projectStatuses', 'accountTypes']),
      ...mapGetters('user', ['currentUserIsAdmin', 'currentUser']),
      activeTab () {
        return {
          id: 'accounts'
        };
      },
      reference () {
        return referenceDictionary().accounts;
      },
      baseHelpUrl () {
        return this.currentUser.help_url;
      },
      referenceLink () {
        if (!this.reference || !this.baseHelpUrl) {
          return null;
        }
        return `${this.baseHelpUrl}${this.reference.helpLink}`;
      },
      referenceTitle () {
        if (!this.reference) {
          return null;
        }
        return this.reference.helpTitle;
      },
      referenceOnboarding () {
        if (!this.reference) {
          return null;
        }

        return this.reference.onboarding;
      },

      isSmallWindow () {
        return this.windowWidth < 970;
      },
      mTableColumnsData () {
        return [
          {
            itemKey: 'id',
            header: '№',
            cellComponent: MTableCellRouterLink,
            minWidth: 96,
            align: 'left',
            static: true,
            resizable: false,
            sortable: true,
            route: el => this.getPath(el)
          },
          {
            itemKey: 'name',
            header: translation.tr('Name'),
            cellComponent: MTableCellRouterLink,
            align: 'left',
            minWidth: 200,
            sortable: true,
            route: el => this.getPath(el)
          },
          {
            itemKey: 'type',
            header: translation.tr('Type'),
            cellComponent: MTableCellAccountType,
            minWidth: 100,
            sortable: true,
            align: 'left'
          },
          {
            itemKey: 'status',
            header: translation.tr('Status'),
            cellComponent: MTableCellAccountStatus,
            minWidth: 60,
            sortable: true,
            align: 'center'
          }
        ];
      }
    },
    watch: {
      $route (prev, curr) {
        if (prev.path !== curr.path) {
          Object.assign(this.params, DEFAULT_PAGE_PARAMS);
          this.afterDataFetch(DEFAULT_PAGE_PARAMS);
          this.selectedStatusValue = 'ALL_ACTIVE';
        }
      }
    },
    async created () {
      this.urlAdapter = new UrlAdapter({
        name: {},
        sorting: {
          converters: converterArrayWrapper(sortingConverters),
          alias: 'sorting'
        }
      });

      this.storageAdapter = new StorageAdapter('accounts-table-storage-new', {
        per_page: 10
      });

      let {name, sorting} = this.urlAdapter.load(this.params);
      let {per_page} = this.storageAdapter.load(this.params);

      this.params.name = name;
      this.params.sorting = sorting;
      this.params.per_page = per_page;

      this.currentSorting = sorting;

      this.fetchEnums(['projectStatuses', 'accountTypes', 'accountStatuses']);
    },
    mounted () {
      this.updateWindowWidth();
      AppEventBus.$on('PAGE_RESIZE', this.updateWindowWidth);
      this.$on('hook:destroyed', () => {
        AppEventBus.$off('PAGE_RESIZE', this.updateWindowWidth);
      });
    },
    methods: {
      ...mapActions('enums', {fetchEnums: 'FETCH_ENUMS'}),
      async getTableData () {
        const params = {
          ...this.params,
          sorting: convertSorting(this.params.sorting)
        };

        return accountsService.list(this.accountId, params);
      },
      updateWindowWidth () {
        this.windowWidth = window.innerWidth;
      },
      createAccount () {
        this.$router.push({name: 'accountCreateRoot'});
      },
      getSelectedStatus (statusId) {
        this.selectedStatusValue = statusId;
        this.params.status = statusId === 'ALL_ACTIVE' ? ['ACTIVE'] : null;
        this.getDefaultPagination();
        this.afterDataFetch();
      },
      getSearchTextDebounced: _.debounce(function (text) {
        this.params.name = text;
        this.urlAdapter.apply({name: this.params.name, sorting: this.params.sorting});
        this.getDefaultPagination();
        this.afterDataFetch();
      }, API_SEARCH_INPUT_DEBOUNCE_TIME),
      setSearchInFocus (value) {
        this.isSearchInFocus = value;
      },
      getDefaultPagination () {
        this.params.page = DEFAULT_PAGE_PARAMS.page;
      },
      async getComponentData () {
        const response = await this.getTableData();
        this.data = response.items;
        this.itemsTotal = response.items_total;
      },
      afterDataFetch () {
        this.isTableLoading = true;
        return [
          this.getComponentData().then(() => {
            this.isTableLoading = false;
          })
        ];
      },
      paginateTable (params) {
        Object.assign(this.params, {per_page: params.perPage, page: params.page});
        this.storageAdapter.apply({per_page: params.perPage});
        this.afterDataFetch();
      },
      sortTable (sorting) {
        if (sorting && sorting.length !== 0) {
          this.params.sorting = sorting;
          this.urlAdapter.apply({name: this.params.name, sorting: this.params.sorting});
          this.afterDataFetch();
        }
      },
      onSelect (selectedIds) {
        this.selectedItems = selectedIds;
      },
      getPath (accData) {
        return {path: this.isMCC(accData) ? `/accounts/${accData.id}` : `/accounts/${accData.id}/projects`};
      },
      isMCC (acc) {
        return acc.type === 'MCC';
      }
    }
  };
</script>

<style>
  .accounts-list-page {
    margin-top: 62px;
  }

  .accounts-search-panel {
    display: flex;

    align-items: center;

    margin-bottom: 15px;
  }

  .m-inline-search__accounts,
  .select-container__accounts {
    width: 165px;
    margin-right: 16px;
  }

  .m-inline-search__accounts--focused {
    border: 1px solid var(--action-color);
  }

  .m-inline-search__accounts input[type='search'].m-inline-search__input {
    padding: 0 0 0 5px;

    border: unset;

    border-radius: var(--border-radius);
  }

  .m-inline-search__accounts .m-inline-search__icon {
    padding-bottom: 5px;
    padding-right: 8px;

    border-bottom: none;
  }

  .accounts-list-page .m-button-create__accounts {
    margin-left: auto;
  }

  .select-container__accounts .select-active__icon,
  .popper__accounts .select-option__icon {
    color: var(--success-color);
  }

  .dropdown-menu__trigger.page-title-dropdown-hint--accounts {
    height: 32px;
  }

</style>
