<template>
  <div class="stats-filter">
    <MSelectControl
      :value="typeValue"
      :label="_('Filtering')"
      :label-position="labelPosition"
      :choices="typeChoices"
      :allow-width-overflow="true"
      class="stats-filter__filtering stats-filter__type-select"
      @input="typeChanged"
    />
    <MMultiSelect
      v-if="Boolean(entitiesProps.choices)"
      ref="entitiesSelect"
      :value="entitiesValue"
      :lazy-render="true"
      :force-dropdown-width="432"
      :disable-option-predicate="disabledEntitiesPredicate"
      v-bind="entitiesProps"
      text-attr="name"
      class="stats-filter__entity-select"
      allow-width-overflow
      @input="entitiesChanged"
      @close="$emit('entities-close')"
    />
  </div>
</template>

<script>
  import {MSelectControl, MMultiSelect} from '@marilyn/marilyn-ui';

  export default {
    name: 'StatisticFiltersFiltering',
    components: {MMultiSelect, MSelectControl},
    props: {
      typeChoices: {
        type: Array,
        default: () => []
      },
      activeChannelIds: {
        type: Array,
        default: Array
      },
      typeValue: {
        type: String,
        required: true
      },
      entitiesValue: {
        type: Array,
        default: () => []
      },
      entitiesChoicesByType: {
        type: Object,
        default () {}
      },
      labelPosition: {
        type: String,
        default: 'left'
      }
    },
    computed: {
      entitiesProps () {
        return this.preprocessEntityProps(this.entitiesChoicesByType[this.typeValue] || {});
      },
      disabledEntitiesPredicate () {
        const channelIdsSet = new Set(this.activeChannelIds);

        const filterByChannel = (item) => channelIdsSet.size > 0 && !channelIdsSet.has(item.channel_id);

        switch (this.typeValue) {
        case 'placement':
          return filterByChannel;
        case 'order':
          return filterByChannel;
        default:
          return MMultiSelect.props.disableOptionPredicate.default;
        }
      }
    },
    methods: {
      preprocessEntityProps (props) {
        switch (this.typeValue) {
        case 'placement':
          return this.preprocessFilterByChannel(props);
        case 'order':
          return this.preprocessFilterByChannel(props);
        default:
          return props;
        }
      },
      preprocessFilterByChannel (props) {
        // filter choices from not selected channels, but save selected options
        const channelIdsSet = new Set(this.activeChannelIds);
        let choices = props.choices;
        if (channelIdsSet.size > 0) {
          const selectedSet = new Set(this.entitiesValue);
          choices = choices.filter(i => channelIdsSet.has(i.channel_id) || selectedSet.has(i.id));
        }
        return {...props, choices};
      },
      typeChanged (value) {
        this.$emit('input-type', value);
        if (value !== 'no') {
          this.$nextFrame(() => {
            if (this.$refs.entitiesSelect) {
              this.$refs.entitiesSelect.focus();
            }
          });
        }
      },
      entitiesChanged (value) {
        this.$emit('input-entities', value);
      }
    }
  };
</script>

<style>
  .stats-filter {
    display: flex;
    align-items: flex-end;
  }

  .stats-filter__filtering {
    flex: none;
    margin-right: 8px;
  }

  .stats-filter__entity-select {
    flex: 1 1 194px;
    min-width: 194px;
  }
</style>
