<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="argumentsValues.name"
        :external-href="externalHref"
      />
    </template>
    <template>
      <div
        v-if="customPeriod.period && customPeriod.period.dateStart && statistics"
        ref="plotContainer"
        class="kpi-widget"
      >
        <KpiWidgetChart
          :statistics="statistics"
          :plan-fact-metrics="dsParams.planFactMetrics"
          :grouping="dsParams.grouping"
          :period="customPeriod.period"
          height="240px"
        />
      </div>
      <CardNoDataText
        v-else
      />
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import KpiWidgetChart from 'components/Dashboards/Widgets/KpiWidget/KpiWidgetChart';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';

  export default {
    name: 'KpiWidget',
    components: {
      WidgetHeader,
      CardNoDataText,
      KpiWidgetChart,
      DashboardCard
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      metrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: null
      },
      customPeriod: {
        type: Object,
        default: Object
      }
    },
    computed: {
      statistics () {
        if (this.data.items && !this.data.items.length) {
          let total = {...this.data.total};
          Object.keys(total).forEach(el => {
            total[el] = 0;
          });

          return [total];
        }

        if (this.data && this.data.items) {
          return this.data.items;
        }

        return null;
      }
    }
  };
</script>

<style>
  .kpi-widget {
    height: 100%;
    padding: 0 15px;
  }
</style>
