<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    class="progress-widget"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="argumentsValues.name"
        :metrics="planMetric"
        :metric-type="metricType"
        :external-href="externalHref"
      />
    </template>
    <template v-if="!isLoading">
      <div
        v-if="hasProgressWidgetData"
        class="progress-widget__contents"
      >
        <ProgressWidgetTable
          :data="page"
          :plan-metrics="planMetrics"
          :plan-metric="planMetric[0]"
          :fact-metric="factMetric"
          :metric-type="metricType"
          :type="dsParams.orderGrouping"
        />
        <ProgressWidgetPagination
          class="progress-widget__pagination"
          :pages-count="pagesCount"
          :current-page="currentPage"
          :type="dsParams.orderGrouping"
          @pageUp="pageUp"
          @pageDown="pageDown"
        />
      </div>
      <CardNoDataText v-else>
        <span class="progress-widget-no-data__text">{{ noDataText }}
        </span>
        <div class="progress-widget-no-data__to-accounts">
          <MLink
            v-if="!readonly && !mainDashboard"
            :href="ordersOrPeriodsLink"
          >
            {{ _("Create") }}
          </MLink>
        </div>
      </CardNoDataText>
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import ProgressWidgetTable from 'components/Dashboards/Widgets/ProgressWidget/ProgressWidgetTable';
  import ProgressWidgetPagination from 'components/Dashboards/Widgets/ProgressWidget/ProgressWidgetPagination';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import MLink from 'legacy/modules/common/components/MLink';
  import appContext from 'legacy/appcontext';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';
  import translation from 'translation';

  const ITEMS_PER_PAGE = 4;

  export default {
    name: 'ProgressWidget',
    components: {
      WidgetHeader,
      CardNoDataText,
      ProgressWidgetTable,
      ProgressWidgetPagination,
      DashboardCard,
      MLink
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      data: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      planMetrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      externalHref: {
        type: String,
        default: null
      },
      mainDashboard: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        currentPage: 1
      };
    },
    computed: {
      hasProgressWidgetData () {
        return this.data.items.length > 0 && this.planMetrics && Object.keys(this.planMetrics).length;
      },
      isOrder () {
        return this.dsParams.orderGrouping === 'order';
      },
      ordersOrPeriodsLink () {
        return appContext.url('ordersList', appContext.urlContext());
      },
      noDataText () {
        if (!this.mainDashboard) {
          return this.noOrdersPeriodsText;
        }

        return translation.tr('There is no data on orders in current period');
      },
      noOrdersPeriodsText () {
        if (this.isOrder) {
          return translation.trc('dashboards', 'You have no orders in current period');
        }

        // Нет активных периодов
        return translation.trc('dashboards', 'No active periods');
      },
      metricType () {
        return this.hasProgressWidgetData ? this.planMetrics[this.planMetric[0]].type : null;
      },
      planMetric () {
        return this.argumentsValues.planMetrics ? this.argumentsValues.planMetrics : this.argumentsValues.metrics;
      },
      factMetric () {
        return this.hasProgressWidgetData ? this.planMetrics[this.planMetric[0]].fact_metric : null;
      },
      page () {
        return this.data.items.slice((this.currentPage - 1) * ITEMS_PER_PAGE, this.currentPage * ITEMS_PER_PAGE);
      },
      itemsCount () {
        return this.data.items.length;
      },
      pagesCount () {
        return Math.ceil(this.itemsCount / ITEMS_PER_PAGE);
      }
    },
    methods: {
      pageUp () {
        this.currentPage -= 1;
      },
      pageDown () {
        this.currentPage += 1;
      }
    }
  };
</script>

<style>
  .progress-widget__contents {
    display: flex;

    flex-direction: column;

    height: 100%;
  }

  .progress-widget__pagination {
    margin-top: auto;
  }

  .progress-widget-no-data__text {
    line-height: 20px;

    text-align: center;

    width: 300px;

    padding: 0 10px;
  }

  .progress-widget-no-data__to-accounts {
    font-weight: var(--medium-font-weight);
    margin-top: 4px;
  }

</style>
