<template>
  <MTableCell
    v-show="showColumn"
    class="m-table-date__container"
    :class="{ 'm-table-date__container_weekends': isWeekend }"
  >
    <span>{{ formattedDate }}</span>
  </MTableCell>
</template>

<script>
  import moment from 'moment';
  import momentWrapper from 'moment-wrapper';
  import {mdash} from 'legacy/utils';
  import translation from 'translation';
  import {mTableCells} from '@marilyn/marilyn-ui';


  export default {
    name: 'MTableCellDate',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      },
      showColumn: {
        type: Boolean,
        default: true
      },
      dateGrouping: {
        type: String,
        default: ''
      },
      showTime: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      unformattedDate () {
        return this.tableRow[this.itemKey];
      },
      momentDate () {
        return this.unformattedDate ? moment(this.unformattedDate) : null;
      },
      isWeekend () {
        if (this.dateGrouping !== 'day') {
          return false;
        }
        if (this.momentDate) {
          const day = this.momentDate.toDate().getDay();
          return day === 6 || day === 0;
        }
        return null;
      },
      formattedDate () {
        if (!this.tableRow[this.itemKey]) {
          return mdash;
        }

        if (this.showTime) {
          return this.momentDate.format(translation.dateFormatter.momentDatetimeFormat());
        }

        switch (this.dateGrouping) {
        case 'day':
          return this.momentDate.format(translation.dateFormatter.momentDateFormatWithWeekday());
        case 'week': {
          const weekNumber = this.momentDate.week();

          return `${
            this.momentDate.format(translation.dateFormatter.momentDateFormat())
          } - ${
            this.momentDate.add(6, 'days').format(this.momentDate.format(translation.dateFormatter.momentDateFormat()))
          } (${weekNumber})`;
        }
        case 'month':
          return this.momentDate.format('YYYY, MMMM');
        case 'quarter':
          var x = momentWrapper(this.unformattedDate);
          var quarter = Math.ceil((x.month() - 1) / 3) + 1;
          return x.clone().date(quarter).
            format(translation.dateFormatter.momentDateGroupedQuartersFormat());
        case 'year':
          return this.momentDate.format('YYYY');
        default:
          return this.momentDate.format(translation.dateFormatter.momentDateFormat());
        }
      }
    }
  };
</script>

<style>
  .m-table-date__container_weekends {
    color: var(--error-hover-color);
  }
</style>
