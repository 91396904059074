<template>
  <BasePopover
    :visible="open"
    :visible-arrow="hasArrow"
    :placement="placement"
    :offset="offset"
    :popper-class="popperClass"
    :close-on-click-outside="closeOnClickOutside"
    :close-on-click-reference="closeOnClickReference"
    :options="popperOptions"
    :show-on="disabled ? 'none' : 'click'"
    @toggle="handleToggle($event)"
  >
    <template slot="popper">
      <div
        v-show="!!title"
        class="menu__header"
      >
        {{ title }}
      </div>
      <ul
        class="menu__list"
        :class="{'menu__list_headless': !title}"
      >
        <component
          :is="component"
          v-for="({active=false, component, props={ class: {} }}, i) in filteredItems"
          :key="props.id"
          ref="items"
          v-bind="props"
          :class="Object.assign({}, props['class'], {'menu__list-item_active': active})"
          class="menu__list-item"
          @click.native="$emit('item-click', items[i])"
        />
      </ul>
    </template>
    <template slot="reference">
      <div
        :title="title"
        class="menu__title"
      >
        <slot />
      </div>
    </template>
  </BasePopover>
</template>

<script>
  import {BasePopover} from '@marilyn/marilyn-ui';

  export default {
    name: 'MMenu',
    components: {BasePopover},

    model: {
      prop: 'open',
      event: 'toggle'
    },

    props: {
      title: {
        type: String,
        default: ''
      },
      offset: {
        type: String,
        default: '0,5'
      },
      open: {
        type: BasePopover.props.visible.type,
        required: BasePopover.props.visible.required,
        default: BasePopover.props.visible.default
      },
      placement: {
        type: BasePopover.props.placement.type,
        required: BasePopover.props.placement.required,
        default: BasePopover.props.placement.default
      },
      popperClass: {
        type: BasePopover.props.popperClass.type,
        default: BasePopover.props.popperClass.default
      },
      popperOptions: {
        type: Object,
        default: Object
      },
      items: {
        type: Array,
        required: true
      },
      closeOnClickOutside: {
        type: Boolean,
        default: true
      },
      closeOnClickReference: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hasArrow: Boolean
    },

    computed: {
      filteredItems () {
        return this.items.map((item) => {
          const newItem = {...item};
          const props = {...newItem.props};
          if (props.id) {
            delete props.id;
            newItem.props = props;
          }
          return newItem;
        });
      }
    },

    watch: {
      open (val) {
        this.handleToggle(val);
      }
    },

    methods: {
      handleToggle (val) {
        if (this.disabled) {
          return false;
        }

        this.$emit('toggle', val);
      }
    }
  };
</script>

<style>
  :root {
    --menu-width: 175px;
    --menu-item-height: 28px;
    --menu-item-horizontal-padding: 12px;
  }

  .menu__list {
    list-style: none;

    min-width: var(--menu-width);

    padding-top: 5px;
    padding-left: 0;
    padding-bottom: 5px;

    margin: 0;

    & .menu__list-item {
      color: var(--text-color);

      cursor: pointer;

      display: block;

      height: var(--menu-item-height);

      padding-left: var(--menu-item-horizontal-padding);
      padding-right: var(--menu-item-horizontal-padding);

      margin: 0;

      &.menu__list-item_active {
        color: var(--background-color);
        background-color: var(--action-color);

        &:hover {
          background-color: var(--action-hover-color);
        }
      }

      &:hover {
        background-color: var(--action-background-color);
      }
    }

    &.menu__list_headless {
      padding-top: 5px;
    }
  }

  .menu__header {
    font-size: var(--secondary-font-size);

    color: var(--gray-text-color);

    box-sizing: border-box;
    height: 24px;

    padding: 6px 12px 3px;
  }

</style>
