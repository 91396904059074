<template>
  <div class="onboarding-text-section">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'OnboardingTextSection'
  };
</script>

<style>
  .onboarding-text-section {
    font-size: 14px;

    color: var(--secondary-text-color);
    line-height: 20px;

    letter-spacing: 0.015em;

    padding-top: 16px;
  }

  .onboarding-text-section .m-icon {
    position: relative;
    display: inline-block;
    top: 3px;

    width: 20px;
    height: 20px;
  }
</style>
