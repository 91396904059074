<template>
  <div
    class="m-tour-step-header"
    :class="[
      `__${size}`
    ]"
  >
    <div class="m-tour-step-header__text">
      <slot />
    </div>
    <div class="m-tour-step-header__close">
      <CloseButton
        :large="true"
        :with-border="false"
        @click="$emit('stop')"
      />
    </div>
  </div>
</template>

<script>
  import CloseButton from 'legacy/modules/common/components/CloseButton';
  export default {
    name: 'MTourHeader',
    components: {CloseButton},
    props: {
      size: {
        type: String,
        default: 'medium',
        choices: ['small', 'medium']
      }
    }
  };
</script>

<style>
  .m-tour-step-header {
    display: flex;

    &.__medium {
      .m-tour-step-header__text {
        font-size: 18px;
        font-weight: var(--medium-font-weight);
      }
    }

    &.__small {
      .m-tour-step-header__text {
        font-size: 16px;
        font-weight: var(--medium-font-weight);

        line-height: 18px;

        letter-spacing: -0.03em;
      }
    }
  }

  .m-tour-step-header__close {
    margin-left: auto;
  }
</style>
