<template>
  <MTableCell
    class="m-table-cell-link__container m-table-cell__time"
    @click="$emit('click-trigger')"
  >
    {{ formattedTime }}
  </MTableCell>
</template>

<script>
  import moment from 'moment-wrapper';
  import {mTableCells} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTableCellTime',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      dataSeconds () {
        return parseInt(this.tableRow[this.itemKey]);
      },
      formattedTime () {

        // copypaste from project/static/js/modules/statistics/components/ColumnsTemplates.js (TimestampCell)
        const zeros = (value) => {
          value = `${value}`;
          return value.length === 1 ? `0${value}` : value;
        };

        const duration = moment.duration(this.dataSeconds, 'seconds');
        return '{0}:{1}:{2}'.format(zeros(Math.floor(duration.asHours())), zeros(duration.minutes()),
                                    zeros(duration.seconds()));
      }
    }
  };
</script>

<style>
  .m-table-cell__default {
    padding: 12px;
  }

</style>
