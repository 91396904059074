<template>
  <MTableCell
    class="m-table-cell-link__container m-table-column__cell_ellipsis"
  >
    <MProviderIcon
      v-if="channelIcon"
      :icon="channelIcon"
      class="m-table-cell__channel-icon"
    />
    <span>
      {{ placementName }}
    </span>
  </MTableCell>
</template>

<script>
  import {mTableCells, MProviderIcon} from '@marilyn/marilyn-ui';
  import {mdash} from 'legacy/utils';
  import ProjectDependentCellMixin
    from 'legacy/modules/common/components/MTable/cells/mixins/ProjectDependentCellMixin';
  import {mapGetters} from 'vuex';

  export default {
    name: 'MTableCellPlacementLink',
    components: {
      MProviderIcon,
      MTableCell: mTableCells.MTableCell
    },
    mixins: [ProjectDependentCellMixin],
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      ...mapGetters('sellerCampaigns', ['channelIcons']),
      placementName () {
        if (!this.tableRow[this.itemKey]) {
          return mdash;
        }
        return this.tableRow[this.itemKey];
      },
      channelIcon () {
        const channelId = this.tableRow.channel_id;
        if (!channelId) {
          return false;
        }
        return this.channelIcons[channelId];
      },
      placementId () {
        return this.tableRow.placement_id;
      },
      showLink () {
        return false;
      }
    }
  };
</script>

<style>
  .m-table-cell__channel-icon {
    flex-shrink: 0;

    width: 12px;
    height: 12px;

    margin-right: 8px;
  }
</style>
