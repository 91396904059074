<template>
  <MTableCell>
    <div class="m-table-cell-strategy-setting">
      <div
        v-if="hasStrategy"
        class="m-table-cell-strategy-setting-item _is-running"
      >
        <MButton
          link
          class="m-table-cell-strategy-setting__name-button"
          :title="strategyName"
          :disabled="isLoading || isCampaignDisable"
          @click="openModalStrategySetting"
        >
          {{ strategyName }}
        </MButton>

        <Switcher
          class="m-table-cell-strategy-setting__switcher"
          :state="strategyIsRun"
          :disabled="isLoading || isCampaignDisable"
          @switched="onSwitchStatus"
        />
      </div>

      <MHint
        v-else
        :allow-show="!!hintDisabledText"
        placement="right"
        hint-class="m-table-cell-strategy-setting__button-hint"
        close-only-hover-popper-outside
      >
        <div class="m-table-cell-strategy-setting-item _not-has-strategy">
          <MButton
            small
            class="m-table-cell-strategy-setting-button"
            :disabled="isDisabled || isCampaignDisable"
            @click="openModalStrategySetting"
          >
            Выбрать
          </MButton>
        </div>

        <template v-slot:content>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="hintDisabledText" />
          <!-- eslint-enable -->
        </template>
      </MHint>
    </div>
  </MTableCell>
</template>

<script>
  import {mTableCells} from '@marilyn/marilyn-ui';
  import {autostrategySellerService} from 'services/seller';
  import {getConnectionById, getConnectionDisabledStrategyTextHint} from 'utils/seller/sellerConnectionData';
  import Switcher from 'modules/common/components/Switcher.vue';
  import {SWITCH_STRATEGY_STATUS} from 'store/modules/seller/campaignsSeller';
  import {mapActions} from 'vuex';
  import ApiBadRequestError from 'utils/api/errors/ApiBadRequestError';
  import translation from 'translation';
  import appEventBus from 'utils/appEventBus';

  export default {
    name: 'MTableCellStrategySetting',
    components: {
      Switcher,
      MTableCell: mTableCells.MTableCell
    },
    props: {
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        isLoading: false
      };
    },
    computed: {
      isCampaignDisable () {
        const status = this.tableRow.status;
        return !(status === 'RUNNING' || status === 'PAUSED');
      },
      strategyData () {
        if (!this.hasStrategy) {
          return null;
        }

        return autostrategySellerService.getAutostrategyById(this.tableRow.strategy_id);
      },
      strategyName () {
        if (!this.hasStrategy) {
          return null;
        }

        return this.strategyData?.nameSeller || this.strategyData?.name || null;
      },
      strategyIsRun () {
        return this.tableRow.strategy_enabled;
      },
      currentChannelData () {
        return getConnectionById(this.tableRow.channel_id);
      },
      hasStrategy () {
        return Boolean(this.tableRow.strategy_id);
      },
      hintDisabledText () {
        if (!this.isDisabled) {
          return null;
        }
        const hintTextData = getConnectionDisabledStrategyTextHint(this.tableRow?.placement_type);
        let hintText = null;

        if (!['OZON_SKU', 'OZON_SKU_TOP'].includes(this.tableRow?.placement_type)) {
          hintText = hintTextData.ozonPlacementType;
        } else if (!['MANUAL', 'TARGET_BIDS'].includes(this.tableRow?.placement_strategy_type)) {
          hintText = hintTextData.ozonStrategyType;
        }

        return hintText;
      },
      isDisabled () {
        if (this.currentChannelData.id === 'ozon') {
          const {
            placement_strategy_type: strategyType,
            placement_type: placementType
          } = this.tableRow ?? {};

          const isManualOzonSku = strategyType === 'MANUAL' && placementType === 'OZON_SKU';
          const isManualOrTargetBidsOzonSkuTop =
            ['MANUAL', 'TARGET_BIDS'].includes(strategyType) &&
            placementType === 'OZON_SKU_TOP';

          return !(isManualOzonSku || isManualOrTargetBidsOzonSkuTop);
        }
        return false;
      }
    },
    methods: {
      ...mapActions('sellerCampaigns', {
        switchStrategyStatus: SWITCH_STRATEGY_STATUS
      }),
      openModalStrategySetting () {
        const params = {
          modalProps: {
            campaignItem: this.tableRow
          },
          modalEventListeners: {
            'switch-status': this.onSwitchStatus
          }
        };

        appEventBus.$emit('SHOW_STRATEGY_SETTING_MODAL', params);
      },
      async onSwitchStatus (val) {
        this.isLoading = true;

        try {
          await this.switchStrategyStatus({
            status: val,
            id: this.tableRow.group_id,
            campaignId: this.tableRow.id
          });

          this.$notify({
            type: 'success',
            header: val ? 'Вы запустили стратегию' : 'Вы остановили стратегию'
          });
        } catch (e) {
          if (e instanceof ApiBadRequestError) {
            this.$notify({
              type: 'error',
              header: translation.tr('Error'),
              text: e.getErrorMessage()
            });
          } else {
            throw e;
          }
        }

        this.isLoading = false;
      }
    }
  };
</script>

<style>
  .m-table-cell-strategy-setting {
    button.m-table-cell-strategy-setting-button {
      height: 22px;
      width: 100%;
    }
  }

  div.m-table-cell-strategy-setting__button-hint {
    padding: 12px;
    max-width: 232px;
  }

  .m-table-cell-strategy-setting-item {
    &._is-running {
      display: flex;
      align-items: center;
      width: 300px;
      justify-content: space-between;

      button.m-table-cell-strategy-setting__name-button {
        overflow: hidden;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }
  }

  .m-table-cell-strategy-setting__switcher {
    margin-left: 16px;
  }
</style>
