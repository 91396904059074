<template>
  <div class="statistics__sampled-block">
    <div class="sampled-block__text">
      {{ _("Analytics statistics is sampled") }}
    </div>
    <MHelp
      class="sampled-block__help"
      width="300px"
    >
      <!-- eslint-disable-next-line max-len -->
      {{ _("Sampling data occurs when a counter contains a large amount of data. If you look at statistics on a site with high traffic, then the probability of sampling is very high. You can read about what it is and why it is needed, for example, in the help") }}
      <a
        href="https://support.google.com/analytics/answer/2637192"
        target="_blank"
      >
        Google Analytics
        <i class="icon-external-link" />
      </a>
    </MHelp>
  </div>
</template>

<script>
  export default {
    name: 'StatisticsSampledNotification'
  };
</script>

<style>
  .statistics__sampled-block {
    display: flex;

    justify-content: center;
    align-items: center;

    margin-bottom: 18px;
  }

  .sampled-block__text {
    font-size: 14px;
    font-weight: var(--medium-font-weight);

    color: var(--warning-color);
  }

  .sampled-block__help {
    line-height: 18px;

    height: 16px;

    margin-left: 8px;
  }

  .sampled-block__help .m-help__icon {
    color: var(--warning-color);
  }

  .statistics__sampled-block .icon-external-link {
    font-size: 9px;
    margin-left: 2px;
  }
</style>
