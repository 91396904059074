<template>
  <div class="project-status-menu-item">
    <MIcon
      :icon="icon"
      class="project-status-menu-item__icon"
    />
    <div class="project-status-menu-item__text">
      {{ name }}
    </div>
  </div>
</template>

<script>
  import {MIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'ProjectStatusCellMenuItem',
    components: {MIcon},
    props: {
      value: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      icon: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style>
  .menu__list .menu__list-item.project-status-menu-item {
    display: flex;
    align-items: center;
  }

  .project-status-menu-item__icon {
    width: 16px;
    height: 16px;
  }

  .project-status-menu-item_active .project-status-menu-item__icon {
    color: var(--success-color);
  }

  .project-status-menu-item_paused .project-status-menu-item__icon {
    color: var(--main-color);
  }

  .project-status-menu-item_archived .project-status-menu-item__icon {
    color: var(--border-color);
  }

  .project-status-menu-item__text {
    margin-left: 16px;
  }

  .menu__list-item_active .project-status-menu-item__icon {
    color: var(--background-color);
  }

</style>
