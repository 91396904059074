<template>
  <div>
    <OnboardingTextSection>
      Если вы включите НДС, то я отображу статистику с<br>
      НДС. Если выключите, то без НДС.
    </OnboardingTextSection>

    <OnboardingTextSection>
      Это важно, поскольку ВКонтакте, Ozon и<br>
      Яндекс.Маркет работают с НДС, и если вам нужно,<br>
      чтобы мои данные и данные на этих площадках<br>
      сходились — включите для них НДС.
    </OnboardingTextSection>

    <OnboardingImageSection
      width="138px"
      :image="ezImage"
      align="center"
      class="onboarding-account-sidebar-tree__mascot"
    />
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';

  import ezImage from 'assets/mascot/mary-it-is-easy.png';

  export default {
    name: 'WATSectionStep',
    components: {OnboardingImageSection, OnboardingTextSection},
    data () {
      return {
        ezImage
      };
    }
  };
</script>

<style>
  .onboarding-account-sidebar-tree__mascot {
    padding-top: 26px;
  }
</style>
