<template>
  <MTableCell
    class="m-table-cell__icon"
    :class="classList"
  >
    <MTitle
      v-if="title || title === null"
      :title="title"
      offset="10,10"
    >
      <MIcon
        v-if="icon"
        :icon="icon"
      />
      <template v-else>
        {{ mdash }}
      </template>
    </MTitle>
    <template v-else>
      <MProviderIcon
        v-if="icon"
        :icon="icon"
        :style="iconSize"
        class="m-table-cell__icon-img"
      />
      <template v-else>
        {{ mdash }}
      </template>
    </template>
    <div
      v-if="text"
      :title="text"
      :aria-label="text"
      class="cell-icon-title"
    >
      <span>
        {{ text }}
      </span>
    </div>
  </MTableCell>
</template>

<script>
  import {mdash} from 'legacy/utils';
  import {MTitle, mTableCells, MProviderIcon, MIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTableCellIcon',
    components: {
      MTitle,
      MIcon,
      MProviderIcon,
      MTableCell: mTableCells.MTableCell
    },
    props: {
      tableRow: {
        type: Object,
        default: Object
      },
      iconSize: {
        type: Object,
        default: () => ({})
      },
      itemKey: {
        type: String,
        default: ''
      },
      icon: {
        type: Object,
        default: Object
      },
      text: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        mdash
      };
    },
    computed: {
      classList () {
        return {
          _disabled: this.disabled
        };
      }
    }
  };
</script>

<style>
  .m-table-cell__icon {
    cursor: pointer;

    display: flex;

    align-items: center;

    padding: 10px 12px;

    &._disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .m-table-cell__icon svg {
    height: 16px;
  }

  .cell-icon-title {
    overflow: hidden;

    display: flex;

    align-items: flex-end;

    margin-left: 5px;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .m-table-cell__icon-img {
    flex-shrink: 0;
  }
</style>
