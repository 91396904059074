<template>
  <transition name="breadcrumb">
    <Breadcrumbs
      v-show="path.length"
      :root-el="rootEl"
      :path="path"
    />
  </transition>
</template>

<script>

  // this component is just for updating path in breadcrumbs component
  import appContext from 'legacy/appcontext';
  import {mapGetters} from 'vuex';
  import Breadcrumbs from 'layouts/features/Breadcrumbs/Breadcrumbs';
  import translation from 'translation';

  export default {
    name: 'BreadcrumbsObserver',
    components: {Breadcrumbs},
    data () {
      return {
        rootEl: {
          name: translation.tr('All accounts'),
          to: {name: 'accounts'}
        }
      };
    },
    computed: {
      ...mapGetters('accounts', ['accountPath', 'currentAccount']),
      ...mapGetters('projects', ['currentProject']),
      ...mapGetters('routing', ['currentAccountUrlName', 'currentProjectUrlName']),
      path () {
        const path = this.accountPath.map(this.formatAccount);
        if (this.currentAccount) {
          path.push(this.formatAccount(this.currentAccount));
        }
        if (this.currentProject) {
          path.push(this.formatProject(this.currentProject));
        }
        return path;
      }
    },
    methods: {
      formatAccount (account) {
        return {
          name: account.name,
          id: account.id,
          url: this.getAccountLink(this.currentAccountUrlName, account.id),
          type: 'account'
        };
      },
      formatProject (project) {
        return {
          name: project.name,
          id: project.id,
          accountId: project.account_id,
          url: this.getProjectLink(this.currentProjectUrlName, project.account_id, project.id),
          type: 'project'
        };
      },
      getAccountLink (route, accountId) {
        return appContext.url(route, {account_id: accountId});
      },
      getProjectLink (route, accountId, projectId) {
        return appContext.url(route, {account_id: accountId, project_id: projectId});
      }
    }
  };
</script>

<style scoped>
  .breadcrumb-enter-active,
  .breadcrumb-leave-active {
    transition: all var(--transition-duration);
  }

  .breadcrumb-enter,
  .breadcrumb-leave-to {
    opacity: 0;
  }
</style>
