<template>
  <div
    :class="{'skeleton-wrapper_active': isLoading}"
    class="skeleton-wrapper"
  >
    <div
      v-if="isLoading"
      :class="{'skeleton-wrapper_rounded': roundBorders}"
      :style="{width: width, height: height, 'margin-top': marginTop}"
      class="skeleton-wrapper__loader"
    />
    <slot v-if="!isLoading" />
  </div>
</template>

<script>
  export default {
    name: 'SkeletonLoader',
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      height: {
        type: String,
        default: '32px'
      },
      width: {
        type: String,
        default: '375px'
      },
      roundBorders: {
        type: Boolean,
        default: false
      },
      marginTop: {
        type: String,
        default: '0'
      }
    }
  };
</script>

<style>
  .skeleton-wrapper_rounded {
    border-radius: 10px;
  }

  .skeleton-wrapper__loader {
    overflow: hidden;

    position: relative;

    background-color: var(--skeleton-loader-background-color);
  }

  .skeleton-wrapper__loader::after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0,
        rgba(200, 200, 200, 0.2) 30%,
        rgba(200, 200, 200, 0.3) 50%,
        rgba(0, 0, 0, 0)
      );

    transform: translateX(-100%);

    animation: skeleton-loader 2500ms infinite;

    @keyframes skeleton-loader {
      100% {
        transform: translateX(100%);
      }
    }
  }

</style>
