<template>
  <div>
    <div
      v-if="showBreadcrumbs"
      class="header-breadcrumbs"
    >
      <BreadcrumbsObserver />
    </div>
    <div
      v-else
      class="breadcrumbs-spacer-block"
    />
    <div class="header-tabs__tabs">
      <Tabs
        :tabs="richTabs"
        :value="activeTabId"
      />
      <h3
        v-if="activeTabTitle"
        class="active-tab-header"
      >
        {{ activeTabTitle }}
        <MHelpPageHelpLink
          v-if="tabReference"
          :reference-link="referenceLink"
          :active-tab="activeTab"
          :reference-onboarding="referenceOnboarding"
          :reference-title="referenceTitle"
          class="active-tab-help-link"
        />
      </h3>
    </div>
    <!-- container for legacy subheaders -->
    <div id="header" />
  </div>
</template>

<script>
  import Tabs from 'components/common/Tabs/Tabs';
  import BreadcrumbsObserver from 'layouts/features/Breadcrumbs/BreadcrumbsObserver';
  import HeaderTabMenuItem from 'components/common/HeaderTabs/HeaderTabMenuItem';
  import MHelpPageHelpLink from 'components/common/HeaderTabs/MHelpPageHelpLink';
  import {referenceDictionary} from 'components/common/HeaderTabs/referenceDictionary';
  import checkRoutePermissions from 'router/utils/checkRoutePermissions';
  import {mapGetters} from 'vuex';

  import _ from 'lodash';

  _.noConflict();

  export default {
    name: 'HeaderTabs',
    components: {
      BreadcrumbsObserver,
      Tabs,
      MHelpPageHelpLink
    },
    props: {
      showBreadcrumbs: {
        type: Boolean,
        default: false
      },
      tabsList: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        referenceDictionary: referenceDictionary()
      };
    },
    computed: {
      ...mapGetters('user', ['currentUser']),
      richTabs () {
        return this.tabsList.map(tab => ({
          ...tab,
          items: tab.items ? this.enrichTabMenuItems(tab.items) : undefined
        })).filter(i => i.to ? this.checkRoutePermissions(i) : i.items && i.items.length > 0);
      },
      tabsFlat () {
        return this.richTabs.map(tab => {
          if (tab.items) {
            return tab.items;
          }
          return tab;
        }).flat();
      },
      tabIdRouterPathMap () {
        /*
         * we need to get tab path, because vue-router doesn't allow us to get active route parent by name
         * in $route.matched all parent routes has no name specified,
         * so the only way to match routes is by path (regex-like paths)
         */
        const map = {};
        this.tabsFlat.forEach(t => {
          const resolved = this.$router.resolve(t.to);
          const matchedRoutes = resolved.resolved.matched;

          // get last route from matched to get target route regex
          let targetRoute = matchedRoutes[matchedRoutes.length - 1];
          let targetPath = targetRoute.path;

          // remove latest `/` if it is present
          if (targetPath[targetPath.length - 1] === '/') {
            targetPath = targetPath.slice(0, targetPath.length - 1);
          }

          map[t.id] = targetPath;
        });
        return map;
      },
      activeTab () {
        const currentRoute = this.$route;
        const matchedPaths = new Set(currentRoute.matched.map(m => m.path));

        let matchedTab = null;

        for (let tabItem of this.tabsFlat) {

          /*
           * `accounts/:account-id/create`
           * if you need active `accounts` tab in routes like above, add route name in forceActiveByRoutes
           */
          if (tabItem.forceActiveByRoutes && tabItem.forceActiveByRoutes.includes(currentRoute.name)) {
            return tabItem;
          }

          // deal with tab that is active only when route name matches
          if (tabItem.exactMatch) {
            if (tabItem.to.name === this.$route.name) {
              return tabItem;
            }

            /*
             * project/static/frontend/src/layouts/nested/account/AccountsNestedPage.vue:29
             * `accounts` always have exactMatch
             * we need continue to prevent `accounts` ba active all the time
             */
            continue;
          }
          const tabPath = this.tabIdRouterPathMap[tabItem.id];

          // do not return matched by path tab, because next tab in array may be matched by name
          if (matchedPaths.has(tabPath)) {
            matchedTab = tabItem;
          }
        }

        if (matchedTab) {
          return matchedTab;
        }

        // if not found by route - try to find by nestedName in route parents
        const matchedNested = new Set(currentRoute.matched.map(m => m.meta.nestedName).filter(x => Boolean(x)));
        const nestedNameTabs = this.tabsFlat.filter(t => Boolean(t.matchBy));

        for (let tab of nestedNameTabs) {
          const nestedName = tab.matchBy;
          if (matchedNested.has(nestedName)){
            return tab;
          }
        }

        return null;
      },
      activeTabTitle () {
        if (this.activeTab) {
          if (this.activeTab.forceRouteTitles) {
            const forcedTitle = this.activeTab.forceRouteTitles[this.$route.name];

            // check for undefined, because it can be overridden with empty string (to hide header)
            if (forcedTitle !== undefined) {
              return forcedTitle;
            }
          }
          return this.activeTab.title;
        }
        return null;
      },
      activeTabId () {
        if (this.activeTab) {
          return this.activeDropdownTabId || this.activeTab.id;
        }
        return null;
      },
      nestedTabsMap () {
        const nestedMap = {};
        this.richTabs.forEach(tab => {
          if (tab.items) {
            tab.items.forEach(subTab => {
              nestedMap[subTab.id] = tab;
            });
          }
        });
        return nestedMap;
      },
      activeNestedParentTab () {
        if (!this.activeTab) {
          return null;
        }
        return this.nestedTabsMap[this.activeTab.id];
      },
      activeDropdownTabId () {
        return this.activeNestedParentTab ? this.activeNestedParentTab.id : null;
      },

      tabReference () {
        if (!this.activeTab) {
          return null;
        }

        // check reference for route, if not available - return by tab id
        if (this.referenceDictionary[this.$router.currentRoute.name]) {
          return this.referenceDictionary[this.$router.currentRoute.name];
        }

        return this.referenceDictionary[this.activeTab.id];
      },
      baseHelpUrl () {
        return this.currentUser.help_url;
      },
      referenceLink () {
        if (!this.tabReference || !this.baseHelpUrl) {
          return null;
        }
        return `${this.baseHelpUrl}${this.tabReference.helpLink}`;
      },
      referenceTitle () {
        if (!this.tabReference) {
          return null;
        }
        return this.tabReference.helpTitle;
      },
      referenceOnboarding () {
        if (!this.tabReference) {
          return null;
        }

        return this.tabReference.onboarding;
      }
    },
    methods: {
      checkRoutePermissions (item) {
        const route = this.$router.resolve(item.to).route;
        return checkRoutePermissions(route);
      },
      enrichTabMenuItems (item) {
        return item.map(item => ({
          ...item,
          component: HeaderTabMenuItem
        })).filter(i => this.checkRoutePermissions(i));
      }
    }
  };
</script>

<style>
  .breadcrumbs-spacer-block {
    margin-top: 62px;
  }

  .header-tabs__tabs {
    margin-top: 16px;
  }

  .active-tab-header {
    font-family: var(--main-font-family);
    font-size: 18px;
    font-weight: var(--medium-font-weight);

    line-height: normal;

    display: block;

    height: auto;

    margin: 25px 0;
  }

  .active-tab-help-link {
    font-size: 12px;
    font-weight: 400;

    margin-left: 16px;
  }

</style>
