<template>
  <MTableCell
    class="m-table-cell__account-type"
  >
    {{ value }}
  </MTableCell>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {mTableCells} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTableCellAccountType',
    components: {
      MTableCell: mTableCells.MTableCell
    },
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      ...mapGetters('enums', ['getAccountTypeById']),
      value () {
        const type = this.getAccountTypeById(this.tableRow[this.itemKey]);
        return type ? type.name : '';
      }
    }
  };
</script>

<style>
  .m-table-cell__account-type {
    padding: 12px 8px;
  }

</style>
