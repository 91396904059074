<template>
  <MMenu
    v-model="menuOpen"
    :class="{'tabs__tab_active': isActive}"
    :items="localItems"
    :popper-class="popperClass"
    placement="bottom-start"
    :popper-options="{
      modifiers: {
        preventOverflow: {
          boundariesElement: 'viewport',
          priority: ['right', 'left', 'top', 'bottom'],
          padding: 16
        },
        flip: {
          enabled: false
        },
        offset: {
          offset: '0, -2'
        }
      }
    }"
    @item-click="handleMenuClick"
  >
    {{ title }}
  </MMenu>
</template>

<script>
  import MMenu from 'legacy/modules/common/components/MMenu';
  import MMenuLinkItem from 'legacy/modules/common/components/MMenuLinkItem';

  export default {
    name: 'TabGroup',
    components: {MMenu},
    props: {
      groupId: {
        type: String,
        default: null
      },
      title: {
        default: String,
        type: String
      },
      items: {
        default: Array,
        type: Array
      },
      popperClass: {
        type: MMenu.props.popperClass.type,
        default: MMenu.props.popperClass.default
      },
      activeTabId: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        menuOpen: false
      };
    },
    computed: {
      localItems () {
        return this.items.map(item => {
          item.component = item.component || MMenuLinkItem;
          item.active = false;

          /*
           * item.active = item.id === this.activeTabId || item.url.name === this.$route.name ||
           *   this.isSubRouteMatch(item.subRoutes);
           */
          item.props = {
            id: item.id,
            name: item.title,
            to: item.to,
            url: item.url ? item.url.href : null,
            active: item.active,
            callback: item.callback,
            className: item.className,
            icons: item.icons || [],
            subRoutes: item.subRoutes
          };

          return item;
        });
      },
      activeItem () {
        return this.localItems.find(item => {
          if (item.active || this.isSubRouteMatch(item.props.subRoutes)) {
            return item;
          }
        });
      },
      isActive () {
        return this.groupId === this.activeTabId || Boolean(this.activeItem);
      }
    },
    watch: {
      activeItem () {
        if (this.activeItem) {
          this.$emit('active-tab', this.activeItem);
          return;
        }
        this.$emit('active-tab', '');
      }
    },
    beforeDestroy () {
      this.$emit('active-tab', '');
    },
    methods: {
      handleMenuClick (item) {
        this.menuOpen = false;
        this.$emit('openInTab', item);
      },
      isSubRouteMatch (subRoutes) {
        return subRoutes && subRoutes.some(x => x === this.$route.name);
      }
    }
  };
</script>

<style>
  .header-menu-tabs__tab_group:hover {
    cursor: pointer;
  }
</style>
