<template>
  <Statistics
    :account-id="accountId"
    :project-id="projectId"

    statistics-table-name="detailed-statistics-table"

    :is-loading="isLoading"
    :webanalytics-disabled="webanalyticsDisabled"
    :webanalytics-disabled-message="webanalyticsDisabledMessage"

    :channels-list="channelsList"
    :date-grouping-choices="dateGroupingChoices"
    :grouping-choices="groupingChoices"
    :filter-type-choices="filterTypeChoices"
    :filter-choices-type-map="filterChoicesTypeMap"
    :profiles-list="filteredProfiles"
    :goals-list="filteredGoals"

    :load-available-metrics="fetchMetricsOnProfilesChange"
    :statistics-available-metrics="statisticsAvailableMetrics"
    :metrics-are-loading="isMetricsLoading"

    :trigger-fetch-by-columns-change="true"

    :statistics-columns-getter="statisticsColumnsGetter"
    class="container seller-statistics-page"
  />
</template>

<script>
  import PropsDependentPage from 'pages/mixins/PropsDependentPage';
  import StatisticsPageMixin from 'pages/seller/statistics/StatisticsPageMixin';
  import {statisticsService} from 'services';
  import _ from 'lodash';
  import Statistics from 'pages/seller/statistics/features/Statistics';
  import StatisticsCommon from 'pages/seller/statistics/common';
  import detailedStatisticsColumnsGetter from 'pages/seller/statistics/utils/detailedStatisticsColumnsGetter';
  import {mapActions, mapGetters} from 'vuex';
  import {FETCH_CHANNELS_CURRENT_USER} from 'store/modules/seller/campaignsSeller';

  _.noConflict();


  export default {
    name: 'StatisticsPage',
    components: {Statistics},
    mixins: [StatisticsPageMixin, PropsDependentPage],
    props: {
    },
    data () {
      /*
       * reactive variables are slow (if there are > than 2kk metrics, page loading may take 1min,
       * and this is only processing fetched data)
       */
      this.statisticsAvailableMetrics = [];

      return {
        isMetricsLoading: false,

        adFormatsList: [],

        prevProfilesList: [],

        statisticsColumnsGetter: () => []
      };
    },
    computed: {
      ...mapGetters('sellerUser', {
        accountId: 'getAccountId',
        projectId: 'getProjectId'
      }),

      webanalyticsDisabledMessage () {
        if (!this.isLoading && this.profilesList.length === 0) {
          return 'You have no web analytics profiles';
        }
        return '';
      },

      groupingChoices () {
        return StatisticsCommon.getGroupingChoices();
      },
      filterTypeChoices () {
        return StatisticsCommon.getFilterTypeChoices();
      },
      filterChoicesTypeMap () {
        return StatisticsCommon.getFilterChoicesByType(
          {
            channels: this.channelsList,
            placements: this.placementsList,
            orders: this.ordersList,
            projects: this.projectsList,
            labels: this.labelsList,
            accounts: this.accountsList
          }
        );
      },

      supportedProviderIds () {
        return [];
      },
      filteredProfiles () {
        return this.profilesList.filter(
          item => this.supportedProviderIds.includes(item.provider_id)
        );
      },
      filteredGoals () {
        const profilesIdsSet = new Set();
        this.profilesList.forEach(item => profilesIdsSet.add(item.id));

        return this.goalsList.filter(item => profilesIdsSet.has(item.profile_id));
      },
      adFormatsByChannelId () {
        const adFormatsGroupedByChannelId = _.groupBy(this.adFormatsList, 'channel_id');
        return _.mapValues(adFormatsGroupedByChannelId, formatsList => _.keyBy(formatsList, 'id'));
      }
    },
    methods: {
      ...mapActions('sellerCampaigns', {
        fetchChannelsCurrentUser: FETCH_CHANNELS_CURRENT_USER
      }),
      fetchDataAsync () {
        return [
          ...this.fetchCommonData(),

          this.fetchAdFormatsByChannelId(),
          this.fetchLabels(),
          this.fetchChannelsCurrentUser(),

          this.fetchDetailedStatisticsAvailableMetrics()
        ];
      },
      async fetchMetricsOnProfilesChange (selectedProfileIds) {
        // fetch only if profiles list changed
        if (!_.isEqual(_.sortBy(selectedProfileIds), _.sortBy(this.prevProfilesList))) {
          await this.fetchDetailedStatisticsAvailableMetrics(selectedProfileIds);
          this.prevProfilesList = selectedProfileIds;
        }
      },
      async fetchAdFormatsByChannelId () {
        this.adFormatsList = [];
      },
      async fetchDetailedStatisticsAvailableMetrics (profiles = []) {
        this.isMetricsLoading = true;
        if (this.isOnProjectLevel) {
          this.statisticsAvailableMetrics = await statisticsService.getProjectDetailedMetricsList(
            this.accountId, this.projectId, profiles);
        } else {
          this.statisticsAvailableMetrics = await statisticsService.getDetailedStatisticsMetricsList(
            {accountId: this.accountId, profiles});
        }
        this.buildColumnsGetter(this.statisticsAvailableMetrics);
        this.isMetricsLoading = false;
      },
      buildColumnsGetter (statisticsAvailableMetrics) {
        this.statisticsColumnsGetter = ({groupings, dateGrouping, channelIds}) =>
          detailedStatisticsColumnsGetter({
            availableMetrics: statisticsAvailableMetrics,
            groupings,
            dateGrouping,
            customMetrics: this.customMetricsList,
            channelIds
          });
      }
    }
  };
</script>

 <style>
  .seller-statistics-page {
    *:focus-visible {
      outline: none;
    }
  }
 </style>
