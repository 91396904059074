<template>
  <div>
    <div v-if="disabled">
      <div class="dropdown-menu__trigger dropdown-menu__trigger_disabled">
        <MIcon
          class="dropdown-menu__icon"
          :icon="icon"
        />
        <MIcon
          class="dropdown-menu__icon dropdown-menu__icon-arrow"
          :icon="bulletArrow"
        />
      </div>
    </div>
    <BasePopover
      v-else
      ref="popover"
      class="dropdown-menu__trigger"
      placement="bottom-start"
      :popper-class="popperClass"
      :fix-width="fixWidth"
      @toggle="active = $event"
    >
      <template slot="reference">
        <div class="dropdown-menu__trigger">
          <span v-if="dropdownText">
            {{ dropdownText }}
          </span>
          <MIcon
            v-if="icon"
            class="dropdown-menu__icon"
            :icon="icon"
          />
          <MIcon
            class="dropdown-menu__icon dropdown-menu__icon-arrow"
            :class="{ 'dropdown-menu__icon-arrow_active': active }"
            :icon="bulletArrow"
          />
        </div>
      </template>
      <template slot="popper">
        <slot />
      </template>
    </BasePopover>
  </div>
</template>

<script>
  import {BasePopover, MIcon} from '@marilyn/marilyn-ui';
  import bulletArrow from 'assets/icons/bullet-arrow.svg';

  export default {
    name: 'DropdownMenu',
    components: {BasePopover, MIcon},
    props: {
      icon: {
        type: Object,
        default: null
      },
      fixWidth: {
        type: Boolean,
        default: false
      },
      popperClass: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      dropdownText: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        bulletArrow,
        active: false
      };
    },
    methods: {
      closeDropdown () {
        this.$refs.popover.closeDropdown();
      }
    }
  };
</script>

<style>
  .dropdown-menu__trigger {
    cursor: pointer;

    display: inline-flex;

    align-items: center;

    height: 18px;
  }

  .dropdown-menu__icon {
    color: var(--action-color);

    width: 18px;
    height: 18px;
  }

  .dropdown-menu__icon-arrow {
    width: 14px;
    height: 14px;

    transform: rotate(90deg);
  }

  .dropdown-menu__icon-arrow_active {
    transform: rotate(270deg);
  }

  .dropdown-menu__trigger:hover .dropdown-menu__icon {
    color: var(--action-hover-color);
  }

  .dropdown-menu__trigger.dropdown-menu__trigger_disabled .dropdown-menu__icon,
  .dropdown-menu__trigger.dropdown-menu__trigger_disabled:hover .dropdown-menu__icon {
    color: var(--gray-color);
  }

</style>
