<template>
  <div class="not-found">
    <m-icon
      class="not-found__image"
      :icon="notFoundImg"
    />
    <div class="not-found__header">
      {{ _('Page not found') }}
    </div>
    <div class="not-found__details">
      {{ _('This page does not exists') }}
    </div>
    <m-button
      class="not-found__back-button"
      @click="goBack"
    >
      {{ goBackText }}
    </m-button>
  </div>
</template>

<script>
  import notFoundImg from 'assets/illustrations/forbidden.svg';
  import {mapGetters} from 'vuex';

  export default {
    name: 'NotFound',
    data () {
      return {
        notFoundImg
      };
    },
    computed: {
      ...mapGetters('routing', ['prevRoute']),
      hasPrevRoute () {
        return this.prevRoute && this.prevRoute.name;
      },
      backRoute () {
        if (this.hasPrevRoute) {
          return this.prevRoute;
        }
        return {name: 'home'};
      },
      goBackText () {
        if (this.hasPrevRoute) {
          return this._('Back');
        }
        return this._('To accounts list');
      }
    },
    methods: {
      goBack () {
        return this.$router.push(this.backRoute);
      }
    }
  };
</script>

<style>
  /* @define not-found */
  .not-found {
    display: flex;

    flex-direction: column;
    align-items: center;
  }

  .not-found__image {
    width: 461px;
    height: 372px;

    margin-top: 52px;
  }

  .not-found__header {
    font-size: 30px;
    font-weight: var(--medium-font-weight);

    color: var(--text-color);
    line-height: 35px;

    margin-top: 42px;
  }

  .not-found__details {
    font-size: var(--title-font-size);

    color: var(--panel-text-color);
    line-height: 22px;

    margin-top: 14px;
  }

  .not-found .not-found__back-button {
    font-size: 20px;

    line-height: 22px;

    display: flex;

    justify-content: center;

    width: 220px;
    height: 44px;

    margin-top: 36px;

    border-radius: 22px;
  }
</style>
