<template>
  <div class="forbidden">
    <m-icon
      class="forbidden__image"
      :icon="forbiddenImg"
    />
    <div class="forbidden__header">
      {{ _('Not enough rights') }}
    </div>
    <span class="forbidden__details">
      {{ _("You don't have enough rights to view this page.") }}
      {{ _('Contact your manager to get correct access') }}
    </span>
    <m-button
      class="forbidden__back-button"
      @click="goBack"
    >
      {{ _('To accounts list') }}
    </m-button>
  </div>
</template>

<script>
  import forbiddenImg from 'assets/illustrations/forbidden.svg';

  export default {
    name: 'Forbidden',
    data () {
      return {
        forbiddenImg
      };
    },
    methods: {
      goBack () {
        return this.$router.push({name: 'home'});
      }
    }
  };
</script>

<style>
  /* @define forbidden */
  .forbidden {
    display: flex;

    flex-direction: column;
    align-items: center;
  }

  .forbidden__image {
    width: 461px;
    height: 372px;

    margin-top: 52px;
  }

  .forbidden__header {
    font-size: 30px;
    font-weight: var(--medium-font-weight);

    color: var(--text-color);
    line-height: 35px;

    margin-top: 42px;
  }

  .forbidden__details {
    font-size: var(--title-font-size);

    color: var(--panel-text-color);
    line-height: 22px;

    text-align: center;

    white-space: pre-line;

    margin-top: 14px;
  }

  .forbidden .forbidden__back-button {
    font-size: 18px;

    line-height: 22px;

    display: flex;

    justify-content: center;

    height: 44px;

    padding: 10px 34px;

    margin-top: 36px;

    border-radius: 22px;
  }
</style>
