<template>
  <div
    class="dashboard-card"
  >
    <div class="dashboard-card-header">
      <div
        class="dashboard-card-header__name"
        :class="{'dashboard-card-header__name_small': fillAllSpaceWithContent}"
      >
        <slot name="header" />
      </div>
      <div
        v-if="!readonly"
        class="dashboard-card-header__controls"
      >
        <m-icon
          class="dashboard-card-controls__icon dashboard-card-controls__edit"
          :icon="iconGear"
          @click="$emit('edit')"
        />
        <m-icon
          class="dashboard-card-controls__icon dashboard-card-controls__drag-handle"
          :icon="iconDots"
          @mousedown.native="$emit('drag', $event)"
        />
      </div>
    </div>
    <div
      :class="{'dashboard-card__contents_fill-all': fillAllSpaceWithContent}"
      class="dashboard-card__contents"
    >
      <SpinnerContainer
        v-if="isLoading"
        :is-loading="isLoading"
        :offset-y="spinnerOffsetY"
        class="dashboard-card__loader"
      />
      <template v-else>
        <slot />
      </template>
    </div>
  </div>
</template>

<script>
  import iconGear from 'assets/icons/gear.svg';
  import iconDots from 'assets/icons/drag-dots.svg';
  import {SpinnerContainer} from '@marilyn/marilyn-ui';

  export default {
    name: 'DashboardCard',
    components: {SpinnerContainer},
    props: {
      fillAllSpaceWithContent: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      readonly: Boolean
    },
    data () {
      return {
        iconGear,
        iconDots
      };
    },
    computed: {
      spinnerOffsetY () {
        return this.fillAllSpaceWithContent ? 0 : 20;
      }
    }
  };
</script>

<style>
  .dashboard-card {
    position: relative;

    background: var(--background-color);

    box-shadow: var(--floating-card-shadow);

    border-radius: 4px;
  }

  .dashboard-card-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    z-index: 2;

    flex-direction: row;
    align-items: center;

    box-sizing: border-box;
    width: 100%;

    padding: 10px 20px;
  }

  .dashboard-card-header__name {
    font-size: 16px;
    font-weight: var(--medium-font-weight);

    color: var(--text-color);
    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    width: 85%;
  }

  .dashboard-card-header__name_small {
    font-size: 12px;
    font-weight: var(--main-font-weight);

    color: var(--secondary-text-color);
  }

  .dashboard-card-header__controls {
    display: flex;

    flex-direction: row;

    margin-left: auto;
  }

  .dashboard-card-controls__edit {
    cursor: pointer;
    margin-right: 6px;
  }

  .dashboard-card-controls__edit:hover {
    color: var(--panel-text-color);
  }

  .dashboard-card-controls__icon {
    color: var(--gray-text-color);

    width: 16px;
    height: 16px;
  }

  .dashboard-card__contents {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    padding: 52px 0 0;
  }

  .dashboard-card__contents_fill-all {
    padding: 0;
  }

  .dashboard-card__loader .spinner-container__overlay {
    z-index: 0;
  }

  .dashboard-card-controls__drag-handle {
    cursor: move;
  }
</style>
