<template>
  <div class="shop-seller-list">
    <div>
      <h2 class="shop-seller-list__title">
        Магазины
      </h2>

      <div class="shop-seller-list__items">
        <ShopCardItem
          is-create-card
          :shop="{}"
          @create="handleOpenModalCreateShop"
        />

        <template v-if="appLoading">
          <SkeletonLoader
            v-for="(_, index) in Array(3)"
            :key="index"
            :is-loading="true"
            width="100%"
            height="100%"
            round-borders
          />
        </template>

        <template v-if="!appLoading">
          <ShopCardItem
            v-for="item in items"
            :key="item.id"
            :shop="item"
            @delete="handleDeleteShop(item)"
            @recover="handleRestoreShop(item)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import ShopCardItem from 'components/seller/shop/ShopCardItem';
  import {DELETE_SHOP} from 'store/modules/seller/shopsSeller';
  import {mapActions, mapGetters} from 'vuex';
  import MModalMixin from 'modules/common/components/MModal/MModalMixin';
  import CreateSellerShopModal from 'components/seller/shop/CreateSellerShopModal';
  import {closeAllNotifications} from 'components/common/MNotification/MNotificationManager';

  export default {
    name: 'ShopSellerList',
    components: {
      ShopCardItem
    },
    mixins: [MModalMixin],
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        modalComponent: CreateSellerShopModal,
        modalProps: {},
        modalEventListeners: {
          closeModal: this.handleCloseModal,
          'update-current-page': () => this.$emit('update-current-page')
        }
      };
    },
    computed: {
      ...mapGetters('sellerCommon', ['appLoading'])
    },
    methods: {
      ...mapActions('sellerShops', {
        deleteShop: DELETE_SHOP
      }),
      handleCloseModal () {
        closeAllNotifications();
        this.hidePopup();
      },
      handleDeleteShop (shop) {
        this.$prompt.danger({
          bodyText: `Отвязать магазин «${shop.name}» от Marilyn?\n
          Стратегии перестанут работать — управлять ставками снова нужно будет на маркетплейсе.`,
          okText: 'Отвязать',
          cancelText: 'Отменить',
          sizeModal: 'small'
        }).then(() => this.deleteShop(shop.id)).
          catch(() => { /* Do nothing */ });
      },
      handleRestoreShop (shop) {
        this.modalProps = {
          isRecover: true,
          recoverShop: shop
        };

        this.showPopup();
      },
      handleOpenModalCreateShop () {
        this.modalProps = {
          isRecover: false
        };

        this.showPopup();
      }
    }
  };
</script>

<style>
  .shop-seller-list__title {
    color: #000000;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.03em;
    margin: 0 0 32px 0;
  }

  .shop-seller-list__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
</style>
