<template>
  <div>
    <OnboardingTextSection>
      Внизу боковой панели вы найдете:
    </OnboardingTextSection>

    <div class="sidebar-bottom-section-step-wrapper">
      <OnboardingImageSection
        class="sidebar-bottom-section-step__mary"
        width="135px"
        :image="speakingMary"
      />
      <div class="sidebar-bottom-section-step__grid">
        <div class="sidebar-onboarding-grid__icon">
          <MIcon
            :icon="profileIcon"
          />
        </div>
        <div class="sidebar-onboarding-grid__text">
          <ul>
            <li>профиль пользователя</li>
            <li>выход из системы</li>
          </ul>
        </div>
        <div class="sidebar-onboarding-grid__icon">
          <MIcon
            :icon="helpIcon"
          />
        </div>
        <div class="sidebar-onboarding-grid__text">
          <ul>
            <li>подсказки в системе</li>
            <li v-if="userHelpUrl">
              частые вопросы и справка
            </li>
          </ul>
        </div>
        <div class="sidebar-onboarding-grid__icon">
          <MIcon
            :icon="foldIcon"
          />
        </div>
        <div class="sidebar-onboarding-grid__text">
          <ul>
            <li>свернуть боковую панель</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OnboardingTextSection from 'onboarding/onboardings/components/OnboardingTextSection';
  import OnboardingImageSection from 'onboarding/onboardings/components/OnboardingImageSection';
  import {MIcon} from '@marilyn/marilyn-ui';
  import store from 'store';

  import speakingMary from 'assets/mascot/mary-speaking.png';
  import profileIcon from 'assets/icons/profile.svg';
  import helpIcon from 'assets/icons/help.svg';
  import foldIcon from 'assets/icons/fold.svg';


  export default {
    name: 'SidebarBottomSectionStep',
    components: {OnboardingImageSection, OnboardingTextSection, MIcon},
    data () {
      return {
        speakingMary,
        profileIcon,
        helpIcon,
        foldIcon
      };
    },
    computed: {
      userHelpUrl () {
        // not using mapGetters because tour items has no store (mounted as separate vue-app instance)
        return store.getters['user/userHelpUrl'];
      }
    }
  };
</script>

<style>
  .sidebar-bottom-section-step-wrapper {
    display: flex;
  }

  .sidebar-bottom-section-step__mary img {
    height: 122px;
  }

  .sidebar-bottom-section-step__grid {
    display: grid;

    grid-template-columns: 20px auto;

    margin-left: 20px;
  }

  .sidebar-onboarding-grid__icon {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .sidebar-onboarding-grid__icon svg {
    width: 16px;
    height: 16px;
  }

  .sidebar-onboarding-grid__text {
    display: flex;
    align-items: center;
  }

  .sidebar-onboarding-grid__text ul {
    margin: 0 0 0 25px;
  }
</style>
