<template>
  <div
    class="shop-card-item"
    :data-channel-id="shop.channel_id"
    :class="{
      '_is_invalid': isInvalid
    }"
  >
    <div>
      <div
        v-if="isCreateCard"
        class="shop-card-item__create-content"
      >
        <div class="shop-card-item__create-text">
          Добавьте магазин, чтобы сервис автоматически управлял ставками
          ваших рекламных кампаний и повышал их эффективность
        </div>

        <MButton
          class="shop-card-item__create-button"
          @click="$emit('create')"
        >
          <div class="shop-card-item__create-button__content">
            <span class="shop-card-item__create-button__plus">
              +
            </span>
            <span>
              Добавить магазин
            </span>
          </div>
        </MButton>
      </div>

      <div
        v-else
        class="shop-card-item__content"
      >
        <div class="shop-card-item__header">
          <div class="shop-card-item__header-left_content">
            <MProviderIcon
              :icon="channelIcon"
              class="shop-card-item__header-logo"
            />

            <div
              v-if="isInvalid"
              class="shop-card-item__header-invalid"
            >
              <MIcon :icon="warningIcon" />
              <span>Потерян доступ к магазину</span>
            </div>
          </div>

          <a
            :href="channelUrl"
            target="_blank"
            class="shop-card-item__header-share"
          >
            <MProviderIcon
              :icon="shareIcon"
              class="shop-card-item__header-share-logo"
            />
          </a>
        </div>

        <div class="shop-card-item__info">
          <h5
            class="shop-card-item__info-title"
            :aria-label="shop.name"
            :title="shop.name"
          >
            {{ shop.name }}
          </h5>

          <span
            class="shop-card-item__info-token"
            :aria-label="shop.customer_xid"
            :title="shop.customer_xid"
          >
            {{ shop.customer_xid }}
          </span>
        </div>

        <div class="shop-card-item__actions">
          <MButton
            v-if="isInvalid"
            secondary
            @click="$emit('recover')"
          >
            Восстановить
          </MButton>

          <MButton
            secondary
            @click="$emit('delete')"
          >
            Отвязать
          </MButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {MProviderIcon} from '@marilyn/marilyn-ui';
  import shareIcon from 'assets/icons/share-icon.svg';
  import warningIcon from 'assets/icons/warning.svg';
  import {getIconConnectionById, getUrlMarketplaceMainCabinetById} from 'utils/seller/sellerConnectionData';

  export default {
    name: 'ShopCardItem',
    components: {MProviderIcon},
    props: {
      shop: {
        type: Object,
        required: true
      },
      isCreateCard: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        shareIcon,
        warningIcon
      };
    },
    computed: {
      channelIcon () {
        if (this.isCreateCard) {
          return null;
        }

        return getIconConnectionById(this.shop.channel_id);
      },
      channelUrl () {
        if (this.isCreateCard) {
          return null;
        }

        return getUrlMarketplaceMainCabinetById(this.shop.channel_id);
      },
      isInvalid () {
        if (this.isCreateCard) {
          return false;
        }

        return this.shop.status !== 'ACTIVE';
      }
    }
  };
</script>

<style>
  .shop-card-item {
    width: 325px;
    height: 194px;

    padding: 24px;

    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 #A3A3A340;

    border-radius: 4px;

    &._is_invalid {
      border: 1px solid #C6474F;
    }
  }

  .shop-card-item__create-content {
    display: inline-flex;

    flex-direction: column;
    align-items: center;
  }

  .shop-card-item__create-text {
    font-size: 14px;
    font-weight: 400;

    color: #A8A8A8;
    line-height: 20px;

    text-align: center;

    letter-spacing: -0.03em;

    margin-bottom: 24px;
  }

  .shop-card-item__create-button__content {
    font-size: 14px;
    font-weight: 400;

    color: #FFFFFF;
    line-height: 16.41px;

    display: flex;

    align-items: center;

    .shop-card-item__create-button__plus {
      font-size: 16px;

      line-height: unset;

      margin: 0 8px 0 0;
    }
  }

  .shop-card-item__header-logo {
    width: 24px;
    height: 24px;
  }

  .shop-card-item__header {
    display: flex;

    justify-content: space-between;
    align-items: center;

    margin-bottom: 24px;
  }

  .shop-card-item__header-left_content {
    display: flex;
    align-items: center;
  }

  .shop-card-item__header-share {
    cursor: pointer;

    width: 20px;
    height: 20px;

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .shop-card-item__info {
    margin-bottom: 24px;
  }

  .shop-card-item__actions {
    display: flex;

    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  .shop-card-item__info-title {
    font-size: 16px;
    font-weight: 500;

    color: #242424;
    line-height: 18px;

    letter-spacing: -0.03em;

    margin: 0 0 8px;
  }

  .shop-card-item__info-token {
    font-size: 12px;
    font-weight: 400;

    color: #A8A8A8;
    line-height: 16px;

    letter-spacing: -0.03em;
  }

  .shop-card-item__info-title,
  .shop-card-item__info-token {
    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    display: block;
  }

  .shop-card-item__header-invalid {
    font-size: 12px;
    font-weight: 400;

    color: #C6474F;
    line-height: 14px;

    display: flex;

    margin-left: 8px;

    svg {
      width: 14px;
      height: 12px;
    }
  }
</style>
