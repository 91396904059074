<template>
  <DashboardCard
    :readonly="readonly"
    :is-loading="isLoading"
    class="stats-widget"
    :fill-all-space-with-content="Boolean(data.items.length)"
    @edit="$emit('edit')"
    @drag="$emit('drag', $event)"
  >
    <template slot="header">
      <WidgetHeader
        :name="argumentsValues.name"
        :metrics="argumentsValues.metrics"
        :profiles="profiles"
        :external-href="externalHref"
        @click="$emit('cancel')"
      />
    </template>
    <template v-if="data.items.length">
      <div class="stats-widget__summary">
        <MNumeric
          :value="summary"
          :type="metricType"
        />
        <span class="stats-widget__percentage">
          <MIcon
            class="stats-widget__percentage-triangle"
            :icon="triangleIcon"
            :class="{'stats-widget__percentage-triangle_inverted': summary < lastPeriodSummary}"
          />
          {{ summaryDifference }}%
        </span>
        <div class="stats-widget__previous-period">
          <strong>
            <MNumeric
              :value="lastPeriodSummary"
              :type="metricType"
            />
          </strong>
          {{ lastPeriodText }}
        </div>
      </div>
      <div class="stats-widget__chart">
        <StatsWidgetChart
          :statistics="data.items"
          :metric-type="metricType"
          :metric="targetMetric"
          :date-start="dateStart"
          :date-end="dateEnd"
        />
      </div>
    </template>
    <template v-else>
      <CardNoDataText />
    </template>
  </DashboardCard>
</template>

<script>
  import DashboardCard from 'components/Dashboards/Widgets/WidgetMockCards/DashboardCard';
  import MNumeric from 'legacy/modules/common/components/MNumeric/MNumeric';
  import StatsWidgetChart from 'components/Dashboards/Widgets/StatsWidget/StatsWidgetChart';
  import WidgetHeader from 'components/Dashboards/Widgets/WidgetHeader/WidgetHeader';
  import MIcon from 'legacy/modules/common/components/MIcon';
  import CardNoDataText from 'components/Dashboards/Widgets/WidgetMockCards/CardNoDataText';
  import triangleIcon from 'assets/icons/triangle.svg';
  import moment from 'moment-wrapper';
  import makeSimpleMetrics from 'legacy/modules/common/components/MetricSelect/makeSimpleMetrics';
  import keyBy from 'lodash.keyby';

  export default {
    name: 'StatsWidget',
    components: {
      StatsWidgetChart,
      MNumeric,
      DashboardCard,
      WidgetHeader,
      MIcon,
      CardNoDataText
    },
    props: {
      isLoading: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: Object
      },
      lastPeriodData: {
        type: Object,
        default: Object
      },
      lastPeriod: {
        type: Object,
        default: Object
      },
      dsParams: {
        type: Object,
        default: Object
      },
      argumentsValues: {
        type: Object,
        default: Object
      },
      metrics: {
        type: Object,
        default: Object
      },
      readonly: Boolean,
      profiles: {
        type: Array,
        default: Array
      },
      externalHref: {
        type: String,
        default: null
      }
    },
    data: () => ({
      triangleIcon
    }),
    computed: {
      targetMetric () {
        return this.argumentsValues.metrics[0];
      },
      dateStart () {
        return this.dsParams.dateStart;
      },
      dateEnd () {
        return this.dsParams.dateEnd;
      },
      summary () {
        if (this.data.total && this.data.total[this.targetMetric] !== undefined){
          const data = this.data.total[this.targetMetric];
          return isNaN(parseFloat(data)) ? 0 : parseFloat(data);
        }
        return 0;
      },
      lastPeriodSummary () {
        if (this.lastPeriodData.total[this.targetMetric] !== undefined){
          const data = this.lastPeriodData.total[this.targetMetric];
          return isNaN(parseFloat(data)) ? 0 : parseFloat(data);
        }
        return 0;
      },
      summaryDifference () {
        if (this.lastPeriodSummary === 0) {
          if (this.summary === 0) {
            return 0;
          }
          return 100;
        }

        return parseFloat((Math.abs(this.lastPeriodSummary - this.summary) / this.lastPeriodSummary * 100).toFixed(1));
      },
      metricType () {
        return this.metrics[this.targetMetric].type;
      },
      simpleMetrics () {
        return keyBy(makeSimpleMetrics(Object.values(this.metrics)), 'simple_id');
      },
      metricName () {
        const metric = this.metrics[this.targetMetric];
        return this.simpleMetrics[metric.simple_id].name;
      },
      lastPeriodText () {
        const dateStart = moment(this.lastPeriod.dateStart).formatDate();
        const dateEnd = moment(this.lastPeriod.dateEnd).formatDate();

        if (dateStart === dateEnd) {
          const translatedPer = this.pgettext('STATS_WIDGET', 'per');
          return `${translatedPer} ${dateStart}`;
        }

        const translatedFrom = this.pgettext('STATS_WIDGET', 'from');
        const translatedTo = this.pgettext('STATS_WIDGET', 'to');
        return `${translatedFrom} ${dateStart} ${translatedTo} ${dateEnd}`;
      }
    }
  };
</script>

<style>
  .stats-widget__summary {
    font-size: 26px;
    font-weight: var(--medium-font-weight);

    padding: 28px 20px 20px;
  }

  .stats-widget__percentage {
    font-size: 18px;

    color: var(--action-hover-color);

    margin-left: 10px;
  }

  .stats-widget__percentage-triangle {
    width: 12px;
  }

  .stats-widget__percentage-triangle_inverted {
    transform: rotate(180deg);
  }

  .stats-widget__previous-period {
    font-size: 12px;
    font-weight: var(--main-font-weight);

    color: var(--secondary-text-color);

    margin-top: 2px;
  }

  .stats-widget__chart {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
  }

  .stats-widget__chart .m-chart svg {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
</style>
