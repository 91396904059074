<template>
  <MChart
    :series="resultMetricSeries"
    :options="chartOptions"
    :titles="titles"
    height="280px"
    class="statistics-plot-chart"
  />
</template>

<script>
  import MChart from 'legacy/modules/common/components/MChart/MChart';
  import _ from 'lodash';
  import insertMissedOrderedObjects from 'utils/object/insertMissedOrderedObjects';
  import getDateRange from 'utils/dateRange/getDateRange';
  import moment from 'moment-wrapper';
  import widgetColors from 'utils/design/chartColors';
  import formatNumber from 'pages/seller/statistics/components/utils/formatNumber';
  import formatDate from 'pages/seller/statistics/components/utils/formatDate';

  _.noConflict();

  const AXIS_TEXT_COLOR = '#8D8D8D';
  const COLORS = widgetColors();
  const STROKE_SIZE = 8;
  const METRIC_SYMBOLS_LIMIT = 38;

  export default {
    name: 'StatisticsPlotChart',
    components: {MChart},
    props: {
      statistics: {
        type: Array,
        required: true
      },
      grouping: {
        type: String,
        required: true
      },
      dateGrouping: {
        type: String,
        required: true
      },
      metrics: {
        type: Object,
        required: true
      },
      metricChoices: {
        type: Array,
        required: true
      }
    },
    computed: {
      titles () {
        return this.metricsList.map((m, i) =>
          `${i === 0 ? '________' : '_  _  _  _  _'} ${this.availableMetricsMap[this.metricsList[i]].header}`
        );
      },
      availableMetricsMap () {
        return _.keyBy(this.metricChoices, 'itemKey');
      },
      metricsList () {
        return [this.metrics.left, this.metrics.right].filter(m => m !== 'no');
      },
      dateSeries () {
        if (this.statistics.length === 0) {
          return [];
        }

        const minDate = this.statistics[0].date;
        const maxDate = this.statistics[this.statistics.length - 1].date;

        return getDateRange(new Date(minDate), new Date(maxDate), this.dateGrouping).
          map(i => moment(i).formatDateApi());
      },
      formattedDateSeries () {
        return this.dateSeries.map(d => formatDate(d, this.dateGrouping));
      },
      groupedStats () {
        let groupById = this.grouping === 'label' ? 'label_name' : `${this.grouping}_id`;

        // group statistics to have separate charts for each grouping item
        const groupedStats = _.groupBy(this.statistics, groupById);
        return Object.values(groupedStats).map(stat => ({
          name: stat[0][`${this.grouping}_name`],
          data: insertMissedOrderedObjects(
            stat,
            this.dateSeries,
            'date',
            (d) => ({
              date: d,
              [this.metrics.left]: 0,
              [this.metrics.right]: 0
            })
          )
        }));
      },
      resultMetricSeries () {
        return this.groupedStats.reduce((acc, s) => {
          this.metricsList.forEach(metric => {
            let resultData = {
              name: s.name,
              nameWithMetric: s.name
                ? `${s.name}_${this.availableMetricsMap[metric].header}` : this.availableMetricsMap[metric].header,
              data: []
            };
            resultData.data = s.data.map(d => d[metric]);
            acc.push(resultData);
          });

          return acc;
        }, []);
      },
      yAxisLimits () {
        return this.resultMetricSeries.reduce((acc, el, i) => {
          const elData = el.data.filter(d => Boolean(d));
          let max = Math.max(...elData);
          let min = Math.min(...elData);
          if (i % 2 === 0) {
            acc.left.max = acc.left.max > max ? acc.left.max : max;
            acc.left.min = acc.left.min < min ? acc.left.min : min;
          } else {
            acc.right.max = acc.right.max > max ? acc.right.max : max;
            acc.right.min = acc.right.min < min ? acc.right.min : min;
          }
          return acc;
        }, {
          left: {
            max: 0,
            min: this.resultMetricSeries[0] && this.resultMetricSeries[0].data
              ? this.resultMetricSeries[0].data[0] : 0
          },
          right: {
            max: 0,
            min: this.resultMetricSeries[1] && this.resultMetricSeries[1].data
              ? this.resultMetricSeries[1].data[1] : 0
          }
        });
      },
      colors () {
        const colorsData = this.groupedStats.reduce((acc, el, i) => {
          if (!acc[el.name]) {
            acc[el.name] = {};
            acc[el.name].color = COLORS[i];
          }
          return acc;
        }, {});

        return this.resultMetricSeries.map(el => colorsData[el.name].color);
      },
      yAxisOptions () {
        return this.metricsList.map((m, i) => ({
          max: i === 0
            ? this.yAxisLimits.left.max
            : this.yAxisLimits.right.max,
          min: i === 0
            ? this.yAxisLimits.left.min
            : this.yAxisLimits.right.min,
          forceNiceScale: true,
          seriesName: this.availableMetricsMap[m].header,
          opposite: i === 1,
          show: true,
          showAlways: true,
          axisTicks: {
            show: false
          },
          labels: {
            offsetX: -5,
            style: {
              color: AXIS_TEXT_COLOR,
              fontSize: '12px'
            },
            formatter: (x, data, config) => {
              let formatType = 'integer';

              // for tooltip labels
              if (data && (data.seriesIndex || data.seriesIndex === 0)) {
                let ind = data.seriesIndex % 2 === 0 ? 0 : 1;
                formatType = this.metricsList[ind] ? this.availableMetricsMap[this.metricsList[ind]].type : 'integer';
              }

              // for yaxis labels and for crosshairs
              if (typeof data === 'number' && config || !data && !config) {
                formatType = this.availableMetricsMap[this.metricsList[i]].type;
              }
              return formatNumber(x, formatType, {withCurrencySymbol: true});
            }
          }
        }));
      },
      dashArray () {
        /*
         * series for the right (additional) yaxis are in even positions
         * these series are drawn with a dotted line
         */
        return this.resultMetricSeries.map((el, i) => {
          if (i % 2 === 0) {
            return 0;
          }
          return STROKE_SIZE;
        });
      },
      tooltipTitles () {
        return this.resultMetricSeries.map(s => ({
          title: {
            formatter: () => _.truncate(`${s.nameWithMetric}:`, {length: METRIC_SYMBOLS_LIMIT})
          }
        }));
      },
      chartOptions () {
        return {
          colors: this.colors,
          chart: {
            type: 'line',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          stroke: {
            width: 2,
            dashArray: this.dashArray,
            curve: 'smooth'
          },
          tooltip: {
            y: this.tooltipTitles
          },
          xaxis: {
            categories: this.formattedDateSeries,
            labels: {
              style: {
                fontSize: '12px'
              },
              rotate: 0
            }
          },
          legend: {
            show: this.grouping !== 'no',
            markers: {
              show: false
            },
            formatter: (seriesName, opts) => {
              if (opts.seriesIndex % 2 === 0) {

                const markerWrapper = document.createElement('div');
                const markerCircle = document.createElement('div');
                const markerText = document.createElement('span');
                const name = document.createTextNode(seriesName);
                markerWrapper.appendChild(markerCircle);
                markerText.appendChild(name);
                markerWrapper.appendChild(markerText);
                markerWrapper.className = 'apexcharts-legend-marker-wrapper';
                markerCircle.className = 'apexcharts-legend-marker--custom';
                markerCircle.style.backgroundColor = this.colors[opts.seriesIndex];
                return markerWrapper.outerHTML;
              }
              return '<span style="display: none"></span>';
            }
          },
          yaxis: this.yAxisOptions
        };
      }
    }
  };
</script>

<style>
  .statistics-plot-chart .apexcharts-legend-marker {
    display: none;
  }

  .statistics-plot-chart .apexcharts-legend-marker-wrapper,
  .statistics-plot-chart .apexcharts-legend-marker--custom {
    display: inline-block;
  }

  .statistics-plot-chart .apexcharts-legend-marker--custom {
    width: 12px;
    height: 12px;

    margin-right: 4px;

    border-radius: 50%;
  }
</style>
