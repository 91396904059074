<template>
  <div class="funnel-widget-chart">
    <div class="funnel-widget-chart__progress-container">
      <div
        v-for="(item, index) in data"
        :key="index + item.name"
        class="funnel-widget-chart__item"
      >
        <div class="funnel-widget-chart__item-title">
          <m-icon
            v-if="item.icon"
            :icon="item.icon"
            class="funnel-widget-chart__item-icon"
          />
          {{ item.name }}
        </div>
        <div class="funnel-widget-chart__item-progress-bar">
          <div
            class="funnel-widget-chart__item-progress-bar-value"
            :style="{backgroundColor: item.color, width: item.percent + '%'}"
          />
        </div>
      </div>
    </div>
    <div class="funnel-widget-chart__value-container">
      <div
        v-for="(item, index) in data"
        :key="index + item.name"
        class="funnel-widget-chart__item-value"
      >
        {{ item.data }}
      </div>
    </div>
  </div>
</template>

<script>
  import formatNumber from 'legacy/modules/common/components/MNumeric/formatNumber';
  import groupBy from 'lodash.groupby';
  import sortBy from 'lodash.sortby';
  import {mapGetters} from 'vuex';
  import widgetColors from 'utils/design/chartColors';

  export default {
    name: 'FunnelWidgetChart',
    props: {
      height: {
        type: String,
        default: '100%'
      },
      statistics: {
        type: Array,
        default: Array
      },
      metrics: {
        type: Array,
        default: Array
      },
      isSimpleMetric: {
        type: Boolean,
        required: true
      },
      grouping: {
        type: String,
        default: ''
      },
      metricType: {
        type: String,
        default: 'integer'
      },
      colorGetter: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      barColors: widgetColors()
    }),
    computed: {
      ...mapGetters('channels', ['channelIcons']),
      data () {
        let result = [];
        if (this.isSimpleMetric) {
          const groupingId = this.grouping === 'label' ? `${this.grouping}_name` : `${this.grouping}_id`;
          const groupedStats = groupBy(this.statistics, groupingId);
          result = Object.keys(groupedStats).map(i => ({
            id: groupedStats[i][0][groupingId],
            name: this.grouping !== 'no' ? groupedStats[i][0][`${this.grouping}_name`] : this.metrics[0].name,
            data: groupedStats[i].reduce((acc, current) => acc + Number(current[this.metrics[0].id]), 0),
            icon: this.grouping === 'channel' ? this.channelIcons[i] : null
          }));
        } else {
          result = this.metrics.map(metric => ({
            id: metric.id,
            name: metric.name,
            data: this.statistics.reduce((acc, current) => acc + Number(current[metric.id]), 0),
            icon: metric.icon
          }));
        }

        let sortedResult = sortBy(result.filter(x => x.name), 'data').reverse();

        if (sortedResult[0] && sortedResult[0].data > 0) {
          const maxValue = sortedResult[0].data;

          sortedResult.forEach((el) => {
            if (this.isSimpleMetric) {
              el.color = this.colorGetter.getEntityColor(this.grouping, el.id);
            } else {
              el.color = this.colorGetter.getEntityColor('metric', el.id);
            }
            el.percent = Math.ceil(el.data / maxValue * 100);
          });
        }

        return sortedResult.map(el => {
          el.data = formatNumber(el.data, this.metricType, {withCurrencySymbol: true});
          return el;
        });
      }
    }
  };
</script>

<style>
  .funnel-widget-chart {
    color: var(--secondary-text-color);

    display: flex;

    margin: 0 20px 20px;
  }

  .funnel-widget-chart__progress-container {
    display: flex;

    flex-direction: column;
    flex-grow: 1;

    padding-right: 14px;
  }

  .funnel-widget-chart__value-container {
    display: flex;
    flex-direction: column;
  }

  .funnel-widget-chart__item {
    position: relative;

    height: 24px;

    margin-bottom: 16px;
  }

  .funnel-widget-chart__item-title {
    font-size: 10px;

    text-overflow: ellipsis;

    white-space: nowrap;

    overflow: hidden;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .funnel-widget-chart__item-icon {
    height: 10px;
    margin-right: 4px;
  }

  .funnel-widget-chart__item-progress-bar {
    width: 100%;
    height: 8px;

    margin-top: 15px;

    background-color: var(--gray-color);

    border-radius: 6px;
  }

  .funnel-widget-chart__item-progress-bar-value {
    height: 8px;
    border-radius: 6px;
  }

  .funnel-widget-chart__item-value {
    font-size: 12px;

    line-height: 10px;

    display: flex;

    justify-content: flex-end;
    align-items: flex-end;

    height: 24px;

    margin-bottom: 16px;
  }
</style>
