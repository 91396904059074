<template>
  <div
    class="labeled-icon"
    :style="{
      '--labeled-icon-size': sizeStyle,
      '--labeled-icon-color': colorStyle
    }"
  >
    <div class="labeled-icon__container">
      <div class="labeled-icon__icon">
        <m-icon :icon="icon" />
      </div>
      <span
        class="labeled-icon__title"
        :title="label"
      >{{ label }}</span>
    </div>
  </div>
</template>

<script>
  import {colorsMap, stylesMap} from 'legacy/styles';

  export default {
    name: 'LabeledIcon',
    props: {
      icon: {
        type: Object,
        default: null
      },
      label: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: 'secondary',
        choices: Object.keys(colorsMap)
      },
      size: {
        type: String,
        default: 'main'
      }
    },
    computed: {
      sizeStyle () {
        return `var(${stylesMap.fontSizes[this.size]})`;
      },
      colorStyle () {
        return `var(${colorsMap[this.color]})`;
      }
    }
  };
</script>

<style>
  .labeled-icon.menu__list-item {
    --labeled-icon-size: 14px;
    --labeled-icon-color: var(--secondary-text-color);

    display: flex;
    align-items: center;
  }

  .labeled-icon__container {
    font-size: var(--labeled-icon-size);

    color: var(--labeled-icon-color);
    line-height: var(--labeled-icon-size);

    display: flex;
  }

  .labeled-icon__icon {
    flex: 0 0 var(--labeled-icon-size);
    margin-right: 4px;
  }

  .labeled-icon__title {
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
