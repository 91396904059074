<template>
  <div>
    <SpinnerContainer
      v-if="appLoading"
      :is-loading="appLoading"
      class="nested-page-spinner"
    />
    <router-view />
  </div>
</template>

<script>
  import {SpinnerContainer} from '@marilyn/marilyn-ui';
  import {mapGetters} from 'vuex';
  import {isCurrentAppSeller} from 'utils/appTypeState';

  export default {
    name: 'SimpleNestedPage',
    components: {SpinnerContainer},
    computed: {
      ...mapGetters(isCurrentAppSeller() ? 'sellerCommon' : 'common', ['appLoading'])
    }
  };
</script>

<style>
  .nested-page-spinner.spinner-container {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
  }

  .nested-page-spinner.spinner-container .spinner-container__spinner {
    top: 50vh;
  }
</style>
