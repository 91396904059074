<template>
  <div class="schedule-control-advanced">
    <WeekdayHourForm
      :label="dayTitle"
      :items="daysList"
      size="l"
      class="schedule-control-advanced-item schedule-control-advanced__day-picker"
      @select="handleDayInput"
    />

    <WeekdayHourForm
      :label="hourTitle"
      :items="timeList"
      size="l"
      class="schedule-control-advanced-item schedule-control-advanced__hour-picker"
      show-manage-choice-button
      @select="handleHourInput"
      @pick-all-item="pickAllHourItem"
      @clear-all-item="clearAllHourItem"
    />
  </div>
</template>

<script>
  import WeekdayHourForm from 'modules/common/components/WeekdayHourForm';
  import {getHourItems} from 'modules/common/components/HourControl/utils';
  import {getWeekdayItems} from 'modules/common/components/WeekdayControl/utils';

  export default {
    name: 'ScheduleControlAdvanced',
    components: {WeekdayHourForm},
    props: {
      days: {
        type: Array,
        default: Array
      },
      time: {
        type: Array,
        default: Array
      },
      dayTitle: {
        type: String,
        required: true
      },
      hourTitle: {
        type: String,
        required: true
      }
    },
    computed: {
      daysList () {
        return getWeekdayItems(this.days);
      },
      timeList () {
        return getHourItems(this.time);
      }
    },
    methods: {
      updateValue (days, time) {
        this.$emit('input', {days, time});
      },
      handleDayInput (val) {
        if (val.selected) {
          return this.updateValue(this.days.filter(i => i !== val.id), this.time);
        }
        this.updateValue([...this.days, val.id], this.time);
      },
      handleHourInput (val) {
        if (val.selected) {
          return this.updateValue(this.days, this.time.filter(i => i !== val.id));
        }
        this.updateValue(this.days, [...this.time, val.id]);
      },
      pickAllHourItem () {
        this.updateValue(this.days, Array.from({length: 24}, (_, index) => index));
      },
      clearAllHourItem () {
        this.updateValue(this.days, []);
      }
    }
  };
</script>

<style>
  .schedule-control-advanced-item {
    --width-container-advanced-item: 400px;
  }

  .schedule-control-advanced__day-picker {
    width: var(--width-container-advanced-item);
  }

  .schedule-control-advanced__day-picker {
    width: 100%;
  }

  .schedule-control-advanced__hour-picker {
    margin-top: 20px;
  }

</style>
