<template>
  <MTableCell
    class="m-table-cell-link__container m-table-column__cell_ellipsis"
  >
    <MProviderIcon
      v-if="channelIcon"
      :icon="channelIcon"
      class="m-table-cell__channel-icon"
    />
    <span
      class="m-table-cell__ellipsis-wrapper"
    >
      {{ tableRow[itemKey] }}
    </span>
  </MTableCell>
</template>

<script>
  import appContext from 'legacy/appcontext';
  import {mapGetters} from 'vuex';
  import {mTableCells, MProviderIcon} from '@marilyn/marilyn-ui';

  export default {
    name: 'MTableCellLabel',
    components: {
      MTableCell: mTableCells.MTableCell,
      MProviderIcon
    },
    props: {
      index: {
        type: [Number, String],
        default: ''
      },
      itemKey: {
        type: String,
        default: ''
      },
      tableRow: {
        type: Object,
        default: Object
      }
    },
    computed: {
      ...mapGetters('channels', ['channelIcons']),
      channelIcon () {
        const channel = appContext.channels().where({display_name: this.tableRow[this.itemKey]});
        if (!channel[0]) {
          return false;
        }
        return this.channelIcons[channel[0].get('id')];
      }
    }
  };
</script>

<style>
  .m-table-cell__channel-icon {
    flex-shrink: 0;

    width: 12px;
    height: 12px;

    margin-right: 8px;
  }
</style>
